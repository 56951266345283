import React from 'react';
import useTemplate from '../../Provider/TableContext/TemplateContext';
import { addClassNames } from '../../Functions/Helper/Component';
import { ReactComponent as TemplateIcon } from 'bootstrap-icons/icons/columns-gap.svg';
import { CVDisplay } from './CVDisplay';
import useCV from '../../Provider/TableContext/CVContext';

export default function SidePanel({className, formData, setFormData, setCVBytes, loading: _loading }) {
    const {cvs} = useCV();
    const mainCVTemplate = React.useMemo(() => {
        if(formData?.cv?._mcb_templatedocument_value) return formData?.cv?._mcb_templatedocument_value;
        let counts = {};
        let lastUsed = {};
        cvs.forEach(cv => {
            const cvTemplate = `${cv._mcb_templatedocument_value}`;
            
            if (counts[cvTemplate]) counts[cvTemplate]++;
            else counts[cvTemplate] = 1;
            
            const lastUsedTemplate = new Date(cv.modifiedon);
            if (lastUsed[cvTemplate]) {
                if (lastUsed[cvTemplate] < lastUsedTemplate) lastUsed[cvTemplate] = lastUsedTemplate;
            } else lastUsed[cvTemplate] = lastUsedTemplate;
        });
        const tempIds = Object.keys(counts);
        if (tempIds.length > 1) return  Object.keys(counts)
            .reduce((a, b) =>
                counts[a] > counts[b]? a
                : counts[b] > counts[a]? b 
                : lastUsed[a] > lastUsed[b]? a
                : lastUsed[b] > lastUsed[a]? b
                : a
            );
        else if (tempIds.length) return tempIds[0];
        else return false;
    }, [cvs, formData]);
    const { cvTemplates, downloadTemplate, loading: cvLoading } = useTemplate();
    const [template, setTemplate] = React.useState();
    const [showOption, setShowOption] = React.useState(false);
    
    const selectedOption = React.useMemo(() => {
        if (mainCVTemplate && Array.isArray(cvTemplates)) {
            return cvTemplates.find(({mcb_templatedocumentid}) => mcb_templatedocumentid === mainCVTemplate);
        } else return false;
    }, [mainCVTemplate, cvTemplates]);

    const setSelectedOption = React.useCallback(({mcb_templatedocumentid}) => {
        setFormData((formData) => ({
            ...formData,
            cv: {
                ...formData.cv,
                _mcb_templatedocument_value: mcb_templatedocumentid
            }
        }));
    }, [setFormData]);
    const loading = React.useMemo(() =>  (
        _loading 
        || (selectedOption?.mcb_templatedocumentid && !template)
    ), [_loading, selectedOption?.mcb_templatedocumentid, template]);
    
    // React.useEffect(() => {
    //     if (!loading && !selectedOption && Array.isArray(cvTemplates) && cvTemplates.length > 0) {
    //         setSelectedOption(cvTemplates[0]);
    //     }
    // }, [cvTemplates, loading, selectedOption, setSelectedOption]);

    React.useEffect(() => {
        if (selectedOption && selectedOption?.mcb_templatedocumentid) {
            let mounted = true;
            downloadTemplate(selectedOption).then(({ arrayBuffer }) => {
                if (mounted) setTemplate(arrayBuffer);
            });
            return () => { mounted = false };
        }
    }, [downloadTemplate, selectedOption]);
    return (
        <div className={addClassNames(className, "d-flex flex-wrap flex-grow gap-3 py-4 w-100 h-100", template? "":"align-items-center justify-content-center" )}>
            <div className={`dropdown${showOption? " show" : ""}`}>
                <div className="btn-group rounded-pill ">
                    <button 
                        type="button"
                        className="btn btn-light upload-dropdown-toggle dropdown-toggle"
                        onFocus={() => setShowOption(true)}
                        onBlur={() => setShowOption(false)}
                        disabled={loading}
                    ><TemplateIcon className="icon"/>{selectedOption?.mcb_name || "Select CV Template"} <div className={addClassNames("spinner-border ms-2", loading? "" : "d-none")} role="status"><span className="visually-hidden">Loading...</span></div></button>
                </div>
                
                <div
                    className={`dropdown-menu${showOption? " show" : ""}`}
                    style={{
                        position: "absolute", 
                        transform: "translate3d(0px, 38px, 0px)",
                        top: "0px", left: "0px", willChange: "transform"
                    }}
                >{cvTemplates.map((template) => (
                    <button 
                        type="button"
                        className="dropdown-item"
                        key={template.mcb_templatedocumentid}
                        onMouseDown={() => { setSelectedOption(template); setShowOption(false);}}
                        onTouchStart={() => { setSelectedOption(template); setShowOption(false);}}
                    >
                        <div>{template.mcb_name}</div>
                    </button>
                ))}</div>
            </div>
            
            
            {!loading && template && <CVDisplay template={template} setCVBytes={setCVBytes} formData={formData} loading={cvLoading}/>}
        </div>
    );

}