import React from 'react';
import { usePortalApi } from '../PortalApiContext';
import Constants from '../../Constants';
import { deferred } from '../../Functions/Helper/Async';
import { v4 as uuidv4 } from 'uuid';
// Helper/FUNCTIONS
function pluralize(name) {
    name = name.trim();
    if (name.endsWith("y")) { 
        return name.slice(0, -1) + "ies";
    } else {
        return name + "s";
    }
}

function unBindEntity(entity, relations) {
    const result = entity ? {...entity} : {};
    for (const key of Object.keys(relations || {})) {
        const valueKey = `_${key.toLowerCase()}_value`;
        const bindKey = `${key}@odata.bind`;
        if (entity[bindKey]) {
            const bindId = /[^(]+\(([^)]+)\)$/.exec(entity[bindKey])[1];
            if (bindId) {
                result[valueKey] = bindId;
            }
        }
    }

    return result;
}

async function setFields(entity, fields, relations) {
    if (fields) {
        const result = {};
        for (const field of fields) {
            if (entity[field] !== undefined) {
                result[field] = await entity[field];
            }
        }
        
        for (const [key, value] of Object.entries(relations || {})) {
            const valueKey = `_${key.toLowerCase()}_value`;
            if (entity[valueKey]) {
                result[`${key}@odata.bind`] = `/${pluralize(value)}(${await entity[valueKey]})`;
            }
        }
        return result;
    } else {
        return entity;
    }
}

async function setData({ preSubmission, fields, relations, profileData}, data ) {
    let entity;
    if (typeof preSubmission === "function")
        entity = await preSubmission(data, profileData);
    entity = await setFields(entity || data, fields, relations);
    return entity;
}

// ajax functions
async function loadData(apiCall, tableName, fields, relations, expands, orderby) {
    let url = `/_api/${tableName}`;
    let queryString = [`$filter=modifiedon le ${new Date().toISOString()}`];
    fields = [...(fields || [])];

    if (relations) {
        for (const key of Object.keys(relations || {})) {
            fields.push (`_${key.toLowerCase()}_value`);
        }
    }
    if (fields?.length) {
        queryString.push(`$select=${fields.join(",")}`);
    }

    if (expands) {
        const expandEntries = [];
        for (const [relationName, entityName] of Object.entries(expands)){
            expandEntries.push(`${relationName}($select=${entityName}id)`);
        }
        queryString.push(`$expand=${expandEntries.join(",")}`);
    }
    if (orderby) {
        queryString.push(`$orderby=${orderby}`);
    }
    
    if (queryString.length) {
        url += "?" + queryString.join("&");
    }
    return apiCall({ method: "GET", url: url.toString() });
}

async function updateRelations(apiCall, entityName, expand, newData, oldData = {}) {
    if (!expand || typeof expand !== "object") return new Promise((res) => res(true));
    return Promise.all(Object.entries(expand).map(([relationName, relationEntityName])=> {
        const newRelationDatas = newData[relationName];
        if (newRelationDatas === undefined) return Promise.resolve();
        const oldRelationDatas = oldData[relationName] || []; 
        const entityIdKey = `${entityName}id`;
        const entityTableName = pluralize(entityName);
        const entityId = oldData[entityIdKey] || newData[entityIdKey];
        
        const relationEntityIdKey = `${relationEntityName}id`;
        const relationTableName = pluralize(relationEntityName);

        const toAssociate = [], toDisassociate = [];

        for(const relationData of newRelationDatas) {
            if (oldRelationDatas.every((oldRelationData) => (
                oldRelationData[relationEntityIdKey] !== relationData[relationEntityIdKey]
            ))) {
                toAssociate.push(relationData[relationEntityIdKey]);
            }
        }

        for(const relationData of oldRelationDatas) {
            if (newRelationDatas.every((newRelationData) => (
                newRelationData[relationEntityIdKey] !== relationData[relationEntityIdKey]
            ))) {
                toDisassociate.push(relationData[relationEntityIdKey]);
            }
        }
        
        return Promise.all([
            ...toAssociate.map(dataId => apiCall({
                method: "POST",
                url: `/_api/${entityTableName}(${entityId})/${relationName}/$ref`,
                body: JSON.stringify({
                    "@odata.id": `${Constants.portalURL}_api/${relationTableName}(${dataId})`
                })
            })),
            ...toDisassociate.map(dataId => apiCall({
                method: "DELETE",
                url: `/_api/${entityTableName}(${entityId})/${relationName}(${dataId})/$ref`
            }))
        ]);

    }));
}

const UNCHANGED = (d) => d;

async function addTableData({apiCall, entityName, expand, fields, relations, preSubmission, profileData}, {onCreated, ...data} = {}) {
    try {
        const entity = await setData({ preSubmission, fields, relations, profileData}, data);
        const result = await apiCall({ 
            method: "POST",
            url: `/_api/${pluralize(entityName)}`,
            body: JSON.stringify(entity)
        });
        if (result.entityId) {
            if (typeof onCreated === "function") onCreated(result.entityId); 
            const finalData = {...data, ...unBindEntity(entity, relations), [`${entityName}id`]: result.entityId, createdon: (new Date()).toISOString()};
            await updateRelations(apiCall, entityName, expand, finalData);
            return [(tableDatas) => {
                return [...tableDatas, finalData]
            }, Promise.resolve(finalData)];
        } 
    } catch (err) {
        console.error(err);
    }
    return [UNCHANGED, Promise.resolve(data)];
}
  
async function updateTableData({apiCall, entityName, expand, fields, relations, tableDatas, preSubmission, profileData},  data) {
    try {
        const entity = await setData({ preSubmission, fields, relations, profileData}, data);
        const dataIdKey = `${entityName}id`;
        const id = entity[dataIdKey];
        try {
            const dataSearch = d => d[dataIdKey] === id ;
            const oldData = tableDatas.find(dataSearch);
            const hasChanged = (fields || []).some(field => data[field] !== oldData[field]) 
                || Object.values(relations || {}).some((entityName) => {
                    const key = `_${entityName.toLowerCase()}_value`;
                    return data[key] !== oldData[key];
                });
            const updatePromise = hasChanged? apiCall({ 
                method: "PATCH",
                url: `/_api/${pluralize(entityName)}(${id})`,
                body: JSON.stringify(entity)
            }) : Promise.resolve();
            
            await Promise.all([
                updatePromise, 
                updateRelations(apiCall, entityName, expand, data, oldData)
            ]);

            const [finalDataPromise, resolve] = deferred();
            return [(tableDatas) => {
                const dataIndex = tableDatas.findIndex(dataSearch);
                if (dataIndex >= 0 && dataIndex < tableDatas.length) {
                    const finalData = {...(tableDatas[dataIndex]||{}), ...data, ...unBindEntity(entity, relations)};
                    
                    resolve(finalData);
                    return ([
                        ...tableDatas.slice(0, dataIndex), finalData,
                        ...tableDatas.slice(dataIndex + 1)
                    ]);
                } else {
                    return tableDatas;
                }
            }, finalDataPromise];
        } catch (err) {
            if (err.status === 404)  {
                return [(tableDatas) => {
                    const dataIndex = tableDatas.findIndex(d => d[dataIdKey] === id);
                    if (dataIndex >= 0 && dataIndex < tableDatas.length) {
                        return ([
                            ...tableDatas.slice(0, dataIndex),
                            ...tableDatas.slice(dataIndex + 1)
                        ]);
                    } else {
                        return tableDatas;
                    }
                }];
            } else {
                throw err;
            }
        }
    } catch (err) {
        console.error(err);
    }
    return [UNCHANGED, Promise.resolve(data)];
};

async function deleteTableData({apiCall, entityName}, id) {
    try {
        await apiCall({ 
            method: "DELETE",
            url: `/_api/${pluralize(entityName)}(${id})`
        });
        
    } catch (err) {
        if (err.status !== 404) console.error(err);
    }
    const dataIdKey = `${entityName}id`;
    return [(tableDatas) => {
        const dataIndex = tableDatas.findIndex(d => d[dataIdKey] === id);
        if (dataIndex >= 0 && dataIndex < tableDatas.length) {
            return ([
                ...tableDatas.slice(0, dataIndex),
                ...tableDatas.slice(dataIndex + 1)
            ]);
        } else {
            
            return tableDatas;
        }
    }];
}

async function addRelation({apiCall, entityName, expand}, entityId, relationName, dataId) {
    try {
        const relationEntityName = expand[relationName];
        const relationTableName = pluralize(relationEntityName);
        await apiCall({
            method: "POST",
            url: `/_api/${pluralize(entityName)}(${entityId})/${relationName}/$ref`,
            body: JSON.stringify({
                "@odata.id": `${Constants.portalURL}_api/${relationTableName}(${dataId})`
            })
        });
        return [(tableDatas) => {
            const dataIndex = tableDatas.findIndex(d => d[`${entityName}id`] === entityId);
            if (dataIndex >= 0 && dataIndex < tableDatas.length) {

                return ([
                    ...tableDatas.slice(0, dataIndex),
                    {...tableDatas[dataIndex], [relationName]: [...(tableDatas[dataIndex][relationName] || []), { [`${relationEntityName}id`]: dataId }]},
                    ...tableDatas.slice(dataIndex + 1)
                ]);
            } else {
                return tableDatas;
            }
        }];

    }catch (err) {

    }
}


async function removeRelation({apiCall, entityName, expand}, entityId, relationName, dataId) {
    try {
        const relationEntityName = expand[relationName];
        await apiCall({
            method: "DELETE",
            url: `/_api/${pluralize(entityName)}(${entityId})/${relationName}(${dataId})/$ref`
        });
        return [(tableDatas) => {
            const dataIndex = tableDatas.findIndex(d => d[`${entityName}id`] === entityId);
            if (dataIndex >= 0 && dataIndex < tableDatas.length) {
                
                return ([
                    ...tableDatas.slice(0, dataIndex),
                    {
                        ...tableDatas[dataIndex],
                        [relationName]: (tableDatas[dataIndex][relationName] || []).filter(d => d[`${relationEntityName}id`] !== dataId)
                    },
                    ...tableDatas.slice(dataIndex + 1)
                ]);
            } else {
                return tableDatas;
            }
        }];

    }catch (err) {

    }
}

async function uploadFile({apiCall, entityName }, entityId, field, file, fileName) {
    const [promise, resolve, reject] = deferred();
    try {
        const uploadFile = async (bodyContents, _fileName = fileName) => {
            var buffer = new Uint8Array(bodyContents);
            await apiCall({
                url: `/_api/${pluralize(entityName)}(${entityId})/${field}${_fileName? `?x-ms-file-name=${_fileName}`: ""}`,
                method: "PATCH",
                headers: {
                    "Content-Type": "application/octet-stream"
                },
                body: buffer
            });
            resolve(Date.now());
        };

        if (file instanceof File) {
            var reader = new FileReader();
            reader.onload = async function(e) {
                try {
                    var bodyContents = e.target.result;
                    await uploadFile(bodyContents);
                } catch (err) {
                    reject(err)
                }
            };
            reader.readAsArrayBuffer(file);
        } else {
            await uploadFile(file);
        }
        await promise;
        return [(tableDatas) => {
            const dataIndex = tableDatas.findIndex(d => d[`${entityName}id`] === entityId);
            if (dataIndex >= 0 && dataIndex < tableDatas.length) {
                const result =  ([
                    ...tableDatas.slice(0, dataIndex),
                    {
                        ...tableDatas[dataIndex],
                        [field]: uuidv4()
                    },
                    ...tableDatas.slice(dataIndex + 1)
                ]);
                return result;
            } else {
                return tableDatas;
            }
        }, promise];
    } catch (err) {
        if (err.status !== 404) console.error(err);
    }
    return [UNCHANGED, Promise.resolve(file)];
}

async function downloadFile({apiCall, entityName}, entityId, field) {
    try {
        const result = await apiCall({
            url: `/_api/${pluralize(entityName)}(${entityId})/${field}/$value`,
            method: "GET",
            headerOverride: { "Accept": "application/octet-stream" }
        });
        
        return [UNCHANGED, result];
    } catch (err) {
        if (err.status !== 404) console.error(err);
    }
    return [UNCHANGED, Promise.resolve({})];
}

async function processTableChanges(operationProps, changes) {
    try {
        const queuedStateChanges = [];
        const resultValues = [];
        await Promise.all(changes.map(async ({ deleted, ...changedItem}) => {
            const changedItemId =  changedItem[`${operationProps.entityName}id`];
            let result = [UNCHANGED, Promise.resolve(changedItem)];
            if (deleted) {
                result = await deleteTableData(operationProps, changedItemId);
            } else if (changedItemId) {
                result = await updateTableData(operationProps, changedItem);
            } else {
                result = await addTableData(operationProps, changedItem);
            }
            if (result[0] !== UNCHANGED) {
                queuedStateChanges.push(result[0]);
            }
            resultValues.push(result[1]);
            
        }));
        if (queuedStateChanges.length !== 0) {
            return [(data) => {
                const result = queuedStateChanges.reduce((acc, cb) => cb(acc), data);
                return result;
            }, Promise.resolve(resultValues)];
        }
    } catch (err) {
        console.error(err);
    }
    return [UNCHANGED, Promise.resolve(changes)];
}

export default function CreateTableContext(
    entityName, { 
        fields, readOnly, expand, orderby, relations, preSubmission,
        contextName = "Data", processContextData = UNCHANGED,
} = {}) {

    const TableContext = React.createContext();
    
    const TableProvider = ({children}) => {
        const { apiCall, loadingIframe, profileData, loadedIframeLocation } = usePortalApi();
        const [tableDatas, setTableDatas] = React.useState([]);
        const [loading, setLoading] = React.useState(true);
        const [error, setError] = React.useState(null);

        // Initially loads the data
        React.useEffect(() => {
            if(
                loadingIframe
                || !profileData?.id
                || !/^\/web-api/.test(loadedIframeLocation?.pathname)
            ) return;
            let unmounted = false;
            setLoading(true);

            let _fields = [...(fields || []), ...(readOnly || [])];
            loadData(apiCall, pluralize(entityName), _fields, relations, expand, orderby).then((result) => {
                if (result && !(typeof unmounted === "function" && unmounted())) {
                    setError(false);
                    setTableDatas(result.json.value);
                }
            }).catch((err) => {
                if (!unmounted) {
                    setError(err);
                }
                console.error(err);
            }).finally(() => {
                if (!unmounted) {
                    setLoading(false);
                }
            });
            return () => { unmounted = true; }
        }, [apiCall, loadingIframe, loadedIframeLocation, profileData]);
        

        const operationWrapper = React.useCallback((operation) => {
            const operationProps = {
                apiCall,
                entityName,
                expand,
                fields,
                relations,
                tableDatas,
                preSubmission,
                profileData
            };

            return async (...args) => {
                const nonBlocking = args.length > 0 && args[args.length - 1] === false;
                if (!nonBlocking) setLoading(true);
                const [tableAction, finalData] = await operation(operationProps, ...args);
                if (tableAction !== UNCHANGED) setTableDatas(tableAction);
                
                if(!nonBlocking) setLoading(false);
                return await finalData;
            }
        }, [apiCall, tableDatas, profileData]);

        const value = React.useMemo(() => processContextData({ 
            [pluralize(contextName.toLowerCase())]: tableDatas,
            [`create${contextName}`]: operationWrapper(addTableData),
            [`update${contextName}`]: operationWrapper(updateTableData),
            [`delete${contextName}`]: operationWrapper(deleteTableData),
            [`addRelationTo${contextName}`]: operationWrapper(addRelation),
            [`removeRelationFrom${contextName}`]: operationWrapper(removeRelation),
            [`process${contextName}Changes`]: operationWrapper(processTableChanges),
            [`uploadFileTo${contextName}`]: operationWrapper(uploadFile),
            [`downloadFileFrom${contextName}`]: operationWrapper(downloadFile),
        }), [tableDatas, operationWrapper]);
        
        return (
            <TableContext.Provider value={{...value, loading, error,}}>
                {children}
            </TableContext.Provider>
        );
    };
    
    const useTableContext = () => React.useContext(TableContext);
    
    return [useTableContext, TableProvider];
}
