import React from 'react';
import useCVSetupContext from './CVSetupContext';
import CVSidePanel from '../../Components/CV/CVSidePanel';
import CVForm from '../../Components/CV/CVForm';

export default function CVStep(props) {
    const context = useCVSetupContext();
    return <CVForm
        {...props}
        {...context}
        loadingText="Loading CV Data" 
        redirectUrl={() => `/cvs`}
    />;
}

export function SidePanel({className}) {
    const { formData, setFormData, setCVBytes, loading } = useCVSetupContext();
    return (<CVSidePanel {...{className, formData, setFormData, setCVBytes, loading}} />);
}

CVStep.SideComponent = SidePanel;

CVStep.stepName = "CV Setup";