import React from 'react';
import AchievementModal from './AchievementModal';
import useABC from '../Provider/TableContext/ABCContext';
import { formDataToABC } from '../Functions/Helper/Model';

export default function HookedAchievementModal({value: _value, initialValue, onSubmit: _onSubmit, onChange: _onChange, title, ...props}) {
    const { processABCChanges } = useABC();
    const [stateValue, setValue] = React.useState(_value || initialValue || {});

    const onChange = React.useCallback((value) => {
        setValue(value);
        if (typeof _onChange === "function") _onChange(value);
        return value;
    }, [_onChange]);
    
    const onSubmit = React.useCallback(async () => {
        const result = await processABCChanges([formDataToABC(_value || stateValue)]);
        let output = true;
        if (typeof _onSubmit === "function") output = await _onSubmit(formDataToABC(result));
        if (output !== false) onChange({});
        return output;

    }, [_onSubmit, processABCChanges, onChange, _value, stateValue]);

    return (
        <AchievementModal 
            onSubmit={onSubmit}
            onChange={onChange}
            value={_value || stateValue}
            {...props}
        />
    );
}