import React from 'react';
import Form from './Generic/Form';
import { SingleInputColumn } from './Generic/InputGrouping';
import { addClassNames } from '../Functions/Helper/Component';
import CheckInput from './Generic/Input/CheckInput';
import CliftonStrengthInput from './CliftonStrengthInput/CliftonStrengthInput';
import useAchievementOptions from '../Hooks/useAchievementOptions';

export default function CliftonStrengthForm({ onSubmit, formData, setFormData, onCancel, ActionComponent, className }) {
    const formRef = React.useRef(null);
    const [options] = useAchievementOptions();

    return (
        <Form
            ref={formRef}
            onSubmit={onSubmit}
            className={addClassNames("achievement-form d-flex flex-column gap-4 mb-4", className)}
        >
            <SingleInputColumn
                state={formData}
                setState={setFormData}
                inputs={[
                    ['cliftonstrengths', CliftonStrengthInput, (
                        <span>
                            <strong>Clifton Strengths</strong>
                        </span>
                    ), { multiselect: true }],
                    ['achievements', CheckInput, (
                        <label>
                            <strong>Select Achievements where the above CliftonStrengths are applied</strong> (at least one)<span className='required-tag'> *</span>
                        </label>
                    ), { options }]
                ]}
            />
            {ActionComponent && (
                <div key="actions">
                    <ActionComponent {...{onSubmit, onCancel, formData, submitType: "submit"}} />
                </div>
            )}
        </Form>
    );
}
