import React from 'react';
import { useParams } from 'react-router';
import useCV from '../../Provider/TableContext/CVContext';
import useProfile from '../../Provider/TableContext/ProfileContext';
import Sectors from '../../Constants/Sector.json';
import useCVSkillAchievement from '../../Provider/TableContext/CVSkillAchievementContext';
import { UserReadableError } from '../../Functions/Error';

const CVSetup = React.createContext();

export function CVSetupProvider(props) {
    const {id} = useParams();
    const { cvs, processCVChanges, uploadFileToCV, loading: cvLoading } = useCV();
    const { profileData, loading: profileLoading } = useProfile();
    const { cvskillachievements, processCVSkillAchievementChanges, loading: cvSkillAchievementLoading } = useCVSkillAchievement();
    
    const [_loading, setLoading] = React.useState(false);
    const loading = React.useMemo(() => 
        _loading || cvLoading || profileLoading || cvSkillAchievementLoading,
        [_loading, cvLoading, profileLoading, cvSkillAchievementLoading]
    );

    const cv = React.useMemo(() => {
        let cv = cvs.find(cv => cv.mcb_cvid === id);
        if (cv) {
            cv = {
                ...cv,
                cvRoles: [],
                keySkillsAchievements: cvskillachievements.filter(a =>
                    a._mcb_cv_value === cv?.mcb_cvid).sort((a,b) => (a?.mcb_index || 0) - (b.mcb_index||0)
                )
            }
        } else {
           cv = {
                mcb_firstname: profileData?.firstname,
                mcb_lastname: profileData?.lastname,
                mcb_email: profileData?.emailaddress1,
                mcb_phone: profileData?.telephone1,
                mcb_profilesummary: profileData?.adx_publicprofilecopy,
                mcb_currentcity: profileData?.address1_city,
                mcb_linkedin: profileData?.mcb_linkedin,
                mcb_sectors: profileData?.sectors
                .map(sector => Sectors.find(({value}) => value === sector)?.label || false)
                .filter(a => a).join(", "),
                
            };
        }
        return {
            ...cv,
            skills: (cv?.mcb_CVKeySkill?.map(({mcb_skillid}) => mcb_skillid) || []),
            cliftonstrengths: (cv?.mcb_cliftonstrength?.split(",")|| []).map(a => Number(a)),
            achievements: (cv?.mcb_CVABC?.map(({mcb_abcid}) => mcb_abcid) || []),
            qualifications: (cv?.mcb_CVQualifications?.map(({mcb_qualificationid}) => mcb_qualificationid) || []),
            cvRoles: (cv?.mcb_CVRoles?.map(({mcb_roleid}) => mcb_roleid) || [])
        }
    }, [ cvs, id, profileData, cvskillachievements ]);
    const [formData, setFormData] = React.useState({ cv: cv || {} });
    
    React.useEffect(() => {
        setFormData({ cv });
    }, [cv]);

    const setCVBytes = React.useCallback((bytes) => {
        setFormData(oFD => {
            return {
                ...oFD,
                cvDOCX: bytes
            };
        });
    }, []);
    const updateCV = React.useCallback(async() => {

        const formCV = (formData || {})?.cv || {};
        const cvDocx = (formData || {})?.cvDOCX;
        if (!cvDocx) throw UserReadableError(`Please Generate a CV by Selecting the CV Template on the Right Side of the Page`);
        const keySkillsAchievements = formCV?.keySkillsAchievements || [];
        setLoading(true);
        try {
            
            formCV.mcb_CVRoles = (formCV.cvRoles || []).map(mcb_roleid => ({ mcb_roleid }));
            formCV.mcb_CVABC = (formCV.achievements || [])?.map((mcb_abcid) => ({ mcb_abcid }));

            formCV.mcb_CVKeySkill = formCV.skills?.map((mcb_skillid) => ({ mcb_skillid }));
            formCV.mcb_cliftonstrength = (formCV.cliftonstrengths || []).join(",");
            formCV.mcb_CVQualifications = formCV.qualifications?.map((mcb_qualificationid) => ({ mcb_qualificationid }));
            const cvName = formCV.mcb_cvname || ["CV", formCV.mcb_roletitle, formCV.mcb_rolecompanyname].filter(a => a).join(" ");
            formCV.mcb_cvname = cvName;
            
            const changes = [formCV];
            const cvData = await ((await processCVChanges(changes))[0]);
            const cvId = cvData?.mcb_cvid;
            const promises = [];
            if (cvDocx && cvId) {
                promises.push(uploadFileToCV(cvId, "mcb_cvdocument", cvDocx, (cvName || "CV") + ".docx"));
            }
            if (cvId && keySkillsAchievements.length) {
                promises.push(processCVSkillAchievementChanges(keySkillsAchievements.map((skillAchievement, mcb_index) => ({
                    ...skillAchievement, _mcb_cv_value: cvId
                }))));
            }

            await Promise.all(promises);
            setLoading(false);
            return true;
        } catch (err) {
            throw err;
        }
    }, [formData, processCVChanges, uploadFileToCV, processCVSkillAchievementChanges]);

    return (
        <CVSetup.Provider value={{ formData, setFormData, updateCV, setCVBytes, loading }}>
            {props.children}
        </CVSetup.Provider>
    );
}

export default function useCVSetupContext() {
    return React.useContext(CVSetup);
}