import React from 'react';
import Input from './Generic/Input';
import { addClassNames } from '../Functions/Helper/Component';
import useQualificationOptions from '../Hooks/useQualificationOptions';

export default function QualificationsInput({className, ...props}) {
    const [options, loading] = useQualificationOptions();
    
    return (
        <div>
            <Input
                {...props}
                type='multiselect'
                isLoading={loading}
                className={addClassNames('primary-select', className)}
                options={options}
            />
        </div>
    );
}