import React from 'react';
import { Tooltip } from 'react-tooltip';
import CliftonStrengthOptions , {tooltipPrefix}from '../Constants/CliftonStrengths';

export default function CliftonStrengthToolTipsProvider({children}) {
    const result = [];
        CliftonStrengthOptions.forEach((optionGroup) => 
            optionGroup.options.forEach(option => result.push(
            <Tooltip
                key={tooltipPrefix + option.value}
                id={tooltipPrefix + option.value}
                place="top"
                style={{zIndex: "9999", whiteSpace: "pre-wrap"}}
                effect="solid"
            >
            <div>{option.description.replace(/\. /g, ".\n")}</div>
            </Tooltip>
            ))
        );
    return (
        <>
            {children}
            <div>
                {result}
            </div>
        </>
    );
}
