import React from 'react';
import { ReactComponent as HandIcon } from 'bootstrap-icons/icons/hand-index-thumb-fill.svg';
import { ReactComponent as ChevronLeft } from 'bootstrap-icons/icons/chevron-double-left.svg';
import './TouchIndicator.scss';

export default function TouchIndicator() {
    return (
        <div className="touch-indicator">
            <div className="motion">
                <ChevronLeft className="left-indicator ico"/>
                <div className="touch-point"/>
            
            </div>
            <HandIcon className="hand-icon ico" />
        </div>
    );
}