import { generateRequestId, awaitIframe } from "./FrameActionHelper";

/**
 * Function to perform forgotPassword via iframe.
 * @param {Object} iframeRef - Reference to the iframe element.
 * @param {Object} forgotPasswordOption - Options for login.
 * @returns {Promise} - Promise that resolves with the login result or rejects with an error.
 */
export default async function ForgotPasswordViaFrame(iframeRef, forgotPasswordOption) {
    const requestId = generateRequestId();

    // Wait for the iframe to load
    await awaitIframe(iframeRef);

    return await new Promise((resolve, reject) => {
        const iframeTimeout = setTimeout(function () {
            window.removeEventListener('message', handleMessage);
            reject(new Error("IFrame Timeout"));
        }, 500);

        const handleMessage = (event) => {
            if (event.source !== iframeRef.contentWindow) return;
            try {
                const response = event.data;
                if (response.action === "FORGOT_PASSWORD_SUCCESS") {
                    window.removeEventListener('message', handleMessage);
                    resolve(response);
                } else if (response.requestId === requestId && response.action === "FORGOT_PASSWORD_RESPONSE") {
                    clearTimeout(iframeTimeout);
                    if (!response.success) {
                        reject(response.error)
                        window.removeEventListener('message', handleMessage);;
                    }
                }
            } catch (err) {
                console.error(err);
            }
        };

        // Listen for messages from the iframe
        window.addEventListener('message', handleMessage);

        // Send login request to the iframe
        iframeRef.contentWindow.postMessage({
            action: "FORGOT_PASSWORD",
            requestId,
            ...forgotPasswordOption
        }, '*');
    });
}
