import CreateTableContext from './TableContext';

const fields = [
    "mcb_qualificationid",
    "mcb_qualification",
    "mcb_abbreviation",
    "mcb_dateearned",
    "mcb_institution",
    "mcb_qualificationlevel",
    "mcb_fieldofstudy"
];

const relations = {
    "mcb_Person": "contact",
};

const expand = {
    "mcb_TertiaryQualifications": "mcb_role",
    "mcb_CVQualifications": "mcb_cv"
};

const readOnly = [
    "modifiedon"
];

const [useQualification, QualificationProvider] = CreateTableContext(
    "mcb_qualification", {
        contextName: "Qualification",
        preSubmission: (entity, profileData) => ({
            ...entity,
            _mcb_person_value: profileData?.id 
        }), fields, relations, expand, readOnly
    }
);

export default useQualification;
export { useQualification, QualificationProvider };
