import React from 'react';
import { addClassNames } from '../../../Functions/Helper/Component';

export default function CheckInput({
    label, id,
    children, value: values,
    onChange: _onChange,
    className, inputClassName: _inputClassName,
    labelClassName,
    options, alias,
    disabledOptions,
    type = "checkbox", ...rest
}) {
    let inputClassName =  addClassNames("form-check-input", _inputClassName);
    
    if (Array.isArray(options)) {
        if (options.length > 0) return (
            <>
                {typeof label === "string"? <label className={labelClassName}>{label}</label>: label}
                {options.map(({label, value, key, options}) => (
                    <CheckInput
                        key={key || value}
                        className={className}
                        inputClassName={inputClassName}
                        id={value}
                        label={label}
                        labelClassName={labelClassName}
                        value={value? values.includes(value) : values}
                        options={options}
                        disabledOptions={disabledOptions}
                        onChange={options? _onChange : (checked) => {
                            if (typeof _onChange === "function") {
                                _onChange((values) => {
                                    if (checked && !values?.includes(value)) {
                                        return [...values, value];
                                    } else if (!checked && values?.includes(value)){
                                        return values.filter(v => v !== value);
                                    } else {
                                        return values
                                    }
                                }); 
                                
                            }
                        }}
                        {...rest}
                    >{label}</CheckInput>
                ))}
            </>
        );
        else return <></>;
    } else {
        return (
            <div className={addClassNames("form-check input ", className)}>
                <input
                    {...rest}
                    type={type}
                    checked={values && !disabledOptions?.includes(id)}
                    disabled={disabledOptions?.includes(id)}
                    onChange={(e) => _onChange && _onChange(e.target.checked)}
                    className={addClassNames(inputClassName, "ms-1 me-2")}
                    id={id}
                />
                <label className={addClassNames("form-check-label", labelClassName)} htmlFor={id}>{children || label}</label>
            </div>
        );
    }
}