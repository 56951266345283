import React from 'react';
import { addClassNames } from '../../Functions/Helper/Component';
import './XMLView.scss'; 
import processXML from './ProcessXML';
import processNumbering from './processNumbering';
import processStyle from './processStyles';


export default function XMLView({XML, relations, links}) {
    const MainXML = XML['main.xml'];
    const NumberingXML = XML['numbering.xml'];
    const StyleXML = XML['styles.xml'];
    const styles = processStyle(StyleXML);
    const numbering = processNumbering(NumberingXML);
    const processProps = { numbering, relations, links, styles };

    const {components, context} = processXML(MainXML, processProps);
    let headers = [];
    if (context.firstHeader) {
        const headerRelation = relations._children.find(rel => rel._attrs['Id'] === context.firstHeader);
        if (headerRelation && headerRelation._attrs['Target'] && XML[headerRelation._attrs['Target']]) {
            const headerFileName = headerRelation._attrs['Target'];
            const { components: headerComponents } = processXML(XML[headerFileName], processProps);
            headers = headerComponents;
        }
        
    }
    return (
        <div className="d-flex bg-white docx-container" style={{backgroundColor: 'white', height: "fit-content", margin: "auto"}}>
        <div className={addClassNames("docx-preview", context.className)} style={context.style}>
            <div className="docx-header">
                {headers.map((comp, key) => <React.Fragment key={key}>{comp}</React.Fragment>)}
            </div>
            <div className="docx-body">
                {components.map((comp, key) => <React.Fragment key={key}>{comp}</React.Fragment>)}
            </div>
        </div>
        </div>
    );
}