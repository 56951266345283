import CreateTableContext from './TableContext';

const fields = [
    "mcb_cvkeyskillachievementid",
    "mcb_statement",
    "mcb_fullstatement",
    "mcb_index"
];

const relations = {
    "mcb_Person": "contact",
    "mcb_CV": "mcb_cv",
    "mcb_Skill": "mcb_skill",
    "mcb_ABC": "mcb_abc"
};

const readOnly = [
    "modifiedon"
];

const [useCVSkillAchievement, CVSkillAchievementProvider] = CreateTableContext(
    "mcb_cvkeyskillachievement", {
        contextName: "CVSkillAchievement",
        preSubmission: (entity, profileData) => ({
            ...entity,
            _mcb_person_value : profileData?.id
        }), fields, relations, readOnly
    }
);

export default useCVSkillAchievement;
export { useCVSkillAchievement, CVSkillAchievementProvider };
