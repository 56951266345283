export function sleepAsync(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function deferred() {
    let resolve, reject;
    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return [promise, resolve,reject];
}

export function deferCall(cb) {
    if (typeof cb === "function")
        setTimeout(() => cb(), 50);
}
