import React, { useState } from 'react';
import { usePortalApi } from '../Provider/PortalApiContext';
import { ReactComponent as InvitationIcon} from 'bootstrap-icons/icons/envelope-paper.svg';
import Input from '../Components/Generic/Input';
import { useLocation } from 'react-router';

export default function Invitation() {
    const portalApi = usePortalApi();
    
    const { search } = useLocation();
    const [initCode, register] = React.useMemo(() => {
        const searchParam = new URLSearchParams(search);
        return [searchParam.get('invitation') || "", searchParam.get('step') === "register"];
    }, [search]);
    
    
    const [ initiallyInvited, setInitiallyInvited] = React.useState(false);

    React.useEffect(() => {
        if (initCode && !portalApi.loadingIframe && !initiallyInvited) {
            portalApi.inviteUser({
                invitationCode: initCode
            });
            setInitiallyInvited(true);
        }
    }, [initCode, portalApi, initiallyInvited]);

    const [invitationCode, setInvitationCode] = useState(initCode);
    const [registerState, setRegisterState] = useState({});

    const handleSubmit = React.useCallback((e) => {
        e.preventDefault();
        if (register) {
            portalApi.registerUser(registerState);
        } else {
            portalApi.inviteUser({ invitationCode });
        }
    }, [invitationCode, registerState, register, portalApi]);

    return (
        <div className='auth-page'>
            {portalApi?.validationMessage && <ul className="validation-message"> 
                {portalApi.validationMessage.split("\n").map((m, i) => (
                    <li key={i}>{m}</li>
                ))}
            </ul>}
            <form onSubmit={handleSubmit}>
            {register? (
                <>
                    <h2 className="my-2">Register Login Details</h2>
                    <Input
                        className="mb-3"
                        label="Email"
                        value={registerState.email || ""}
                        onChange={(v) => setRegisterState({...registerState, email: v})}
                        required 
                    />
                    <Input
                        className="mb-3"
                        label="User Name"
                        value={registerState.userName || ""}
                        onChange={(v) => setRegisterState({...registerState, userName: v})}
                        required 
                    />
                    <Input
                        className="mb-3"
                        label="Password"
                        type="password"
                        value={registerState.password || ""}
                        onChange={(v) => setRegisterState({...registerState, password: v})}
                        required 
                    />
                    <Input
                        className="mb-3"
                        label="Confirm Password"
                        type="password"
                        value={registerState.confirmPassword || ""}
                        onChange={(v) => setRegisterState({...registerState, confirmPassword: v})}
                        required 
                    />
                    <div className="Links">
                        <button className="btn btn-primary" type="submit">
                            <InvitationIcon className="icon"/> Register
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <h2 className="my-2">Redeem Invitation</h2>
                    <Input
                        className="mb-3"
                        label="Invitation Code"
                        value={invitationCode}
                        onChange={setInvitationCode}
                        required 
                    />
                    <div className="Links">
                        <button className="btn btn-primary" type="submit">
                            <InvitationIcon className="icon"/> Redeem Invitation
                        </button>
                    </div>
                </>
            )}
            </form>
        </div>
    );
}