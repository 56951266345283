import React from 'react';
import useResponsibility from '../Provider/TableContext/ResponsibilityContext';
import useProfile from '../Provider/TableContext/ProfileContext';
import useSkill from '../Provider/TableContext/SkillContext';
import useABC from '../Provider/TableContext/ABCContext';
import useRole from '../Provider/TableContext/RoleContext';
import CliftonStrength from '../Constants/CliftonStrengths.json';
import RoleStatus from '../Constants/RoleStatus.json';
import { lightenColor } from '../Functions/Helper/Color';

export default function useRoleData(role) {
    const { profileData, loading: profileLoading } = useProfile();
    const { abcs, loading: abcLoading } = useABC();
    const { roles, loading: roleLoading} = useRole();
    const { responsibilities, addRelationToResponsibility, removeRelationFromResponsibility, loading: respLoading } = useResponsibility();
    const { skills, loading: skillLoading } = useSkill();

    const loading = React.useMemo(() => abcLoading || respLoading || skillLoading || profileLoading || roleLoading,
        [abcLoading, respLoading, skillLoading, profileLoading, roleLoading]);

    const achievementOptions = React.useMemo(() => {
        if (loading) return [];
        const achievementOption = [];
        roles.forEach(role => {
            if(role && [RoleStatus.Current, RoleStatus.Previous].includes(role.mcb_rolestatus)) {
                abcs.forEach(abc => {
                    if(abc._mcb_role_value === role?.mcb_roleid) {
                        achievementOption.push({ label: abc.mcb_achievementstatement, value: abc.mcb_abcid});
                    }
                });
            }
        });
        
        return achievementOption;
    }, [abcs, roles, loading]);

    const [roleRequirements, roleAchievements] = React.useMemo(() => {
        if (loading || !role) return [];
        const roleRequirements = [];
        const roleAchievements = [];
        responsibilities.sort((a, b) => (a?.mcb_index || 0) - (b?.mcb_index||0)).forEach(responsibility => {
            if(responsibility._mcb_role_value === role.mcb_roleid) {
                const requirement = {...responsibility};
                requirement.achievements = [];
                (responsibility.mcb_ABCResponsibility || []).forEach(({mcb_abcid}) => {
                    const abc = abcs.find(abc => abc.mcb_abcid === mcb_abcid);
                    if (abc) {
                        requirement.achievements.push(abc);
                        roleAchievements.push(abc);
                    }
                });
                roleRequirements.push(requirement);
            }
        });

        
        return [roleRequirements, roleAchievements];
        
    }, [abcs, role, responsibilities, loading]);

    const skillData = React.useMemo(() => {
        if (loading || !role) return [];
        const skillCountMap = {};
        (role.mcb_KeySkills || []).forEach(({mcb_skillid}) => {
            skillCountMap[mcb_skillid] = 0;
        });

        roleAchievements.forEach(achievement => {
            Object.keys(skillCountMap).forEach(skillId => {
                (achievement.mcb_ABCSkill || []).forEach(({mcb_skillid}) => {
                    if (skillId === mcb_skillid) {
                        skillCountMap[skillId] += 1;
                    }
                });
            });
        });

        const data = Object.entries(skillCountMap).map(([id, value]) => ({
            label: skills?.find(skill => skill.mcb_skillid === id)?.mcb_skill,
            value 
        })).filter(d => !isNaN(d.value) && d.value)
        .sort((a, b) => Number(b.value) - Number(a.value));
        
        const otherDatas = data.splice(10);
        if (otherDatas.length > 0 ) {
            data.push({
                label: "Others",
                isEmpty: true,
                value: otherDatas.reduce((acc, cur) => acc + cur.value, 0)
            });
        }
        return data;
    }, [roleAchievements, role, loading, skills]);

    const [cliftonStrengthData, cliftonStrengthColors] = React.useMemo(() => {
        if (!profileData || !profileData?.mcb_has_cliftonstrength) return [];
        
        const strengthCount = {};
        (role?.mcb_cliftonstrength || "").split(",").forEach(strength => {
            if (!isNaN(strength)) strengthCount[strength] = 0;
        });
        
        roleAchievements?.forEach(({mcb_cliftonstrengths}) => {
            (mcb_cliftonstrengths || "").split(",").forEach(strength => {
                if (!isNaN(strength)) {
                    if(Object.keys(strengthCount).includes(strength)) {
                        strengthCount[strength]++;
                    }
                }
            });
        })
        
        const data = Object.entries(strengthCount).map(([id, value]) => {
            let option;
            if(Object.values(CliftonStrength).some(({options, color}) => {
                const foundOption = options.find(option => `${option.value}` === id);
                if (foundOption) {
                    option = {...foundOption, color};
                    return true;
                } else return false;
            })) {
                return ({
                    label: option.label,
                    value,
                    color: option.color
                });
            } else return false;
        }).filter(a => a !== false && !isNaN(a.value) && a.value)
        .sort((a, b) => Number(b.value) - Number(a.value));
        const otherDatas = data.splice(10);
        const uniqueColor = {};
        const colors = data.map(({color}) => color).map(color => {
            if (uniqueColor[color]) {
                uniqueColor[color] += 1;
                return lightenColor(color, uniqueColor[color] * 5);
            } else {
                uniqueColor[color] = 1;
                return color;
            }
        });
        if (otherDatas.length > 0 ) {
            data.push({
                label: "Others",
                isEmpty: true,
                value: otherDatas.reduce((acc, cur) => acc + cur.value, 0)
            });
        }
        return [data, colors];

    }, [profileData, role?.mcb_cliftonstrength, roleAchievements]);

    return {
        loading,
        skillData,
        profileData,
        cliftonStrengthData,
        cliftonStrengthColors,
        roleRequirements,
        removeRelationFromResponsibility,
        addRelationToResponsibility,
        achievementOptions
    };
}