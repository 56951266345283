import React from 'react';
// import { useDrag } from 'react-dnd';
import useDrag from '../../Hooks/useDrag';
import { addClassNames } from '../../Functions/Helper/Component';
import './Draggable.scss'

export default function Draggable({
    type,
    data,
    className:_className,
    children,
    onDragStart,
    onDragStop,
    onDropOver,
    onDropLeave,
    onDrop,
    ItemComponent,
    touchHandle,
    ...rest
}) {
    const dragOptions = React.useMemo(() => {    
        return {
            onDragStart,
            onDragStop,
            onDrop,
            onDropOver, 
            onDropLeave,
            touchHandle
        };
    }, [onDragStart, onDragStop, onDropOver, onDropLeave, touchHandle, onDrop]);

    const [drag, {isDragging}] = useDrag(type, data, dragOptions);

    const props = {
        ...rest,
        ref: drag,
        className: addClassNames("draggable", _className, isDragging? "active" : "")
    };
    if (ItemComponent) {
        return (
            <ItemComponent {...props}>
                {children}
            </ItemComponent>
        );
    } else return (
        <div {...props}>
            {children}
        </div>
    );
}


