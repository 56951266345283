import React from 'react';
import DonutChart from 'react-donut-chart';
import './Graph.scss';
import { addClassNames } from '../../Functions/Helper/Component';

export default function Graph({ data, colors, className, ...props}) {
    // const roundedPercent = React.useMemo(() => {
    //     const values = data.map(({ value }) => value);
    //     return to100(values);
    // }, [data]);
    return (
        <div className="chart-display">
            <DonutChart
                className={addClassNames(className, "donut-chart")}
                data={data}
                colors={colors}
                forceChartWidth={15}
                formatValues={(value,total) =>  `${Math.round(100 * value/total)}%`}
                labelRenderer={(label, item) => {
                    const { value } = item;
                    return (
                        <span className="donut-chart-legend-label">
                            <span>
                                {label}
                            </span>
                            <span
                                className="label-value"
                                style={{ backgroundColor: item.fill }}
                            >{value}</span>
                        </span>
                    );
                }}
                emptyColor='#505050'
                
                horizontalAlign='center'
                legendSide="left"
                {...props}
                selectionOpacity={1}
            />
        </div>
    );
}

/**
function to100(values) {
    const total = values.reduce((acc, cur) => acc + cur, 0);
    const roundedOffValues = [];
    
    let roundedTotal = 0;
    const roundedDownDecimalIndex = {};
    const roundedUpDecimalIndex = {};

    values.forEach((val, index) => {
        const decimal = (val / total) * 100;
        const roundedValue = Math.round(decimal);
        roundedOffValues.push(roundedValue);
        roundedTotal += roundedValue;
        const decimalIndex = decimal % 1;
        if (decimalIndex >= 0.5) {
            if (!roundedUpDecimalIndex[decimalIndex]) roundedUpDecimalIndex[decimalIndex] = [index];
            else roundedUpDecimalIndex[decimalIndex].push(index);
        } else {
            if (!roundedDownDecimalIndex[decimalIndex]) roundedDownDecimalIndex[decimalIndex] = [index];
            else roundedDownDecimalIndex[decimalIndex].push(index);
        }
    });

    let sortedDecimalIndexes = [];
    if (roundedTotal < 100) {
        sortedDecimalIndexes = Object.keys(roundedDownDecimalIndex).sort((a, b) => b - a);
    } else if (roundedTotal > 100) {
        sortedDecimalIndexes = Object.keys(roundedUpDecimalIndex).sort((a, b) => a - b);
    }

    if (sortedDecimalIndexes.length) {
        let difference = 100 - roundedTotal;
        let sign = Math.sign(difference);
        difference = Math.abs(difference);
        
        let iterationCounter = 0;
        while (difference >= 0) {
            const sortedIDX = iterationCounter % sortedDecimalIndexes.length;
            const indexes = sortedDecimalIndexes[sortedIDX];

            if(indexes.length === 0) difference--;
            else for (let i = 0; i < indexes.length; i++) {
                const index = indexes[i];
                roundedOffValues[index] += sign;
                difference--;
                if (difference < 0) break;
            }
            if (difference < 0) break;
            iterationCounter++;
        }
    }

    return roundedOffValues;
}
*/