import React from 'react';
import Input from './Generic/Input';
import SkillInput from './SkillInput';
import { ReactComponent as ClearIcon} from 'bootstrap-icons/icons/x-circle.svg';
import useABC from '../Provider/TableContext/ABCContext';
import { stripHtml } from '../Functions/Helper/RichText';
import ArrayInput from './Generic/ArrayInput';
import RichTextDisplay from './RichTextDisplay';
import useSkill from '../Provider/TableContext/SkillContext';
import LoadingSpinner from './Generic/LoadingSpinner';

const colorOverrideStyle = ( styles, { data }) => {
    return {
      ...styles,
      whiteSpace: "break-spaces",
      color: data.color
    };
}

const colorOverrideStyles = {
    option: colorOverrideStyle,
    singleValue: colorOverrideStyle
}

export function KeySkillAchievementForm({
    isNew, forceCallback, Collapsable, Collapse,
    value, arr, ActionComponent, onSubmit: _onSubmit, onCancel, onChange: _onChange
}) {

    const { abcs, loading: abcLoading } = useABC();
    const { skills, loading: skillLoading } = useSkill();
    const filter = React.useCallback(({mcb_skillid}) => !(
        arr.some(({_mcb_skill_value}) => 
            mcb_skillid === _mcb_skill_value
            && _mcb_skill_value !== value._mcb_skill_value
        )
    ), [arr, value._mcb_skill_value]);
    
    const abcOptions = React.useMemo(() => {
        return abcs.map(({mcb_abcid: value, mcb_achievementstatement, mcb_ABCSkill}) => {
            const label = stripHtml(mcb_achievementstatement);
            const color = value._mcb_skill_value
                &&  !mcb_ABCSkill.some(({mcb_skillid}) => mcb_skillid === value._mcb_skill_value) ? 
                    "var(--mo-warning)" : "initial";
            return { label, value, color };
        });
    }, [abcs]);
    
    const onSubmit = React.useCallback((data) => {
        if (value._mcb_skill_value && value._mcb_abc_value) _onSubmit(data);
    }, [_onSubmit, value]);
    
    const associateStatement = React.useCallback((value) => {
        const abc = abcs.find(({mcb_abcid}) => mcb_abcid === value._mcb_abc_value);
        const skill = skills.find(({mcb_skillid}) => mcb_skillid === value._mcb_skill_value);
        const ach = (abc?.mcb_achievementstatement && stripHtml(abc?.mcb_achievementstatement)) || "";
        return {
            ...value,
            mcb_fullstatement: `<p><strong>${skill?.mcb_skill}: </strong><span>${ach}</span></p>`,
            mcb_statement: `${skill?.mcb_skill}: ${ach}`.slice(0, 100)
        };
    }, [abcs, skills]);

    if (abcLoading || skillLoading) return <LoadingSpinner/>;    
    return (
        <div>
            {isNew && (forceCallback || Collapsable) && ( <button
                type="button"
                className="btn btn-transparent text-danger fs-4 position-absolute t-3 r-0"
                title="Clear Form"
                onClick={() => {
                    _onChange(() => ({}));
                    if (Collapsable) Collapse();
                }}
            ><ClearIcon/></button>)}
            <SkillInput
                onChange={(v) => _onChange(associateStatement({...value, _mcb_skill_value: v}))}
                value={value?._mcb_skill_value || ""}
                filter={filter}
                required
                inputClassName="fw-bold w-100"
                placeholder="Select Skill"
                single 
            />
            <Input
                type="select"
                options={abcOptions}
                value={value._mcb_abc_value}
                onChange={(v) => _onChange(associateStatement({...value, _mcb_abc_value: v}))}
                inputClassName="w-100"
                required
                placeholder="Select Achievement"
                styles={colorOverrideStyles}
            />
            {ActionComponent && (
                <div className="actions mt-4">
                    <ActionComponent disabled={!value._mcb_skill_value || !value._mcb_abc_value} {...{onSubmit, onCancel, value, submitType: "button"}} />
                </div>
            )}
        </div>
    )
}

export function KeySkillAchievementDisplay({ value}) {
    return (
        <RichTextDisplay value={value.mcb_fullstatement} />
    );
}

function associateMCBIndex(arr) {
    return arr.map((v, i) => ({...v, mcb_index: i}));
}

export default function KeySkillAchievement({value, onChange: _onChange, ...props}) {
    const onChange = React.useCallback((values) => {
        _onChange((od) => {
            if (typeof values === "function") return associateMCBIndex(values(od));
            else return associateMCBIndex(values);
        });
    }, [_onChange]);
    return (
        <ArrayInput
            inputClassName="mb-4" 
            newItemLabel="Add Key Skills & Achievements"
            newItemClassName="card text-bg-light shadow-sm p-4 mb-3"
            editItemLabel="Edit Key Skills & Achievements"
            editItemClassName="card text-bg-light shadow-sm p-4 mb-3"
            InputComponent={KeySkillAchievementForm}
            DisplayComponent={KeySkillAchievementDisplay}
            noActionComponents={true}
            keyFunction={(v) => v?.mcb_cvkeyskillachievementid}
            values={value || []}
            newValue={{_mcb_skill_value: "", _mcb_abc_value: ""}}
            displayWrapperClassName="min-w-1"
            newButtonLabel="Add Key Skills & Achievements"
            defaultNewCollapsed={true}
            onChange={onChange}
            {...props}
        />
    );
}