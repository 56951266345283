import React from 'react';
import useEmailCVs from './EmailCVs';
import useCorrectHTML from './CorrectHTML';
import { usePortalApi } from '../PortalApiContext';

const defaultValue = {
    emailCVs: () => console.error("Not implemented"),
    correctHTML: () => console.error("Not implemented")
};
const PowerFlowContext = React.createContext();

export function PowerFlowProvider({ children }) {
    const { apiCall, loadingIframe, profileData, loadedIframeLocation } = usePortalApi();
    const emailCVs = useEmailCVs(apiCall);
    const correctHTML = useCorrectHTML(apiCall);
    const value = React.useMemo(() => {       
        if (!loadingIframe && profileData && loadedIframeLocation)  {
            return {
                emailCVs,
                correctHTML
            };
        } else return defaultValue;
    }, [loadingIframe, profileData, loadedIframeLocation, emailCVs, correctHTML]);

    return (
        <PowerFlowContext.Provider value={value}>
            {children}
        </PowerFlowContext.Provider>
    );
}

export default function usePowerFlow() {
    return React.useContext(PowerFlowContext);
}