import React from "react";

export default function RichTextDisplay({value}) {
    const containerRef = React.useRef();
    const shadowRootRef = React.useRef(null);
    React.useEffect(() => {
        if (!containerRef.current) return;
        if (!shadowRootRef.current) {
            shadowRootRef.current = containerRef.current.attachShadow({mode: 'open'});
        }
        shadowRootRef.current.innerHTML = value;
    }, [value]);
    return (
        <div
            ref={containerRef}
        />
    );
}
