import React from 'react';
import { ReactComponent as UploadIcon } from 'bootstrap-icons/icons/upload.svg';
import useProfile from '../Provider/TableContext/ProfileContext';
import { Link } from 'react-router-dom';
import { addClassNames } from '../Functions/Helper/Component';

export default function CheckCliftonStrength({ children, label, className}) {
    const { profileData } = useProfile();
    if (profileData?.mcb_has_cliftonstrength) {
        return children;
    } else {
        const dialog = (
            <div className={addClassNames("d-flex flex-column justify-content-center align-items-center gap-2 py-2", className)}>
                <Link 
                    to="https://mycareerbrand.net/products/cliftonstrengths34-assessment-and-coaching"
                    target='_blank'
                    rel='noreferrer'
                    className="btn btn-outline-link-color"
                >
                    Take your CliftonStrengths Assessment
                </Link>
                <div className="fw-bold">OR</div>
                <Link className="btn btn-link-color" to="/profile#clifton-strength-input">
                    <UploadIcon className="me-2"/> Upload your CliftonStrengths Report
                </Link>
            
            </div>
        );
        if (label) return (
            <div className="input">
                <label>{label}</label>
                {dialog}
            </div>
        ); else return dialog;
    }

}