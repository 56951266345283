import React from 'react';
import Form from './Generic/Form';
import { SingleInputColumn } from './Generic/InputGrouping';
import SkillInput from './SkillInput';
import { addClassNames } from '../Functions/Helper/Component';
import CheckInput from './Generic/Input/CheckInput';
import useAchievementOptions from '../Hooks/useAchievementOptions';

export default function SkillForm({ onSubmit, formData, setFormData, onCancel, ActionComponent, className }) {
    const [options] = useAchievementOptions();
    
    const formRef = React.useRef(null);
    
    return (
        <Form
            ref={formRef}
            onSubmit={onSubmit}
            className={addClassNames("achievement-form d-flex flex-column gap-4 mb-4", className)}
        >
            <SingleInputColumn
                state={formData}
                setState={setFormData}
                inputs={[
                    ['skills', SkillInput, (
                        <span>
                            <strong>Skills</strong> (1,2 or 3 words e.g. Leadership)
                        </span>
                    ), { required: true }],
                    ['achievements', CheckInput, (
                        <label>
                            <strong>Select Achievements where the above Skills and CliftonStrengths are applied</strong> (at least one)<span className='required-tag'> *</span>
                        </label>
                    ), { options }]

                ]}
            />
            {ActionComponent && (
                <div key="actions">
                    <ActionComponent {...{onSubmit, onCancel, formData, submitType: "submit"}} />
                </div>
            )}
        </Form>
    );
}
