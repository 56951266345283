import React from 'react';
import { monthDate } from '../Functions/Helper/Month';
import useABC from '../Provider/TableContext/ABCContext';
import useRole from '../Provider/TableContext/RoleContext';
import RoleStatus from '../Constants/RoleStatus.json';
import { v4 as uuidv4 } from 'uuid';
import { AchievementDisplay } from '../Components/AchievementsInput';

export default function useAchievementOptions(search, roleSelection, onRoleSelected) {
    const { roles, loading: roleLoading } = useRole();
    const uuid = React.useMemo(() => uuidv4(), []);
    const abcContext = useABC();
    const { abcs, loading: abcLoading } = abcContext;
    const options = React.useMemo(() => {
        const _abcs = [...abcs];
        const result = [];
        const keywords = search?.toLowerCase().split(/\s/).filter(a => a);
        
        (roles || []).sort((a, b) => new Date(b.mcb_starteddate) - new Date(a.mcb_starteddate)).forEach(role => {
            const options = [];
            const roleSearched = keywords && keywords.every(keyword => 
                role?.mcb_roletitle?.toLowerCase().includes( keyword || "")
                || role?.mcb_rolecompanyname?.toLowerCase().includes( keyword || "")
            );
            if(
                role?.mcb_roleid && [
                    RoleStatus.Current, RoleStatus.Previous
                ].includes( role?.mcb_rolestatus)
            ) {
                let abcs = [];
                for (let c = 0; c < _abcs.length; c++) {
                    const abc = _abcs[c];
                    if (
                        abc?._mcb_role_value === role?.mcb_roleid
                        && abc?.mcb_achievementstatement
                        && (!keywords || roleSearched || keywords.every(keyword => 
                            abc?.mcb_achievementstatement?.toLowerCase().includes( keyword || "")
                        ))
                    ) {
                        abcs.push(abc);
                        _abcs.splice(c--, 1);
                    }
                }

                abcs.sort((a, b) => (a.mcb_index || 0) - (b.mcb_index || 0)).forEach(abc => {
                    options.push({
                        label: (
                            <AchievementDisplay value={abc}/>
                        ), value: abc?.mcb_abcid, abc
                    });
                })

                result.push({ 
                    label: (
                        <div className="d-flex">
                            <div className="d-flex flex-row flex-nowrap align-items-baseline gap-1">
                                {roleSelection && <input
                                    type="checkbox"
                                    className="form-check-input ms-1 me-2"
                                    checked={roleSelection.includes(role?.mcb_roleid)}
                                    id={`${uuid}-${role?.mcb_roleid}`}
                                    onChange={(e) => {
                                        if (typeof onRoleSelected === "function")
                                            onRoleSelected(role?.mcb_roleid, e.target.checked, abcs.filter(abc => abc?._mcb_role_value === role?.mcb_roleid));
                                    }}
                                />}
                                <label className="unstyled" htmlFor={`${uuid}-${role?.mcb_roleid}`}><span className="fs-larger">{role?.mcb_roletitle},</span><span className="fs-medium">{role?.mcb_rolecompanyname}</span></label>
                            </div>
                            <div className="flex-grow-1 fs-medium text-end">{monthDate(role?.mcb_starteddate)} - {role?.mcb_rolestatus === RoleStatus.Current? "Current" : monthDate(role?.mcb_endeddate)} </div>
                        </div>
                    ),
                    key: role?.mcb_roleid, role, options 
                })
            }
        });
        return result;
    }, [abcs, search, roleSelection, onRoleSelected, uuid, roles]);

    const loading = React.useMemo(() => roleLoading || abcLoading, [roleLoading, abcLoading]);

    return [options, loading, abcContext];
}