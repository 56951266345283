import React from 'react'
import { SideNav } from '../Components/SideNav';
import Navigation from '../Components/Navigation';
function NavDisplayedLayout({ children }) {

    return (
        <>
        <Navigation/>
        <div className='d-flex flex-row flex-nowrap gap-3 flex-grow-1 align-items-stretch'>
            <div className="d-none d-lg-flex flex-column h-100vh position-sticky align-items-center t-0 px-3 py-4 bg-light min-w-max-content">
                    <SideNav className="t-4 align-self-start position-sticky d-flex flex-column flex-grow-1 gap-3 "/>
            </div>
            <div className='container-fluid overflow-auto py-4 d-flex flex-column gap-5'>
                {children}
            </div>
        </div>
        </>
    );
}
export default function PageLayout(PageFunctionalComponent) {
    return (props) => (
        <NavDisplayedLayout>
            <PageFunctionalComponent {...props} />
        </NavDisplayedLayout>
    );
}