import React from 'react';
import FormModal from './Generic/FormModal';
import SkillForm from './SkillForm';
import { ReactComponent as  AddIcon } from 'bootstrap-icons/icons/plus-circle.svg';
import useABC from '../Provider/TableContext/ABCContext';

export default function SkillAssociationModal({title, actionName,  onClose: _onClose,...props}) {
    const [formData, setFormData] = React.useState({ skills: [], achievements: []});
    const { abcs, loading, addRelationToABC } = useABC();
    const onClose = React.useCallback(() => {
        _onClose?.(); 
    }, [_onClose]);

    const onSubmit = React.useCallback(async () => {
        
        if (!formData?.achievements?.length) return false;
        const promises = [];
        
        formData.achievements.forEach(achievement => {
            const abc = abcs.find(({mcb_abcid}) => mcb_abcid === achievement);
            const skills = formData.skills.filter(skill => !abc?.mcb_ABCSkill?.find(({mcb_skillid}) => mcb_skillid === skill));
            
            if (skills.length) {
                skills.forEach(skill => {
                    promises.push(addRelationToABC(abc.mcb_abcid, "mcb_ABCSkill", skill));
                });
            }
        });
        await Promise.all(promises);
        return true;
    }, [formData, abcs, addRelationToABC]);
    return (
        <>
            <FormModal
                title={(title || "Add Skills")}
                actionName={(actionName || <><AddIcon className="me-2"/> Add Skills</>)}
                classNames={{
                    modalTitleClassName: "btn-primary",
                    modalDialogClassName: "modal-dialog-centered", 
                    modalTriggerClassName: "btn-outline-dark border-0 fs-5 p-0 shadow-none text-decoration-underline",
                    closeActionClassName: "btn icon-button",
                    submitActionClassName: "btn btn-primary",
                }}
                BodyComponent={SkillForm}
                onClose={onClose}
                formData={formData}
                setFormData={setFormData}
                onSubmit={onSubmit}
                loading={loading}
                {...props}
            />
        </>
    );
}