import React from 'react';
import { ReactComponent as FloppyIcon } from 'bootstrap-icons/icons/floppy-fill.svg';
import { ReactComponent as TrashIcon } from 'bootstrap-icons/icons/trash-fill.svg';
import { ReactComponent as AdditionalOptionsIcon } from 'bootstrap-icons/icons/three-dots-vertical.svg';
import { ReactComponent as Plus } from 'bootstrap-icons/icons/plus-circle-fill.svg';
import { addClassNames } from '../../Functions/Helper/Component';
import { bindOnChangeArray } from '../../Functions/Helper/OnChange';
import { v4 as uuidv4 } from 'uuid';
import './ArrayInput.scss';
import Orderable from './Orderable';
import useActionDialog from '../../Provider/ ActionDialogContext';

function DefaultCollapseButton(label) {

    return ({setCollapsed}) => (
        <button type="button" className="btn btn-primary mb-4 align-self-start" onClick={() => setCollapsed(false)}>
            <Plus className="me-2"/> {label || "Add"}</button>
    );
}


function getItemForm({
    InputComponent,
    addButtonProps,
    updateButtonProps,
    cancelButtonProps,
    isNew,
    onItemSubmit,
    onItemRemove,
    noActionComponents,
    className
}) {
    const SubmitButton = function ({ value, disabled, type, onSubmit }) { 
        const {label, children, onClick, ...props} = (
            isNew? addButtonProps : updateButtonProps
        ) || {};
        const submit = (e) => {
            if (typeof onClick !== "function" || onClick(e) !== false) {
                onSubmit();
            }
        }
        return (
            <button
                className={"btn btn-primary " +  (isNew? "edit-item-submit" : "new-item-submit")}
                onClick={(e) => {
                    e.value = value;
                    if (typeof onClick === "function") onClick(e);
                    if (type !== "submit") submit(e);
                }}
                disabled={disabled}
                type={type || "button"}
                {...props}
            >{children || (<>
                <FloppyIcon className="me-2"/>{label || (isNew? "Add" : "Update")}
            </>)}</button> 
        );
    };

    const CancelButton = function ({ value, onCancel }) {
        const {label, children, onClick, ...props} = cancelButtonProps || {};
        return (
            <button
                className="btn btn-outline-secondary"
                onClick={(e) => {
                    e.value = value;
                    if (typeof onClick !== "function" || onClick(e) !== false) {
                        onCancel()
                    }
                }}
                type="button"
                {...props}
            >{children || (label || "Cancel")}</button>
        );
    }
    
    const ActionComponent = function ({onSubmit, onCancel, submitType, ...props}) {
            return (
                <div className="action-components d-flex justify-content-between align-items-center">
                    <SubmitButton type={submitType || "button"} onSubmit={onSubmit} {...props} />
                    {!isNew && <CancelButton onCancel={onCancel} {...props} />}
                </div>
            );
        
        };

    return function ({onCancel, Collapsable, CollapseNew, arr, ...props}) {
        const [value, setValue] = React.useState(props.value || {});
        const onSubmit = React.useCallback(() => {
            let result;
            if (isNew) {
                result = onItemSubmit(value, props.value);
                setValue({});
                CollapseNew();
            } else {
                result = onItemSubmit(props.index, value, props.value);
                onCancel();
            }
            return result;
        }, [props.index, props.value, CollapseNew, value, onCancel]);

        return (
            <div className={addClassNames("array-input-item d-flex flex-column gap-1", className)}>
                <InputComponent
                    value={value}
                    onChange={(_value) => {
                        setValue(_value);
                    }}
                    isNew={isNew}
                    Collapsable={Collapsable}
                    Collapse={CollapseNew}
                    onSubmit={onSubmit}
                    onRemove={() => {
                        onItemRemove(props.index, props.value);
                    }}
                    onCancel={onCancel}
                    ActionComponent={ActionComponent}
                    arr={arr}
                />
                {noActionComponents? <></> :  (
                    <ActionComponent 
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        value={value}
                    />
                )}
            </div>
            
        )
    }

}

function getItemDisplay({
    DisplayComponent,
    additionalActions,
    removeButtonProps,
    displayWrapperClassName,
    onItemSubmit,
    onItemRemove,
    noClickWrapper,
    className
}) {

    const AdditionalOptions = ({ value, index }) => {
        const [dropdown, setDropdown] = React.useState(false);
        const {label, children, onClick, ...props} = removeButtonProps || {};
        const { confirm } = useActionDialog();
        return (
            <div className={addClassNames("dropdown-container right", className)}>
                <button
                    className="btn btn-outline-secondary"
                    onClick={() => setDropdown(!dropdown)}
                    onBlur={(e) => {
                        setDropdown(false);
                        const relatedTarget = e.relatedTarget;
                        if (
                            relatedTarget 
                            && e.target.parentNode.contains(relatedTarget)
                            && relatedTarget.tagName === "BUTTON"
                            && typeof relatedTarget.click === "function"
                        ) {
                            relatedTarget.click();
                        }
                    }}
                    type="button"
                ><AdditionalOptionsIcon/></button>
                <ul className={addClassNames("dropdown-menu",(dropdown?"show" : ""))}>
                    {additionalActions && additionalActions.map(([label, action], i) => (
                        <li key={i}>
                            <button className="dropdown-item"
                                onClick={() => {action(index, value)}}
                            >{label}</button>
                        </li>
                    ))}
                    {additionalActions && <li><hr className="dropdown-divider"/></li>}
                    <li key="remove-button">
                        <button
                            type="button"
                            className="dropdown-item text-danger"
                            onClick={async (e) => {
                                e.index = index;
                                e.value = value;
                                if (await confirm("This could not be undone. Are you sure?"))
                                if (typeof onClick !== "function" || onClick(e) !== false) {
                                    onItemRemove(index, value);
                                }
                            }}
                            {...props}
                        >{children || (<>
                            <TrashIcon className="me-2"/>{label? label : " Delete"}
                        </>)}</button>
                    </li>
                </ul>
            </div>
        );
    }

    const DisplayComponentWrapper = ({ index, value, onEdit }) => {

        return (
            <div {...(noClickWrapper?{}:{ onClick: (e) => {
                e.preventDefault();
                onEdit();
            }})} className={addClassNames("display-item-container flex-grow-1 me-1", displayWrapperClassName)}>
                <DisplayComponent onChange={(_value) => {
                    onItemSubmit(index, _value, value)
                }} value={value} onEdit={onEdit} />
            </div>
        );
    }
    return function ({ onEdit, ...props}) {
        return (
            <>
                <DisplayComponentWrapper onEdit={onEdit} {...props}/>
                <AdditionalOptions {...props}/>
            </>
            
        );
    }
}


function getItemComponent({
    itemClassName, inputClassName, 
    editItemClassName, editItemLabel,
    ...getProps}) {

    const EditRenderer = getItemForm({
        ...getProps,
        className: inputClassName || itemClassName || "",
        isNew: false
    });

    const DisplayRenderer = getProps.DisplayComponent && getItemDisplay({
        className: itemClassName || "", ...getProps
    });
    
    return function ({initiallyEdit, ...props}) {
        const [editing, setEditing] = React.useState(initiallyEdit);
        
        if (editing || !DisplayRenderer) {
            return (
                <div className={addClassNames('new-item', editItemClassName)}>
                    {editItemLabel && ( <h3 className="mb-3 fs-5">
                        {typeof editItemLabel === "function"? editItemLabel(props.value) : editItemLabel}
                    </h3> ) }
                    <EditRenderer
                        {...props}
                        onCancel={() => setEditing(false)}
                    />
                </div>
                
            ); 
        } else {
            return (
                <DisplayRenderer
                    {...props}
                    onEdit={() => setEditing(true)}
                />
                
            );
        }
    }
}

export default function ArrayInput({
    InputComponent,
    DisplayComponent,
    additionalActions,
    addButtonProps,
    updateButtonProps,
    cancelButtonProps,
    removeButtonProps,
    noActionComponents,
    itemClassName,
    inputClassName,
    values,
    newValue,
    keyFunction,
    editItemLabel,
    editItemClassName,
    label,
    newItemLabel,
    newItemClassName,
    displayWrapperClassName,
    className,
    children,
    newButtonLabel,
    CollapsedNewView: _CollapseNewView,
    defaultNewCollapsed,
    noClickWrapper,
    onChange
}) {
    const [isCollapsed, setNewCollapsed] = React.useState(defaultNewCollapsed || false);
    const CollapsedNewView = React.useMemo(() => {
        if (_CollapseNewView) return _CollapseNewView;
        else if (newButtonLabel) return DefaultCollapseButton(newButtonLabel);
    }, [_CollapseNewView, newButtonLabel]);
    const containerRef = React.useRef();
    const ItemComponent = React.useMemo(() => getItemComponent({
        InputComponent,
        DisplayComponent,
        updateButtonProps,
        cancelButtonProps,
        removeButtonProps,
        additionalActions,
        editItemLabel,
        editItemClassName,
        inputClassName,
        itemClassName,
        displayWrapperClassName,
        noClickWrapper,
        onItemSubmit: (index, value) => {
            if (typeof onChange === "function") {
                return bindOnChangeArray(onChange, index)(value);
            }
        },
        onItemRemove: (index) => {
            if (typeof onChange === "function") {
                return onChange((values) => {
                    const newValues = values.slice();
                    newValues.splice(index, 1);
                    return newValues;
                });
            }
        },
        noActionComponents
    }), [
        InputComponent,
        DisplayComponent,
        updateButtonProps,
        cancelButtonProps,
        removeButtonProps,
        additionalActions,
        editItemLabel,
        editItemClassName,
        onChange,
        inputClassName,
        itemClassName, 
        noActionComponents,
        noClickWrapper,
        displayWrapperClassName
    ]);

    const NewItemForm = React.useMemo(() => getItemForm({
        InputComponent,
        addButtonProps,
        cancelButtonProps,
        isNew: true,
        value: newValue,
        inputClassName,
        itemClassName,
        onItemSubmit: (value) => {
            if (typeof onChange === "function") return onChange((values) => {
                const newValues = (values || []).slice();
                const _value = {...value, key: uuidv4()};
                newValues.push(_value);
                return newValues;
            });
        },
        noActionComponents
    }), [
        InputComponent,
        addButtonProps,
        cancelButtonProps,
        newValue,
        onChange,
        inputClassName,
        itemClassName,
        noActionComponents
    ]);

    return (
        <div ref={containerRef} className={addClassNames("input array-input", className)}>
            {(children || label) && <label>{children || label}</label>}
            <div className="values d-flex flex-column gap-3">
                <Orderable
                    onChange={onChange}
                    values={values}
                    ItemComponent={ItemComponent}
                    keyFunction={keyFunction}
                />
                {CollapsedNewView && isCollapsed ?
                    (<CollapsedNewView setCollapsed={setNewCollapsed}/>)
                    : (
                        <div className={addClassNames('new-item', newItemClassName)}>
                            {newItemLabel && ( <h3 className="mb-3 fs-5">{newItemLabel}</h3> ) }
                            
                                <NewItemForm
                                    key="new-item"
                                    CollapseNew={() => setNewCollapsed(true)}
                                    Collapsable={!!CollapsedNewView}
                                    arr={values}
                                    className={addClassNames(`new-item`, inputClassName || itemClassName || "")}
                                />
                        </div>
                    )
                }
            </div>
        </div>
    );
}