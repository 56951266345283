
export default async function ForgotPasswordActionHandler(json) {
    if(/^\/account\/login\/forgotpassword/i.test(window.location.pathname)) {
        try {
            document.getElementById("Email").value = json.email;
            setTimeout(() => {
                document.getElementById("submit-forgot-password").click()
            }, 10);
            return {success: true};
        } catch (err) {
            return {success: false, error: err};
        }
    } else {
        return {success: false, error: new Error("Not on forgot password page.")};
    }
}

