import React from 'react';
import useDrop from '../../Hooks/useDrop';
import { addClassNames } from '../../Functions/Helper/Component';
import './Droppable.scss';


export default function Droppable({
    type,
    className: _className,
    onDrop,
    onDragOver,
    onDragLeave,
    children,
    ...rest
}) {
    const [_onDrop, options] = React.useMemo(() => {
        const _onDragOver = (props) => {
            if (props === false) return;
            if (onDragOver) onDragOver(props);
        };
        const _onDragLeave = (props) => {
            if (props === false) return;
            if (onDragLeave) onDragLeave(props);
        };
        const _onDrop = (...args) => {
            if (onDrop) onDrop(...args);
        }
        return [_onDrop, {
            onDragOver: _onDragOver,
            onDragLeave: _onDragLeave
        }];
    }, [onDragOver, onDragLeave, onDrop]);

    const [drop, {isOver}] = useDrop(type, _onDrop, options);
    return (
        <div
            {...rest}
            ref={drop}
            className={addClassNames("droppable", _className, isOver? "active" : "")}
        >
            {children}
        </div>
    );
};
