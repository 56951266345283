import React from 'react';

function _Li({ children, ...rest}, ref) {
    return (
        <li {...rest} ref={ref}>
            {children}
        </li>
    );
}
const Li = React.forwardRef(_Li);


function _Div({ children, ...rest}, ref) {
    return (
        <li {...rest} ref={ref}>
            {children}
        </li>
    );
}
const Div = React.forwardRef(_Div);
export { Li, Div };