import React from "react";

export default function RichTextDisplay({value}) {
    return (
        <div
            ref={el => {
                if (el) {
                    el.innerHTML = value || "";
                }
            }}
        />
    );
}
