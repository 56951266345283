import React, { useState } from 'react';
import { usePortalApi } from '../Provider/PortalApiContext';
import { Link } from 'react-router-dom';
import { ReactComponent as LoginIcon} from 'bootstrap-icons/icons/box-arrow-in-right.svg';
import Input from '../Components/Generic/Input';

const SignIn = () => {
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const portalApi = usePortalApi();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your login logic here
        portalApi.signIn({
            username,
            password,
            rememberMe
        });
    };

    return (
        <div className='auth-page'>
            {portalApi?.validationMessage && <ul className="validation-message"> 
                {portalApi.validationMessage.split("\n").map((m, i) => (
                    <li key={i}>{m}</li>
                ))}
            </ul>}
            <form onSubmit={handleSubmit}>
                <Input label="User Name" required className="mb-3" value={username} onChange={setUserName} />
                <Input label="Password" required className="mb-3" value={password} onChange={setPassword} type="password" />
                <Input label="Remember me" className="mb-3" value={rememberMe} onChange={setRememberMe} type="checkbox" />
                <div className="Links">
                    <button className="btn btn-primary" type="submit"><LoginIcon className="icon"/> Login</button>
                    <Link to="/forgot-password">Forgot Password</Link>
                </div>

            </form>
        </div>
    );
};

export default SignIn;
