import React from 'react';
import { useNavigate } from 'react-router';
import Form from '../../Components/Generic/Form';
import LoadingSpinner from '../../Components/Generic/LoadingSpinner';
import { TwoInputColumns, SingleInputColumn } from '../../Components/Generic/InputGrouping';
import useAchievementOptions from '../../Hooks/useAchievementOptions';
import CheckInput from '../../Components/Generic/Input/CheckInput';
import SkillInput from '../../Components/SkillInput';
import useQualificationOptions from '../../Hooks/useQualificationOptions';
import CliftonStrengthInput from '../../Components/CliftonStrengthInput/CliftonStrengthInput';
import KeySkillAchievement from '../KeySkillAchievements';

export default function CVForm({ onNext, next, formData, setFormData, updateCV, loading, redirectUrl, loadingText}) {
    const cvData = React.useMemo(() => (formData?.cv || {}), [formData?.cv]);
    const setCVData = React.useCallback(
        (newData) => setFormData((od) => ({ ...od, cv: (typeof newData === "function"? newData(od?.cv): newData)})), [setFormData]
    );
    const [disabledABCs, setDisabledABCs] = React.useState([]);
    const [achievementOptions, achievementOptionsLoading] = useAchievementOptions(undefined, cvData.cvRoles || [], (roleId, checked, affectedABCs) => {
        setCVData((cv) => {
            const cvRoles = cv.cvRoles || [];
            if (checked) {
                if(!cvRoles.includes(roleId)) {
                    return { ...cv, cvRoles: [...cvRoles, roleId] };
                }
            } else {
                const index = cvRoles.indexOf(roleId);
                if (index >= 0) {
                    const _cvRoles = [...cvRoles.slice(0, index), ...cvRoles.slice(index + 1)];
                    return { ...cv, cvRoles: _cvRoles};
                }
            }
            return cv;
        });

        setDisabledABCs((disabledABCs) => {
            const newABCS = new Set(disabledABCs || []);
            if (checked) {
                affectedABCs.forEach(abc => newABCS.delete(abc.mcb_abcid));
            } else {
                affectedABCs.forEach(abc => newABCS.add(abc.mcb_abcid));
            }
            return [...newABCS];
        });
    
    });
    const [qualificationOptions, qualificationOptionsLoading] = useQualificationOptions();
    
    const navigate = useNavigate();
    const formRef = React.useRef();
    const [formLoading, setFormLoading] = React.useState(false);  
    onNext(async () => {
        if(formRef.current.reportValidity()) {
            setFormLoading(true);
            try {
                await updateCV();
                const navigateUrl = redirectUrl();
                if (navigateUrl) {
                    navigate(navigateUrl);
                    setFormLoading(false);
                }
            } catch (e) {
                if (e.userReadable) alert(e.message);
                else console.error(e);
                setFormLoading(false);
            }
        }
        return false;
    });

    if (loading || achievementOptionsLoading || qualificationOptionsLoading || formLoading) return (
        <LoadingSpinner style={{ background: "transparent", backdropFilter: "none"}}>
            {loadingText}...
        </LoadingSpinner>
    );
    return (
        <Form 
            ref={formRef}
            onSubmit={(e) => { 
                e.preventDefault();
                next(); 
            }}
            className="my-2 d-flex flex-column gap-3"
        >
            <h3 key="personal-details" className="h4">Personal Details</h3>
            <TwoInputColumns
                state={(cvData || {})}
                setState={setCVData}
                inputs={[ 
                    ['mcb_firstname', 'text', 'First Name', {required: true}],
                    ['mcb_lastname', 'text', 'Last Name', {required: true}],
                    ['mcb_email', 'email', 'Email', {required: !cvData?.mcb_phone || cvData?.mcb_email}],
                    ['mcb_phone', 'tel', 'Phone', {required: !cvData?.mcb_email || cvData?.mcb_phone}],
                    ['mcb_title', 'text', 'Job Title', {required: true}],
                    ['mcb_mainqualification', 'text', 'Main Qualification', {required: true}],
                    ['mcb_currentcity', 'text', 'Current City', {required: true}],
                    ['mcb_linkedin', 'url', 'LinkedIn Url ']

                ]}
            />
            <SingleInputColumn
                state={(cvData || {})}
                setState={setCVData}
                inputs={[
                    ['mcb_cvname', 'text', 'CV Name', {required: true}],
                    ['mcb_sectors', 'text', 'Sectors'],
                    ["mcb_profilesummary", "richtext", <span>Profile Summary <em className='fs-smaller'>(max 1650 char)</em></span>, {
                        required: true,
                        maxCharacterCount: 1650
                    }],
                    ["keySkillsAchievements", KeySkillAchievement, <span className="h4">Key Skills & Achievements</span>, {required: true}],
                    ["skills", SkillInput, <span className="h5">Skills</span>, {required: true, labelClassName: "mt-3"}],
                    ["cliftonstrengths", CliftonStrengthInput, <span className="h5">Clifton Strengths</span>, { multiselect: true, labelClassName: "mt-3"}],
                    ["achievements", CheckInput, <span className="h4 mt-3">Experiences</span>, {
                        options: achievementOptions,
                        disabledOptions: disabledABCs
                    }],
                    ["qualifications", CheckInput, <span className="h4 mt-3">Qualifications</span>, {options: qualificationOptions, className: "d-flex", labelClassName: "flex-grow-1"}],
                    ["mcb_coverletter", "richtext", <span className="h4">Cover Letter <small className="fw-normal fs-6">(max 2500 char)</small></span>, {required: true,maxCharacterCount: 2500, labelClassName: "mt-3"}]
                ]}
            />      
        </Form>
    );
}