import React from 'react';
import SimpleJobApplicationCard from '../Components/SimpleJobApplicationCard';
import SimpleAchievementCard from '../Components/SimpleAchievementCard';
import PromoBanner from '../Components/PromoBanner';
import SkillGraphCard from '../Components/SkillGraphCard';
import CliftonGraphCard from '../Components/CliftonGraphCard';
import AchievementsCard from '../Components/AchievementsCard';
import IdealRolePanel from '../Components/IdealRolePanel';
import ApplyingRolePreview from '../Components/ApplyingRolePreview';

export default function Home() {
    return (
        <>
            <div className="d-flex flex-row flex-wrap gap-4 w-100">
                <SimpleJobApplicationCard className="flex-grow-1 shadow"/>
                <SimpleAchievementCard className="flex-grow-1 shadow"/>
            </div>
            <PromoBanner className="w-100 shadow" />
            <div className="d-flex flex-row flex-wrap flex-lg-nowrap align-items-stretch gap-4">
                <div className="d-flex flex-column gap-4 flex-grow-1">
                    <SkillGraphCard className="flex-grow-1 w-100 shadow"/>
                    <CliftonGraphCard className="flex-grow-1 w-100 shadow"/>
                </div>
                <div className="flex-grow-1">
                    <AchievementsCard className="flex-grow-1 w-100 h-100 shadow"/>
                </div>
            </div>
            <div id="ideal-job">
                <IdealRolePanel showMyIdealRole={true} className="w-100 shadow"/>
            </div>
            <div>
                <ApplyingRolePreview limit={4} className="w-100 shadow"/>
            </div>
        </>
    );
}