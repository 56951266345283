import React, { createContext, useContext } from 'react';
import useConstant from './ConstantContext';
import usePortalFrame from '../Hooks/usePortalFrame';
import ActionsViaFrame from '../Functions/ActionViaFrame';
import LoadingSpinner from '../Components/Generic/LoadingSpinner';

/**
 * Context for the web API.
 * @type {React.Context}
 */
const WebApiContext = createContext();


/**
 * Provides the PortalApiProvider component.
 * @param {Object} props - The component props.
 * @returns {JSX.Element} The PortalApiProvider component.
 */
function PortalApiProvider({ children }) {
  const portalURL = useConstant().portalURL;
  const [iframeRef, loadingIframe, profileData, validationMessage, loadedIframeLocation] = usePortalFrame(portalURL);

  const actions = React.useMemo(() => {
    const result = {};
    Object.entries(ActionsViaFrame).forEach(([key, action]) => {
      result[key] = (options) => action(iframeRef, options);
    });
    return result;
  }, [iframeRef]);
  const value = React.useMemo(() => ({
    ...actions,
    profileData,
    validationMessage,
    loadingIframe,
    loadedIframeLocation
  }), [actions, profileData, validationMessage, loadingIframe, loadedIframeLocation]);
  return (
    <WebApiContext.Provider value={value}>
      {children}
      {loadingIframe? <LoadingSpinner style={{zIndex: "9999999"}} fullScreen/> : null}
    </WebApiContext.Provider>
  );
}

/**
 * Custom hook for accessing the web API.
 * @returns {Function} The function for making API requests.
 */
function usePortalApi() {
  return useContext(WebApiContext);
}

export { PortalApiProvider, usePortalApi };
