import React from 'react';
import CreateTableContext from './TableContext';

const fields = [ "mcb_coverlettertemplateid", "mcb_name", "mcb_document" ];
const readOnly = [ "modifiedon"];
function processContextData({datas, downloadFileFromData}) {
    const coverLetterTemplateData = datas[0];
    return { 
        coverLetterTemplateData,
        downloadCoverLetterTemplate: (setLoading) =>   downloadFileFromData(coverLetterTemplateData.mcb_coverlettertemplateid, "mcb_document", setLoading)
    };
}

const [useCoverLetterTemplateContext, CoverLetterTemplateProvider] = CreateTableContext(
    "mcb_coverlettertemplate", {processContextData, fields, readOnly }
);

function useCoverLetterTemplate() {
    const { coverLetterTemplateData, downloadCoverLetterTemplate } = useCoverLetterTemplateContext();
    const [coverLetterTemplate, setCoverLetterTemplate] = React.useState(null);
    React.useEffect(() => {
        if (coverLetterTemplateData?.mcb_coverlettertemplateid) {
            downloadCoverLetterTemplate().then(({arrayBuffer}) => {
                setCoverLetterTemplate(arrayBuffer);
            });
        }
    }, [coverLetterTemplateData, downloadCoverLetterTemplate]);
    return { coverLetterTemplate, loading: !coverLetterTemplateData };

}

export default useCoverLetterTemplate;
export { useCoverLetterTemplate, CoverLetterTemplateProvider };
