import React from 'react';
import useCVs from '../Provider/TableContext/CVContext';

export default function useCVOption() {
    const { cvs, loading } = useCVs();
    
    const options = React.useMemo(() => {
        return (cvs || []).map(cv => ({ 
            label: cv.mcb_cvname,
            value: cv?.mcb_cvid,
            cv
        }));
    }, [cvs]);

    return [options, loading];
}