import CreateTableContext from './TableContext';
const fields = [
    "mcb_skill",
    "mcb_skillid",
    "mcb_occurrence"
];

const relations = {
    "mcb_Person": "contact",
};

const expand = {
    "mcb_ABCSkill": "mcb_abc",
    "mcb_CVKeySkill": "mcb_cv",
    "mcb_KeySkills": "mcb_role"
};

const readOnly = [
    "modifiedon"
];

const [useSkill, SkillProvider] = CreateTableContext(
    "mcb_skill", {
        contextName: "Skill",
        preSubmission: (entity, profileData) => ({
            ...entity,
            _mcb_person_value: profileData?.id 
        }), fields, relations, readOnly, expand
    }
);

export default useSkill;
export { useSkill, SkillProvider };
