import React from 'react';
import { ReactComponent as UploadIcon } from 'bootstrap-icons/icons/upload.svg';
import CliftonStrengthInput from './CliftonStrengthInput';
import { getColor } from '../../Constants/CliftonStrengths';
import './CliftonStrengthInput.scss';
import { Link } from 'react-router-dom';

export default function TopCliftonStrengthInput({ 
    hasCliftonStrength,
    setHasCliftonStrength,
    strengthValues,
    setNthStrength,
    browseFile,
    isNew= true,
    id
}) {
    const [showCliftonFileUpload, setShowCliftonFileUpload] = React.useState(false);
    
    const topStrengthInputs = [];
    for (let i = 0; i < 10; i++) {
        const value = (strengthValues || [])[i];
        const color = value && getColor(value);
        topStrengthInputs.push(
            <div key={i} className="d-flex flex-row flex-nowrap align-items-stretch gap-2">
                <div 
                    className={i < 5? "fw-bold": ""} 
                    style={{
                        width: "1.9em",
                        borderLeft: color? `0.43em solid ${color}` : undefined,
                        display:"flex",
                        paddingLeft: "0.5em",
                        justifyContent: "left",
                        alignItems: "center"
                    }}
                >{i + 1}</div>
                <CliftonStrengthInput 
                    key={i} 
                    bold={i < 5}
                    value={value}
                    className="flex-grow-1"
                    checkCliftonStrength={false}
                    onChange={(value) => setNthStrength && setNthStrength(i, value)}
                    />
            </div>
        );
    }
    return (
        <div id={id} className='input clifton-strength mt-4'>
            <label htmlFor="cliftonStrength">
                {isNew? "Have you completed a CliftonStrengths 34 assessment?" : "Would you like to upload a new CliftonStrength assessment?"}
                <span className='required-tag'> *</span>
            </label>
            <div className="py-2 d-flex justify-content-between gap-3">
                <div className="clifton-check d-flex gap-2">
                    <button 
                        type="button"
                        onClick={() => setHasCliftonStrength(false)}
                        className={`btn btn-${hasCliftonStrength? "outline-" : ""}primary`}
                    >
                        No
                    </button>
                    <button
                        type="button"
                        onClick={() => setHasCliftonStrength(true)}
                        className={`btn btn-${hasCliftonStrength? "" : "outline-"}primary`}
                    >
                        Yes
                    </button>
                </div>
                {hasCliftonStrength && (
                    <div
                        className={`dropdown${showCliftonFileUpload? " show" : ""}`}
                    >
                        <div className="btn-group rounded-pill ">
                            <button 
                                type="button"
                                className="btn btn-link-color upload-dropdown-toggle"
                                onClick={() => { browseFile("mcb_clifton34pdf"); }} 
                            >
                                <UploadIcon className="me-2"/> Upload your CliftonStrength34 PDF
                            </button>
                            
                            <button 
                                type="button"
                                className="btn btn-link-color dropdown-toggle dropdown-toggle-split"
                                onFocus={() => { setShowCliftonFileUpload(true); }}
                                onBlur={() => { setShowCliftonFileUpload(false); }}
                            >
                                <span className="visually-hidden">Toggle Dropdown</span>
                            </button>
                        </div>
                        
                        <div className={`dropdown-menu${showCliftonFileUpload? " show" : ""}`}>
                            <button 
                                type="button"
                                className="dropdown-item"
                                onMouseDown={() => { browseFile("mcb_cliftonstrengthstop5pdf"); }} 
                                onTouchStart={() => { browseFile("mcb_cliftonstrengthstop5pdf"); }} 
                            >Upload CliftonStrengths Top 5 Report</button>
                            <button 
                                type="button"
                                className="dropdown-item"
                                onMouseDown={() => { browseFile("mcb_signaturethemepdf"); }} 
                                onTouchStart={() => { browseFile("mcb_signaturethemepdf"); }} 
                            >Upload Signature Theme Report</button>
                            <button 
                                type="button"
                                className="dropdown-item"
                                onMouseDown={() => { browseFile("mcb_themesequencepdf"); }} 
                                onTouchStart={() => { browseFile("mcb_signaturethemepdf"); }} 
                            >Upload Theme Sequence Report</button>
                        </div>
                  </div>
                )}
            </div>
            {hasCliftonStrength ? (
                <div>
                    <div className="d-flex flex-column gap-3">
                        <div className="d-flex flex-column gap-1 mt-3">
                            {topStrengthInputs}
                        </div>
                        <div className="fs-smaller">
                            CliftonStrengths® and each of the 34 CliftonStrengths theme names are trademarks of Gallup, Inc.<br/>
                            Copyright © 2000 Gallup, Inc. All rights reserved.
                        </div>
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column justify-content-center align-items-center gap-2 py-2">
                    <Link
                        to="https://mycareerbrand.net/products/cliftonstrengths34-assessment-and-coaching"
                        target="_blank" className="btn btn-outline-link-color"
                    >
                        Take your CliftonStrengths Assessment
                    </Link>
                    <div className="fw-bold">OR</div>
                    <button
                        type="button"
                        className="btn btn-link-color"
                        onClick={() => {
                            setHasCliftonStrength(true);
                            browseFile("mcb_clifton34pdf");
                        }}
                    >
                        <UploadIcon className="me-2"/> Upload your CliftonStrengths Report
                    </button>
                </div>
            )}
        </div>
    );
}