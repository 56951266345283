import React from 'react';
import { addClassNames } from '../Functions/Helper/Component';
import LoadingSpinner from '../Components/Generic/LoadingSpinner';
import './AccountSettings.scss';
import useConstant from '../Provider/ConstantContext';

function handleIframeResizing(iframe, setLoading) {
    const getHeight = () => iframe.contentWindow.postMessage('getIframeHeight', '*');
    const handleMessage = (event) => {
      if (event.source === iframe.contentWindow) {
        if (event.data?.type === "iframeHeight") {
            iframe.style.height = `${event.data.iframeHeight}px`;
        } else if (event.data?.type === "mounted") {
            setLoading(false);
            getHeight();
        }
      }
    };
    window.addEventListener('message', handleMessage);

    const resizeObserver = new ResizeObserver(getHeight);
    resizeObserver.observe(iframe);
    
    if (iframe.contentWindow) {
        iframe.contentWindow.postMessage('mount-check', '*');
        iframe.contentWindow.postMessage('iframeHeight', '*');
    }

    return () => {
      window.removeEventListener('message', handleMessage);
      resizeObserver.disconnect();
    };
}


export default function AccountSettings() {
    const [passwordLoading, setPasswordLoading] = React.useState(true);
    const [emailLoading, setEmailLoading] = React.useState(true);
    const { portalURL } = useConstant();

    const passwordIframe = React.useRef(null);
    const emailIframe = React.useRef(null);
    
    React.useEffect(() => {
        if (!passwordIframe.current || !emailIframe.current) throw new Error("Iframes not found");
        const unmountPassword = handleIframeResizing(passwordIframe.current, setPasswordLoading);
        const unmountEmail = handleIframeResizing(emailIframe.current, setEmailLoading);
        return () => {
            unmountPassword();
            unmountEmail();
        };
    }, []);

    return (
        <div className="container min-h-100">
            <h1>Account Settings</h1>
            <div className="card my-2 rounded">
                <div className="card-body bg-white">
                    <h2 className='h5 mb-3'>Change Password</h2>
                    <iframe
                        src={`${portalURL}en-US/Account/Manage/SetPassword`}
                        title="Set Password"
                        ref={passwordIframe}
                        className={addClassNames("account-iframe", passwordLoading && "d-none")}
                    />
                    {passwordLoading && <LoadingSpinner/>}
                </div>
            </div>
                
            <div className="card my-2 rounded">
                <div className="card-body bg-white">
                    <h2 className='h5 mb-3'>Change Email</h2>
                    <iframe
                        src={`${portalURL}en-US/Account/Manage/ChangeEmail`}
                        title="Change Email"
                        ref={emailIframe}
                        className={addClassNames("account-iframe", emailLoading && "d-none")}
                    />
                    {emailLoading && <LoadingSpinner/>}
                </div>
            </div>
            
        </div>
    )
}