export function bindOnChange(onChange, alias) {
    if (typeof onChange === "function" && alias) {
        return (value) => onChange(data => {
            const newValue = typeof value === "function"? value(data[alias]): value;
            return {...data, [alias]: newValue};
        });
    } else {
        return console.log;
    }
}
export function bindOnChangeArray(onChange, index) {
    return (value) => onChange(data => { 
        const newArray = [...data];
        newArray[index] = typeof value === "function"? value(data[index]): value;
        return newArray;
    });
}