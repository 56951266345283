import React from 'react';
import useABC from '../Provider/TableContext/ABCContext';
import useSkill from '../Provider/TableContext/SkillContext';
import LoadingSpinner from './Generic/LoadingSpinner';
import Card from './Generic/Card';
import RichTextDisplay from './RichTextDisplay';
import HTMLDocxLink from './HTMLDocxLink';
import useResponsibility from '../Provider/TableContext/ResponsibilityContext';
import { generateIdealRoleHTML } from '../Functions/Generator/IdealRoleGenerator';
import useQualification from '../Provider/TableContext/QualificationContext';
import { addClassNames } from '../Functions/Helper/Component';

const Description = (name, HTML) => () => <HTMLDocxLink HTML={HTML} rotate name={name}> Download MyIdealRole</HTMLDocxLink>;

export default function MyIdealRole({role, className}) {
    const { abcs, loading: abcLoading } = useABC();
    const { responsibilities, loading: respLoading } = useResponsibility();
    const { qualifications, loading: qualLoading } = useQualification();
    const { skills, loading: skillsLoading } = useSkill();
    
    const loading = React.useMemo(() =>
        abcLoading || respLoading || skillsLoading || qualLoading,
    [abcLoading, respLoading, skillsLoading, qualLoading]);
    
    const value = React.useMemo(() => {
        if (loading) {
            return "Loading...";
        }
        return generateIdealRoleHTML(role, responsibilities, qualifications, abcs, skills);
    }, [role, abcs, responsibilities, qualifications, skills, loading]);

    const children = loading? <LoadingSpinner/> : <RichTextDisplay value={value}/>;
    
    return (
        <Card
            title="MyIdealRole"
            className={addClassNames("w-100", className)}
            description="Downloads MyIdealRole as MS Word Document"
            DescriptionControl={Description(`MyIdealRole`, value)}
        >
            {children}
        </Card>
    );
}