import React from 'react';
import useRole from '../../Provider/TableContext/RoleContext';
import RoleStatus from '../../Constants/RoleStatus.json';
import useCreatedRoleContext from '../../Hooks/useCreatedRoleContext';
import { useParams } from 'react-router';

const ApplyingRoleContext = React.createContext();

export function ApplyingRoleProvider(props) {
    const { roles, createRole, updateRole, loading: roleLoading } = useRole();
    const {id} = useParams();
    const role = React.useMemo(() => {
        if (!roleLoading) {
            const role = {...(roles?.filter(role => (
                RoleStatus.Applying === (role || {}).mcb_rolestatus
                && role?.mcb_roleid === id
            ))[0] || {})}; 
            return role;
        }
    }, [roles, roleLoading, id]);
    const { formData, setFormData, setLoading, ...contextValue} = useCreatedRoleContext({ role, updateRole, roleStatus: RoleStatus.Applying, roleStatusName: "Applying" })
    const updateApplyingRoleDetails = React.useCallback(async ()  => {
        setLoading(true);
        const role = {...(formData || {}), mcb_rolestatus: RoleStatus.Applying};
        role.mcb_atriskrenumeration = Number(role?.mcb_atriskrenumeration) || 0;
        role.mcb_baserenumeration = Number(role?.mcb_baserenumeration) || 0;

        const result = role?.mcb_roleid? await updateRole(role): await createRole(role);
        setLoading(false);
        return result;
    }, [formData, updateRole, createRole, setLoading]);
    
    return (
        <ApplyingRoleContext.Provider value={{
            formData, setFormData, updateApplyingRoleDetails, ...contextValue
        }}>
            {props.children}
        </ApplyingRoleContext.Provider>
    );
}

export default function useApplyingRoleJourneyContext() {
    return React.useContext(ApplyingRoleContext);
}