function cloneOOXMLData(data, parentOOXML) {
    let result = {
        _attrs: data._attrs || {},
        _fTextNode: data._fTextNode,
        _children: [],
        _parent: parentOOXML,
    };
    if (data._tag) result._tag = data._tag;
    if (data._text) result._text = data._text;
    if (data._children) data._children.map((child) => result._children.push(cloneOOXMLData(child, result)));
    return result;
}

function convertToOOXML(data, parentOOXML) {
    let result = { _attrs: data.attrs || {}, _children: []};
    if (parentOOXML) result._parent = parentOOXML;

    if (data.tag === "TEXT_NODE") {
        result._fTextNode = true;
        result._text = data.children;
    } else {
        result._tag = data.tag;
        result._fTextNode = false;
        
        if (data.children) data.children.forEach((child) =>
            result._children.push(convertToOOXML(child, result))
        );

        if (data.ooxmlChildren) data.ooxmlChildren.forEach((child) =>
            result._children.push(cloneOOXMLData(child, result))
        );
    }

    return result;

}

/**
 * 
 * @param {ChildNode} node 
 * @param {string} pr 
 * @returns 
 */
function parseHTMLToOoxmlCursively(ctx, node, parentOOXML = null, pr = []) {
    if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.trim()) {
            return [convertToOOXML({
                tag: "w:r",
                children: [
                    {tag: "w:rPr", ooxmlChildren: pr},
                    {
                        tag: "w:t", attrs: {"xml:space": "preserve"},
                        children: [{tag: "TEXT_NODE", children: node.textContent}]
                    }
                ]
            }, parentOOXML)];
        }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.nodeName === 'BR') {
            return [convertToOOXML({ tag: "w:r", children: [{tag: "w:br"}]}, parentOOXML)];
        } else if (node.nodeName === 'P' || /^H\d/.test(node.nodeName)) {
            const style = node.nodeName === 'P' ? 'Normal' : node.nodeName.replace("H", "Heading ");
            const pRp = {tag: "w:pPr", children: [{ attrs: {"w:val": style, tag: "w:pStyle"} }],  ooxmlChildren: pr};
            const toNewParagraph = ooxmlChildren => convertToOOXML({ tag: "w:p", children: [pRp], ooxmlChildren}, parentOOXML);

            const result = [];
            let children = [];
            Array.from(node.childNodes).forEach((cn) => {
                const processedOOXML = parseHTMLToOoxmlCursively(ctx, cn, parentOOXML, pr);
                processedOOXML.forEach(node => {
                    if (node._tag === 'w:p') {
                        if (children.length) {
                            result.push(toNewParagraph(children));
                            children = [];
                        }
                        result.push(node);
                    } else {
                        children.push(node);
                    }
                });
            });

            if (children.length) {
                result.push(toNewParagraph(children));
            }
            return result;
        }  else if (node.nodeName === 'OL' || node.nodeName === 'UL') {
            ctx.maxNumId ++;
            ctx.numberingDetails.push({
                numId: ctx.maxNumId,
                listType: node.nodeName
            });
            const numId = ctx.maxNumId.toString();
            const listGroup = [];
            node.childNodes.forEach(li => {
                const matches = /ql-indent-(\d+)/gi.exec(li.className);
                const ilvl = matches ? matches[1] : "0";
                
                const ooxmlChildren = [];

                Array.from(li.childNodes).forEach(cn => 
                    parseHTMLToOoxmlCursively(ctx, cn, parentOOXML, pr)
                        .forEach(pr => ooxmlChildren.push(pr))
                );

                listGroup.push(convertToOOXML({ tag: "w:p", ooxmlChildren, children: [
                    {tag: "w:pPr", children: [
                        {tag: "w:numPr", children: [
                            {tag: "w:ilvl", attrs: {"w:val": ilvl}},
                            {tag: "w:numId", attrs: {"w:val": numId}}
                        ]}
                    ]},
                ]}, parentOOXML));
            });
            return listGroup;
        } else if (node.nodeName === 'A') {
            const ooxmlChildren = [];
            Array.from(node.childNodes).forEach(cn => {
                parseHTMLToOoxmlCursively(ctx, cn, parentOOXML, pr).forEach(pr => ooxmlChildren.push(pr));
            });
            const rtLinkId = ctx.links.length;
            ctx.links.push(node.href);
            const result = [convertToOOXML({ tag: "w:hyperlink", ooxmlChildren, attrs: {"r:id": `rtLinkId${rtLinkId}`}}, parentOOXML)];
            return result;
        } else {
            let newRPR = [...pr];
            if (node.nodeName === 'B' || node.nodeName === 'STRONG') {
                newRPR.push(convertToOOXML({ tag: "w:b" }));
            } else if (node.nodeName === 'I' || node.nodeName === 'EM') {
                newRPR.push(convertToOOXML({ tag: "w:i" }));
            } else if (node.nodeName === 'U') {
                newRPR.push(convertToOOXML({ atts: {"w:val": "single"}, tag: "w:u" }));
            }
            const result = []
            Array.from(node.childNodes).forEach(cn => {
                parseHTMLToOoxmlCursively(ctx, cn, parentOOXML, newRPR).forEach(pr => result.push(pr));
            });
            return result;
        }
    } 
    return [];
}

/**
 * 
 * @param {Node} node 
 */
export function removeParagraph(doc, node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
        const newChildren = [];
        let child;
        do {
            child = node.childNodes[0];
            if (child) {
                const removedNodes = removeParagraph(doc, child);
                if (Array.isArray(removedNodes)) newChildren.push(...removedNodes);
                else newChildren.push(removedNodes);
                node.removeChild(child);
            }
        } while(child);
        newChildren.forEach(child => node.appendChild(child));

        if (node.nodeName === 'P' && node.childNodes.length > 0) { 
            const children = []
            node.childNodes.forEach(child => {
                children.push(child);
            });
            children.push(doc.createElement('br')); 
            return children;
        }
    } 

    return node;
}

export function htmlToOoxml(ctx, html, parentNode, ppr) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const result = [];
    if (ctx.removeParagraph) removeParagraph(doc, doc.body);
    Array.from(doc.body.childNodes).map((cN) =>
        parseHTMLToOoxmlCursively(ctx, cN, parentNode, ppr)
            .forEach(d => result.push(d))
    );
    return result;
}


export function processRichNode(ctx, node) {
    
    if (node._fTextNode) {
        let mainMatch = /~~~(INLINE_)?RICHTEXT\s(.*)~~~/gi.exec(node._text);
        if (mainMatch) {
            if (mainMatch[1] === "INLINE_") ctx.removeParagraph = true;
            const result = htmlToOoxml(ctx, mainMatch[2], node._parent);
            if (mainMatch[1] === "INLINE_") ctx.removeParagraph = false;
            return result;
        } else {
            return node;
        }
    } else if (node._tag === "w:r" || node._tag === "w:t") {
        const finder = node._tag === "w:r"? a => a._tag === "w:t" : a => a._fTextNode;
        const tNode = node._children.find(finder);
        if (tNode) {
            const result = processRichNode(ctx, tNode);
            if (Array.isArray(result)) return result;
        }
        return node;
            
    } else {
        const children = [];
        for (let c = 0; c < node._children.length; c++) {
            const child = node._children[c];
            if (child._tag === "w:p") ctx.afterParagraph = [];
            const result = processRichNode(ctx, child);

            if (Array.isArray(result)) {
                if(Array.isArray(ctx.afterParagraph)) {
                    const [first, ...rest] = result;
                    if (!first) continue;
                    if (first && first._tag === "w:p") {
                        first._children.forEach(firstChild => {
                            if(firstChild._tag === 'w:r') {
                                children.push({...firstChild, _parent: node});
                            }
                        });
                    } else children.push({...first, _parent: node});

                    rest.forEach(restItem => {
                        if (restItem._tag === "w:p") {
                            ctx.afterParagraph.push(restItem);
                        } else {
                            children.push({...restItem, _parent: node});
                        }
                    });

                } else {
                    result.map(r => children.push({...r, _parent: node}));
                }
            } else if (result) {
                children.push({ ...result, _parent: node});
            }

            if (child._tag === "w:p") {
                ctx.afterParagraph.forEach(afterParagraph => 
                    children.push({...afterParagraph, _parent: node})
                );
                ctx.afterParagraph = false;
            }
        }
        return {...node, _children: children.map(a => ({...a, _parent: node}))};
    }
}

export function maxAbstractId(nodes, max = 0) {
    if (nodes?._children?.length) nodes._children.forEach(node => {
        if (node._tag === 'w:abstractNum') {
            max = Math.max(max, parseInt(node._attrs['w:abstractNumId']));
        }
        max = maxNumId(node, max);
    });
    return max;
}

export function maxNumId(nodes, max = 0) {
    if (nodes?._children?.length) nodes._children.forEach(node => {
        if (node._tag === 'w:numId') {
            max = Math.max(max, parseInt(node._attrs['w:val']));
        }
        max = maxNumId(node, max);
    });
    return max;
}


export function removeNumId(maxNumId, xml) {
    if (xml._tag === 'w:numbering') {
        xml.children = xml._children.filter(childNode => {
            return removeNumId(maxNumId, childNode);
        });
    } else {
        return xml._children.every(childNode => {
            if(childNode._tag === 'w:numId') {
                return parseInt(childNode._attrs['w:val']) <= maxNumId
            } else if (!childNode._fTextNode) {
                return removeNumId(maxNumId, childNode);
            } 
            return true;
            
        });
    }
}

export function abstractOrderedList(id, parentOOXML) {
    return convertToOOXML({
        tag: "w:abstractNum",
        attrs: {"w:abstractNumId": id},
        children: [
            {tag: "w:lvl", attrs: {"w:ilvl": "0"}, children: [
                {tag: "w:start", attrs: {"w:val": "1"}},
                {tag: "w:numFmt", attrs: {"w:val": "decimal"}},
                {tag: "w:lvlText", attrs: {"w:val": "%1."}},
                {tag: "w:lvlJc", attrs: {"w:val": "left"}}
            ]},
            {tag: "w:lvl", attrs: {"w:ilvl": "1"}, children: [
                {tag: "w:start", attrs: {"w:val": "1"}},
                {tag: "w:numFmt", attrs: {"w:val": "lowerLetter"}},
                {tag: "w:lvlText", attrs: {"w:val": "%2."}},
                {tag: "w:lvlJc", attrs: {"w:val": "left"}}
            ]},
            {tag: "w:lvl", attrs: {"w:ilvl": "2"}, children: [
                {tag: "w:start", attrs: {"w:val": "1"}},
                {tag: "w:numFmt", attrs: {"w:val": "lowerRoman"}},
                {tag: "w:lvlText", attrs: {"w:val": "%3."}},
                {tag: "w:lvlJc", attrs: {"w:val": "left"}}
            ]}
        ]
    }, parentOOXML);
}

export function abstractUnorderedList(id, parentOOXML) {
    return convertToOOXML({
        tag: "w:abstractNum",
        attrs: {"w:abstractNumId": id},
        children: [
            {tag: "w:lvl", attrs: {"w:ilvl": "0"}, children: [
                {tag: "w:numFmt", attrs: {"w:val": "bullet"}},
                {tag: "w:lvlText", attrs: {"w:val": "•"}},
                {tag: "w:lvlJc", attrs: {"w:val": "left"}}
            ]}
        ]
    }, parentOOXML);
}


export function numberingDetail(id, abstractId, parentOOXML) {
    return convertToOOXML({
        tag: "w:num",
        attrs: {"w:numId": id},
        children: [
            {tag: "w:abstractNumId", attrs: {"w:val": abstractId}}
        ]
    }, parentOOXML);
}


export function processRichTextXML(ctx, xml, documentComponent, reports) {
    if (documentComponent === "main.xml") {
        let body = xml._children[0];
        ctx.initialMaxNumId = maxNumId(body);
        ctx.maxNumId = ctx.initialMaxNumId;
        ctx.links = [];
        const result = processRichNode(ctx, xml);
        // ctx.links.forEach((url, idx) => {
        //     reports.links[`rtLinkId${idx}`] = {url};
        // });
        xml = result;
    } else if (documentComponent === "numbering.xml") {
        ctx.maxAbstractId = maxAbstractId(xml) + 1;
        ctx.unOrderedListId = ctx.maxAbstractId.toString();
        ctx.maxAbstractId++;
        ctx.orderedListId = ctx.maxAbstractId.toString();
        removeNumId(ctx.initialMaxNumId , xml);
        
        xml._children.unshift(
            abstractUnorderedList(ctx.unOrderedListId, xml),
            abstractOrderedList(ctx.orderedListId, xml)
        );
        
        ctx.numberingDetails.forEach(({ numId, listType }) => {
            const abstractId = listType === "OL"? ctx.orderedListId: ctx.unOrderedListId;
            const numDetail = numberingDetail(numId.toString(), abstractId, xml);
            xml._children.push(numDetail);
        });
    }
    return xml;
}
