import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { addClassNames } from '../Functions/Helper/Component';
import { AchievementDisplay } from './AchievementsInput';
import useRole from '../Provider/TableContext/RoleContext';
import useABC from '../Provider/TableContext/ABCContext';
import RoleStatus from '../Constants/RoleStatus.json';
import SearchForm from './SearchForm';
import LinkToJourney from './Generic/LinkToJourney';
import RoundedIcon from './Generic/RoundedIcon';

import { ReactComponent as PersonIcon } from 'bootstrap-icons/icons/person.svg';
import { ReactComponent as CrossIcon } from 'bootstrap-icons/icons/x.svg';
import { ReactComponent as ProfessionalIcon } from 'bootstrap-icons/icons/briefcase.svg';
import { ReactComponent as AchievementsIcon } from 'bootstrap-icons/icons/rocket-takeoff.svg';
import { ReactComponent as JobApplicationIcon } from 'bootstrap-icons/icons/clipboard-check.svg';
import { ReactComponent as IdealJobIcon } from 'bootstrap-icons/icons/clipboard-heart.svg';

import "./Navigation.scss";
import { SideNav } from './SideNav';
import useSkill from '../Provider/TableContext/SkillContext';
import useResponsibility from '../Provider/TableContext/ResponsibilityContext';
import useProfile from '../Provider/TableContext/ProfileContext';
import { LoadingSpinner } from './Generic/LoadingSpinner';

export default function Navigation() {
    const [search, setSearch] = React.useState("");
    const [showSearch, setShowSearch] = React.useState(false);
    const navigate = useNavigate();

    const {roles, loading: rolesLoading} = useRole();
    const {abcs, loading: abcLoading} = useABC(); 
    const { loading: skillsLoading } = useSkill();
    const { loading: responsibilityLoading} = useResponsibility();
    const { loading: profileLoading } = useProfile();
    const loading = rolesLoading || abcLoading || skillsLoading || responsibilityLoading || profileLoading;

    const keywords = React.useMemo(() => search.length? search.toLowerCase().split(" ").filter(a => a) : [], [search]);
    const filteredRoles = React.useMemo(() => {
        if (roles.length && keywords.length) {
            return roles.filter(role => keywords.every(keyword => (
                role.mcb_roletitle?.toLowerCase().includes(keyword)
                || role.mcb_rolecompanyname?.toLowerCase().includes(keyword)
                || role.mcb_rolehtml?.toLowerCase().includes(keyword)
                || role.mcb_challenge?.toLowerCase().includes(keyword)
                || role.mcb_description?.toLowerCase().includes(keyword)
                || role.mcb_generalcomments?.toLowerCase().includes(keyword)
            ))).slice(0, 5);
        }
        return [];
    
    }, [keywords, roles]);

    const filteredABCs = React.useMemo(() => {
        if (abcs.length && keywords.length && filteredRoles.length < 5) {
            return abcs.filter(abc => keywords.every(keyword => (
                abc.mcb_achievement?.toLowerCase().includes(keyword)
                || abc.mcb_benefit?.toLowerCase().includes(keyword)
                || abc.mcb_achievementstatement?.toLowerCase().includes(keyword)
            ))).slice(0, 5 - filteredRoles.length);
        }
        return [];
    }, [keywords, abcs, filteredRoles]);

    const achievementOnClick = React.useCallback(() =>  navigate(`/achievements?q=${encodeURIComponent(search)}`), [search, navigate]);

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light px-4 justify-content-between">
            <div className="navbar-brand flex-grow-1">
                <Link to="/">
                    <LoadingSpinner loading={loading} style={{ height: "2.7em", width: "auto"}}/>
                </Link>
            </div>

            <button
                className="navbar-toggler border-0 border-focus-0 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Open Navigation"
            >
                <CrossIcon className="navbar-close-icon"/>
                <span className="navbar-toggler-icon"></span>
            </button>

            <div id="navbarSupportedContent"
                className="collapse navbar-collapse flex-grow-1"
            >
                <div className="w-100 d-flex flex-row flex-wrap justify-content-between gap-4">
                    <div className="d-flex flex-column flex-grow-1">
                    <div className="dropdown w-100">
                        <SearchForm
                            search={search}
                            setSearch={setSearch}
                            onFocus={() => { setShowSearch(true); }}
                            onBlur={() => { setShowSearch(false); }}
                            onSubmit={(e) => e.preventDefault()}
                        />
                        {(
                            !!filteredRoles.length
                            || !!filteredABCs.length
                        ) && showSearch  && (
                            <ul className={addClassNames("dropdown-menu w-100 mt-2 show")} aria-labelledby="Search Options">
                                {filteredRoles.map(role => {
                                    let onClick;
                                    let Icon;
                                    switch (role.mcb_rolestatus) {
                                        case (RoleStatus.Applying): 
                                            onClick = () => navigate(`/job-applications/${role.mcb_roleid}`);
                                            Icon = JobApplicationIcon;
                                            break;
                                        case RoleStatus.Ideal:
                                            onClick = () => navigate('/#ideal-job');
                                            Icon = IdealJobIcon;
                                            break;
                                        case RoleStatus.Current:
                                        case RoleStatus.Previous:
                                            onClick = () => navigate(`/profile/professional#${role.mcb_roleid}`);
                                            Icon = ProfessionalIcon;
                                            break;
                                        default:
                                            return null;
                                    }
                                    if (onClick) return (
                                        <li key={role.mcb_roleid}>
                                            <Link
                                                className="dropdown-item unstyled py-2"
                                                onMouseDown={onClick}
                                                onTouchStart={onClick}
                                            >
                                                <Icon className="icon fs-5"/><span className="search-description">{role.mcb_roletitle}</span>
                                            </Link>
                                        </li>
                                    );
                                    return null;
                                })}

                                {filteredABCs.map(abc => {
                                    return (
                                        <li key={abc.mcb_abcid}>
                                            <Link
                                                className="dropdown-item unstyled py-2"
                                                onMouseDown={achievementOnClick}
                                                onTouchStart={achievementOnClick}
                                            >
                                                <AchievementsIcon className="icon fs-5"/>
                                                <AchievementDisplay value={abc}/>
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                    </div>
                    <div className="d-flex flex-grow-1 flex-nowrap">
                        <ul className="navbar-nav ml-auto flex-grow-1 justify-content-end pe-3 d-none d-lg-flex">
                            <li className="nav-item">
                                <Link className="nav-link text-secondary" to="/achievements">Achievements</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-secondary" to="/job-applications">Job Applications</Link>
                            </li>
                        </ul>
                        <div className="d-flex d-lg-none gap-3 navbar-nav ml-auto flex-column flex-grow-1 justify-content-end pe-3 mb-2">

                            <SideNav className="d-flex flex-column"/>
                        </div>
                        <div className="btn-group profile-dropdown">
                            
                            <button 
                                type="button"
                                className="icon-button fs-3"
                                data-bs-toggle="dropdown" aria-expanded="false"
                            >
                                <RoundedIcon
                                    Icon={PersonIcon}
                                    backgroundColor="var(--mo-secondary)"
                                    color="var(--mo-light)"
                                />
                            </button>

                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <li>
                                    <LinkToJourney className="dropdown-item" to="/profile">Profile Settings</LinkToJourney>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/account-settings">Account Settings</Link>
                                </li>
                                <li><hr className="dropdown-divider"/></li>
                                <li><Link className="dropdown-item" to="/sign-out">Sign Out</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};
