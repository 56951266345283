import { divHTML, newlineHTML, spanHTML } from "./Generator.Helper";

/**
 * 
 * @param {{
*      responsibilities: {
*          responsibilityId: string,
*          responsibility: string,
*          achievements: { 
*              achievementId: string,
*              achievement: string,
*              responsibilities: string[],
*              skills: string[],
*              strengths: string[]
*          }[]
*      }[],
*      keySkills: {id: string, name: string}[],
*      keyStrengths: {id: string, name: string}[],
*      achievements: {
*          achievement: string, 
*          responsibilities: string[],
*          skills: string[],
*          strengths: string[]
*      }[],
*  }} data 
* @returns 
*/
export function generateRACTableRowsHTML(data) {
    let totalRowCount = 0;
    data.responsibilities.forEach(function(dat) {
        totalRowCount += dat.achievements.length;
    });
    
    const strengths = [
        divHTML(
            data.keySkills.map(function(skill) {
                return spanHTML(skill.name);
            }).join(spanHTML(", "))
        ) + newlineHTML + divHTML(
            data.keyStrengths.map(function(strength) {
                return spanHTML(strength.name);
            }).join(spanHTML(", "))
        ), { options: "rowspan=\"" + totalRowCount + "\"" }
        
    ]
    let tableRows = [];
    
    data.responsibilities.forEach(function(responsibility, respIdx) {
        const achievementCount = responsibility.achievements.length;

        responsibility.achievements.forEach(function(achievement, achIdx) {
            const result = [];
            if (!achIdx) {
                result.push([
                    responsibility.responsibility,
                    { options: "rowspan=\"" + achievementCount + "\""}
                ]);
            }
            result.push(achievement.achievement);
            if (!respIdx && !achIdx) {
                result.push(strengths);
            }
            tableRows.push([result]);
        });
    });

    return tableRows;
}