
import ApplicationStatus from '../../Constants/ApplicationStatus.json';
import ApplicationStatusColors from '../../Constants/ApplicationStatusColors.json';

export const ApplicationStatuses = Object.entries(ApplicationStatus)
    .map(([label, value]) => ({ label, value, color: ApplicationStatusColors[label] }));
const DefaultApplicationStatus = ApplicationStatuses.find(({ value }) => value === ApplicationStatus["Gathering Information"]);

export function getRoleApplicationStatus(mcb_applicationstatus) {
    return ApplicationStatuses.find(({ value }) => value === mcb_applicationstatus) || DefaultApplicationStatus;
}
