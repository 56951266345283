import React from 'react';
import './ChipDropdown.scss';

/**
 * A dropdown component with chip-like styling for selecting options.
 *
 * @component
 * @example
 * // Example usage of ChipDropdown
 * <ChipDropdown
 *   onChange={(value) => console.log(value)}
 *   placeholder="Select an option"
 *   value="option1"
 *   options={[
 *     "option1",
 *     "option2",
 *     { label: "Option 3", value: "option3" },
 *     { divider: true },
 *     { label: "Option 4", value: "option4" },
 *   ]}
 *   defaultValue="option1"
 * />
 *
 * @param {Object} props - The component props.
 * @param {function} props.onChange - The callback function triggered when an option is selected. Receives the selected value as a parameter.
 * @param {string} props.placeholder - The placeholder text displayed when no option is selected.
 * @param {ant} props.value - The currently selected value.
 * @param {Array|string|function} props.options - The list of options to be displayed in the dropdown. Can be an array of strings, an array of objects with `label` and `value` properties, or a function that returns an array of options.
 * @param {string} props.defaultValue - The default value to be selected when the component is rendered.
 *
 * @returns {JSX.Element} The rendered ChipDropdown component.
 */
export default function ChipDropdown({ onChange, placeholder, value, options:_options, defaultValue}) {
    const [stateValue, setStateValue] = React.useState(value || defaultValue);
    React.useEffect(() => {
        if (value && stateValue !== value) setStateValue(value);
    }, [value, stateValue]);
    let options = typeof _options === "function"? _options() : _options;
    let currentLabel =  placeholder || "Select an option";
    let currentOption = options.find(option => option === stateValue || option.value === stateValue);
    
    if (currentOption) {
        if (typeof currentOption === "string") {
            currentLabel = currentOption;
        } else if (currentOption?.label) {
            currentLabel = currentOption.label;
        }
    }

    return (
        <div className="btn-group chip-dropdown">
            <button
                type="button"
                className="btn bg-primary text-light dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                {currentLabel}
            </button>
            <ul className="dropdown-menu">
                {options.map((option, idx) => {
                    let label, value;
                    if (typeof option === "string") {
                        label = value = option;
                    } else {
                        if (option?.divider) {
                            return <li key={idx} className="dropdown-divider"></li>;

                        } else if (!option.label || !option.value) {
                            return null;
                        } else {
                            label = option.label;
                            value = option.value;
                        }
                    } 

                    let className = "dropdown-item";
                    if (stateValue === value) {
                        className += " active";
                    }
                    return (
                        <li key={idx}>
                            <span
                                className={className} 
                                onClick={() => {
                                    setStateValue(value);
                                    if (onChange) {
                                        onChange(value);
                                    }
                                }}
                            >{label}</span>
                        </li>
                    );
                })}
            </ul>
        </div>
    )
}