import { ApplyingRoleProvider } from "./ApplyingRoleContext";
import RoleDetails from "./ApplyingRoleDetails";
import Responsibility from "./ApplyingRoleRequirements";
import CV from './ApplyingRoleCV';
import Journey from "../../Layouts/Journey";

export default Journey({
    journeyTitle: "Job Application",
    Provider: ApplyingRoleProvider,
    finalRoute: (id) => `/job-applications/${id}`
}, RoleDetails, Responsibility, CV );