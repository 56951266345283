import React from 'react';
import useDragAndDrop from '../Provider/DragAndDropContext';
function getScrollableParent(element) {
    let currentNode = element;
    while (currentNode !== null) {
      if (currentNode.scrollHeight > currentNode.clientHeight) {
        return currentNode;
      }
      currentNode = currentNode.parentNode;
    }
    return null;
}
export default function useDrag(type, data, {touchHandle, ...options} = {}) {
    const dragRef = React.useRef(null);
    const [isDragging, setDragging] = React.useState(false);

    const {setCurrentlyDragging, setTouchDragDisplay, resolve} = useDragAndDrop();
    
    const getDragData = React.useCallback(() => ({
        type: type || "default",
        element: dragRef.current,
        data,
        ...options
    }), [type, data, options]);
    
    const onDragStart = React.useCallback((e) => {
        e.stopPropagation();
        const data = getDragData();
        setCurrentlyDragging(data);
        setDragging(true);
        
    }, [setCurrentlyDragging, getDragData]);

    const onDragStop = React.useCallback((e) => {
        e.stopPropagation();
        resolve();
        setDragging(false);
    }, [resolve]);

    const cancelDrag = React.useCallback((e) => {
        e.stopPropagation();
        setCurrentlyDragging(null);
        setDragging(false);
        setTouchDragDisplay({start: false, x: 0, y: 0});
    }, [setCurrentlyDragging, setTouchDragDisplay]);
    
    const lastElementRef = React.useRef(null);
    const touchDragStarted = React.useRef(false);
    
    React.useEffect(() => {
        const element = dragRef.current;
        if (element) {
            const assignedEvents = [
                ['dragstart', onDragStart],
                ['dragend', onDragStop],
                ['mouseup', cancelDrag],
                ['touchcancel', cancelDrag],
                ['touchend', (e) => {
                    if(lastElementRef.current) {
                        const event = new TouchEvent(e.type, {
                            view: window,
                            bubbles: true,
                            cancelable: true,
                            currentTarget: null
                        });
                        lastElementRef.current.dispatchEvent(event);
                        lastElementRef.current = null;
                    }
                    touchDragStarted.current = false;
                    onDragStop(e);
                    setTouchDragDisplay({start: false, x: 0, y: 0});
                }],
                ['touchmove', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!touchDragStarted.current) {
                        touchDragStarted.current = true;
                        onDragStart(e);
                    }
                    if (!e.touches[0]) return;
                    let x = e.touches[0].clientX;
                    let y = e.touches[0].clientY;
                    setTouchDragDisplay({start: true, x, y});
                    const overTarget = document.elementFromPoint(x,y);
                    const scrollableParent = getScrollableParent(overTarget);
                    if (scrollableParent) {
                        // Calculate the position of the touch point relative to the scrollable parent
                        const parentRect = scrollableParent.getBoundingClientRect();
                        const relativeX = x - parentRect.left;
                        const relativeY = y - parentRect.top;

                        // Scroll the scrollable parent if the touch point is at the edge
                        const edgeDistance = 120;  // Distance from edge in pixels
                        if (relativeX < edgeDistance) {
                        // Scroll left
                        scrollableParent.scrollBy(-10, 0);
                        } else if (parentRect.width - relativeX < edgeDistance) {
                        // Scroll right
                        scrollableParent.scrollBy(10, 0);
                        }
                        if (relativeY < edgeDistance) {
                        // Scroll up
                        scrollableParent.scrollBy(0, -10);
                        } else if (parentRect.height - relativeY < edgeDistance) {
                        // Scroll down
                        scrollableParent.scrollBy(0, 10);
                        }
                    }
                    const event = new CustomEvent("touchover", {
                        view: window,
                        bubbles: true,
                        cancelable: true,
                        detail: {overTarget}
                    });
                    
                    if (lastElementRef.current) lastElementRef.current.dispatchEvent(event);
                    if (overTarget) overTarget.dispatchEvent(event);
                    lastElementRef.current = overTarget;
                }, touchHandle?.current]
                
            ];
            element.draggable = true;
            assignedEvents.forEach(([event, handler, _element]) => {
                (_element || element).addEventListener(event, handler);
            });
            return () => {
                assignedEvents.forEach(([event, handler, _element]) => {
                    (_element || element).removeEventListener(event, handler);
                });
            };
        }
    }, [onDragStart, onDragStop, cancelDrag, setTouchDragDisplay, touchHandle]);

    return [dragRef, {isDragging}];
}
