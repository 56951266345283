import React from 'react';
import { ReactComponent as RemoveIcon } from 'bootstrap-icons/icons/dash-circle.svg';
import { ReactComponent as AdditionalOptionsIcon } from 'bootstrap-icons/icons/three-dots-vertical.svg';
import { addClassNames } from '../../Functions/Helper/Component';

export default function RemoveAdditionalOption({ onRemove }) {
    const [dropdown, setDropdown] = React.useState(false);
    return (
        <div className="dropdown-container right position-relative">
            <button
                className="btn btn-outline-secondary"
                onClick={() => setDropdown(!dropdown)}
                onBlur={(e) => {
                    setDropdown(false);
                    const relatedTarget = e.relatedTarget;
                    if (
                        relatedTarget 
                        && e.target.parentNode.contains(relatedTarget)
                        && relatedTarget.tagName === "BUTTON"
                        && typeof relatedTarget.click === "function"
                    ) {
                        relatedTarget.click();
                    }
                }}
                type="button"
            ><AdditionalOptionsIcon/></button>
            <ul className={addClassNames("dropdown-menu position-absolute ",(dropdown?"show" : ""))}>
                
                <li key="remove-button">
                    <button
                        type="button"
                        className="dropdown-item text-danger"
                        onClick={() => {
                            if (typeof onRemove === "function") {
                                onRemove();
                            }
                        }}
                    ><RemoveIcon className="me-2"/> Remove Achievement</button>
                </li>
            </ul>
        </div>
    );
}