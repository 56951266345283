import React from 'react';
import RoleStatus from '../Constants/RoleStatus.json';
import useABC from '../Provider/TableContext/ABCContext';
import useRole from '../Provider/TableContext/RoleContext';

export default function useRoleAchievement() {

    const { abcs, loading: abcLoading } = useABC();
    const { roles, loading: roleLoading } = useRole();
    
    const achievements = React.useMemo(() => {
        const result = [];
        (roles || []).sort((a, b) => new Date(b.mcb_starteddate) - new Date(a.mcb_starteddate)).forEach(role => {
            if(role?.mcb_roleid && [ RoleStatus.Current, RoleStatus.Previous ].includes( role?.mcb_rolestatus)) {
                (abcs || []).forEach(abc => {
                    if (abc?._mcb_role_value === role?.mcb_roleid) {
                        result.push(abc);
                    }
                });
            }
        });
        return result;
    }, [abcs, roles]);

    return { achievements, loading: abcLoading || roleLoading };
}