import { useCallback } from "react";
import useActionDialog from "../ ActionDialogContext";

export default function useEmailCVs(apiCall) {
    const { prompt, alert } = useActionDialog();
    const emailCVs = useCallback(async (...cvIds) => {
        if (!cvIds.length) return;
        const emailAddress = await prompt("Enter email address to send CVs to:");
        if (emailAddress) {
            if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress)) {
                try {
                    await apiCall({
                        method: "POST",
                        url: "/_api/cloudflow/v1.0/trigger/4157476b-534e-ef11-a316-000d3ae0e86d",
                        body: JSON.stringify({
                            "eventData": JSON.stringify({  
                                "cvIds": cvIds.join(";"),
                                emailAddress
                            
                            })
                        })
                    });
                    await alert("CVs sent successfully.");
                } catch (err) {
                    await alert("Failed to send CVs. Please try again later.");
                }
            } else {
                await alert("Invalid email address");
                emailCVs(...cvIds);
            }
        } 
        
    }, [apiCall, prompt, alert]);

    return emailCVs;
}