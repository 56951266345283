import React from 'react';

export function useFunctionRef(defaultValue) {
    const ref = React.useRef();
    const setRef = React.useCallback(func => {
        if (func) {
            ref.current = func;
        }
    }, []);
    const callRef = React.useCallback(async (...args) => {
        if (ref.current) {
            return await ref.current(...args);
        }
        if (defaultValue !== undefined) {
            return defaultValue;
        }
    }, [defaultValue]);
    return [setRef, callRef, ref];
}