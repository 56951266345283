import React from 'react';
import { createPortal } from 'react-dom';
import { addClassNames } from '../Functions/Helper/Component';
import BootstrapModal from "bootstrap/js/dist/modal";

const context = React.createContext({});
const uuid = "mcb-pitch-modal";
export function ModalPortalProvider({children}) {
    const ModalPortal = React.useCallback(({ 
        children,
        modalType = "modal-lg",
        modalDialogClassName,
        modalContentClassName,
        ...props
    }) => {
        const modal = document.getElementById(uuid);
        const modalDialog = document.getElementById(uuid + "-dialog");
        const modalContent = document.getElementById(uuid + "-content");
        modal.className = `modal ${modalType} fade${modal.classList.contains("show")? " show": ""}`;
        modalDialog.className = addClassNames("modal-dialog", modalDialogClassName);
        modalContent.className = addClassNames("modal-content", modalContentClassName);
        
        Object.entries(props).forEach(([key, value]) => {
            modal.setAttribute(key, value);
        });
        return createPortal(children, modalContent);
    }, []);

    const modal = React.useMemo(() => new BootstrapModal(document.getElementById(uuid)), []);
    
    return (
        <context.Provider value={{uuid, modal, ModalPortal}}>
            {children}
        </context.Provider>
    
    );
}

export default function useModalPortal() {
    return React.useContext(context);
}