import CreateTableContext from './TableContext';
const basicProfileFields = [
    "contactid",
    "firstname",
    "lastname",
    "emailaddress1",
    "telephone1",
    "address1_city",
    "jobtitle",
    "mcb_linkedin",
    "mcb_mainqualification",
    "mcb_sectors",
    "adx_publicprofilecopy",
    "mcb_has_cliftonstrength",
];
const mcbCliftonStrengthFields = [];
for(let c = 1; c <= 10; c++) mcbCliftonStrengthFields.push(`mcb_cliftonstrength_${c}`);

const readOnly = ["mcb_clifton34pdf", "mcb_cliftonstrengthstop5pdf", "mcb_signaturethemepdf", "mcb_themesequencepdf", "modifiedon"];


function convertTableData(tableData) {
    
    if (Array.isArray(tableData) && tableData[0]) {
        const profileData = {};
        const associate = fieldKey => {
            profileData[fieldKey] = tableData[0][fieldKey];
        };
        basicProfileFields.forEach(associate);
        readOnly.forEach(associate);
        
        profileData.topCliftonStrengths = mcbCliftonStrengthFields.map(key => tableData[0][key]);
        profileData.sectors = (tableData[0].mcb_sectors?.split(",") || []).map(a => Number(a));
        return profileData;
    }
}

function convertFormData(formData) {
    if (typeof formData === "object" && formData) {
        const tableData = {};
        basicProfileFields.forEach(fieldKey => {
            tableData[fieldKey] = formData[fieldKey];
        });
        const topCliftonStrengths = formData.topCliftonStrengths || [];
        for (let c = 0; c < 10; c++) {
            tableData[`mcb_cliftonstrength_${c + 1}`] = topCliftonStrengths[c];
        }
        tableData.mcb_sectors = (formData.sectors || []).join(",");
        return tableData;
    }
}

function processContextData ({
    datas, addData, updateData, deleteData,
    uploadFileToData, downloadFileFromData,
    ...context
}) {
    const profileData = convertTableData(datas);
    if (!window.context) window.context = {};
    if (!window.context.user) window.context.user = {...profileData};
    else Object.assign(window.context.user, profileData);
    window.context.user.name = `${window.context.user.firstname} ${window.context.user.lastname}`;

    return {
        profileData,
        updateProfile: (formData, ...args) => updateData(convertFormData(formData), ...args), 
        uploadProfileFile: (field, file, ...args) => datas?.length && uploadFileToData(datas[0]?.contactid, field, file, ...args),
        downloadProfileFile: (field, ...args) =>   datas?.length && downloadFileFromData(datas[0]?.contactid, field, ...args),
        ...context
    };
}

const [useProfile, ProfileProvider] = CreateTableContext(
    "contact", { processContextData, fields: [...basicProfileFields, ...mcbCliftonStrengthFields], readOnly }
);

export default useProfile;
export { useProfile, ProfileProvider };
