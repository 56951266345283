import CreateTableContext from './TableContext';

const fields = [
    "mcb_cvid",
    "mcb_cvname",
    "mcb_title",
    "mcb_firstname",
    "mcb_lastname",
    "mcb_email",
    "mcb_phone",
    "mcb_currentcity",
    "mcb_sector",
    "mcb_profilesummary",
    "mcb_coverletter",
    "mcb_linkedin",
    "mcb_mainqualification",
    "mcb_cliftonstrength",
    "mcb_sectors",
    "mcb_cvdocument",
    "statecode"
];

const relations = {
    "mcb_TemplateDocument": "mcb_templatedocument",
    "mcb_Person": "contact"
}

const expand = {
    "mcb_CVABC": "mcb_abc",
    "mcb_CVKeySkill": "mcb_skill",
    "mcb_CVRoles": "mcb_role",
    "mcb_CVQualifications": "mcb_qualification"
};

const readOnly = [
    "createdon",
    "modifiedon"
];

const [useCV, CVProvider] = CreateTableContext(
    "mcb_cv", {
        contextName: "CV",
        preSubmission: (entity, profileData) => ({
            ...entity,
            _mcb_person_value: profileData?.id
        }), fields, readOnly, expand, relations
    }
);

export default useCV;
export { useCV, CVProvider };
