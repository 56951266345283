import React from 'react';
import MessageHandlers from '../Functions/ActionMessageHandler';

/**
 * Handles the message event received from the parent window.
 * @param {MessageEvent} event - The message event object.
 * @returns {void}
 */
async function handlePortalActionMessageEvent(event) {
    // Parse the incoming JSON message
    const json = event.data;
    const action = json?.action;
    const requestId = json?.requestId;

    if (requestId == null || action == null) return;
    
    // Check if the action is a web API request and has a valid requestId
    if (typeof MessageHandlers[action] === 'function') {
        const messageHandler = MessageHandlers[action];
        try {
            const { transferList, ..._data} = await messageHandler(json, event.source)
            const data = { requestId, action: `${action}_RESPONSE`, ..._data};
            if (transferList) event.source.postMessage(data, '*', transferList);
            else event.source.postMessage(data, '*');
        } catch (err) {
            console.error(err);
            event.source.postMessage({
                requestId,
                action: `${action}_RESPONSE`,
                success: false,
                error: err.message
            }, '*');
        
        }

    }

}


export default function usePortalMessageHandler() {
    // Listen for messages from the parent window
    React.useEffect(() => {
        window.addEventListener('message', handlePortalActionMessageEvent);
        return () => {
            window.removeEventListener('message', handlePortalActionMessageEvent);
        };
    })

    // send validation error if it occurs
    React.useEffect(() => {
        if (!window?.parent?.postMessage) return;
        const loginValidationSummaryDOM = document.getElementById("loginValidationSummary");
        const redeemCodeInvitationSummaryDOM = document.getElementById("redeemInvitation-validation-summary");
        const validationSummary = document.getElementById("ValidationSummary1");
        const GenericError = document.querySelector('.alert.alert-block.alert-danger[for=GenericError]');
        let validationMessage = null;
        
        if (loginValidationSummaryDOM) {
            validationMessage = loginValidationSummaryDOM?.innerText?.trim();
        } else if (redeemCodeInvitationSummaryDOM) {
            validationMessage = redeemCodeInvitationSummaryDOM?.innerText?.trim();
        } else if (validationSummary) {
            validationMessage = validationSummary?.innerText?.trim();
        } else if (GenericError) {
            validationMessage = GenericError?.innerText;
        }

        if (validationMessage && window?.parent?.postMessage) {
            window.parent.postMessage({
                action: "ERROR_MESSAGE",
                message: validationMessage
            }, '*');
        }

        if (/^\/account\/login\/forgotpassword/i.test(window.location.pathname)) {
            const mainContentDOM = document.getElementById("mainContent");
            const passwordResetSuccessMessage = "Please check your email to reset your password.";
            if(mainContentDOM.innerText.includes(passwordResetSuccessMessage)) {
                window.parent.postMessage({
                    action: "FORGOT_PASSWORD_SUCCESS",
                    success: true,
                    message: passwordResetSuccessMessage
                }, '*');

                window.location.href = "/Account/Login/ForgotPassword";
            }
        }
        
        if (window?.parent?.postMessage) {
            if (window?.user) {
                window.parent.postMessage({
                    action: "USER_INFO",
                    user: window.user
                }, '*');
            }
            
            window.parent.postMessage({
                action: "API_READY"
            }, '*');
        }
        // Loaded Path and Iframe Unloading Message is sent from the footer content snippet.
        // It is done to ensure that the event happens every time the page is loaded.
    });

}