import { ConstantProvider } from "./ConstantContext";
import { PortalApiProvider } from "./PortalApiContext";
import { BrowserRouter } from "react-router-dom";
// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
// import { TouchBackend } from 'react-dnd-touch-backend';
import TableContextProvider from "./TableContext";
import { ModalPortalProvider } from "../Hooks/useModalPortal";
import { DragAndDropProvider } from "./DragAndDropContext";
import {ReactComponent as DragIcon} from 'bootstrap-icons/icons/arrows-move.svg';
import CliftonStrengthToolTipsProvider from "./CliftonStrengthToolTips";
import { PowerFlowProvider } from "./PowerFlow";
import { ActionDialogProvider  } from "./ ActionDialogContext";

export default function ProvideAll({children}) {
    return (        
        <ModalPortalProvider>
        {/* <DndProvider backend={HTML5Backend} options={{  }} > */}
        <DragAndDropProvider touchDragIcon={<DragIcon style={{ color: "lightgray", width: "68px", height: "69px"}}/>}>
        <ConstantProvider>
        <BrowserRouter>
        <ActionDialogProvider>
        <PortalApiProvider>
        <TableContextProvider>
        <PowerFlowProvider>
        <CliftonStrengthToolTipsProvider>
            {children}
        </CliftonStrengthToolTipsProvider>
        </PowerFlowProvider>
        </TableContextProvider>
        </PortalApiProvider>
        </ActionDialogProvider>
        </BrowserRouter>
        </ConstantProvider>
        </DragAndDropProvider>
        {/* </DndProvider> */}
        </ModalPortalProvider>
        
    );
}