import React from 'react';
import SearchForm from '../SearchForm';
import Pagination from '../Pagination';
import { localeDate } from '../../Functions/Helper/Month';
import LinkToJourney from '../Generic/LinkToJourney';
import LoadingSpinner from '../Generic/LoadingSpinner';
import AdditionalOptions from '../AdditionalOptions';
import TextDropDownOption from '../TextDropDownOptions';
import useCVTableData from './CVTableHooks';
import { ReactComponent as AddIcon } from 'bootstrap-icons/icons/plus-circle.svg';
import { ReactComponent as UpArrow } from 'bootstrap-icons/icons/arrow-up.svg';
import { ReactComponent as DownArrow } from 'bootstrap-icons/icons/arrow-down.svg';
import { ReactComponent as EmailIcon } from 'bootstrap-icons/icons/envelope.svg';
import { ReactComponent as Archive } from 'bootstrap-icons/icons/archive.svg';
import { ReactComponent as DownloadIcon } from 'bootstrap-icons/icons/download.svg';
import { ReactComponent as Duplicate } from 'bootstrap-icons/icons/copy.svg';
import { addClassNames } from '../../Functions/Helper/Component';
import DateOptions from './DateOptions';
import "./CVTable.scss";
import SelectOptions from './SelectOptions';
import { implementFilterByValue, valueToLabel } from './FilterHelper';
import { Link, useNavigate } from 'react-router-dom';
import useConstant from '../../Provider/ConstantContext';

export default function CVTable({ limit = 10 }) {
    const searchContainer = React.useRef();
    const navigate = useNavigate();
    const [filterView, setFilterView] = React.useState(false);
    const { portalURL } = useConstant();
    const {
        visibleCVs, loading, search, setSearch,
        sortColumn, sortDirection, sortColumnClickFactory,
        checked, setChecked, pageCount, pageNumber, searchParams,
        filter, setFilter, company_names, cv_titles, application_statuses,
        archiveCV, unArchiveCV, archivedUrl, notArchivedUrl, archived,
        emailCVs, duplicateCV
    } = useCVTableData({ limit });
    React.useEffect(() => {
        const handleClick = (e) => {
            setFilterView(
                searchContainer.current
                && (
                    searchContainer.current === e.target
                    || searchContainer.current.contains(e.target)
                )
            );
        }
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
    }, []);
    const filterOptions = React.useMemo(() => [
        { label: "Date", value: "created_date", options: DateOptions({ filter, setFilter }), className: "left" },
        {
            label: "Company", value: "company_name", options: SelectOptions({
                options: company_names || [],
                value: filter.company_name,
                onChange: (company_name) => setFilter({ ...filter, company_name })
            })
        },
        {
            label: "Role", value: "mcb_title", options: SelectOptions({
                options: cv_titles || [],
                value: filter.mcb_title,
                onChange: (mcb_title) => setFilter({ ...filter, mcb_title })
            })
        },
        {
            label: "Status", value: "application_status", options: SelectOptions({
                options: application_statuses || [],
                value: filter.application_status,
                onChange: (application_status) => setFilter({ ...filter, application_status: implementFilterByValue(application_status) })
            })
        }
    ].map(({ label, value, ...props }, idx) => (
        <div key={idx} className="d-flex flex-row flex-nowrap">
            <div>{label}: </div>
            <TextDropDownOption label={valueToLabel(filter[value])} {...props} />
        </div>
    )), [filter, setFilter, company_names, cv_titles, application_statuses]);

    if (loading) return <LoadingSpinner />;
    return (
        <div className="d-flex flex-column gap-3 mb-3">
            <div className="d-flex flex-row flex-wrap flex-md-nowrap gap-3 align-items-center">
                <h3 className="h4">{archived? "Archived" : "All your"} CVs are Here</h3>
                <LinkToJourney to="/cv-setup" className="text-secondary fs-5"><AddIcon className="icon" /> Add New</LinkToJourney>
                <div ref={searchContainer} className="position-relative flex-grow-1">
                    <div
                        className={addClassNames("position-absolute w-100 bg-white shadow px-3 pb-3", filterView ? "" : "collapse")}
                        style={{ left: 0, top: "50%", paddingTop: 42, zIndex: 99 }}
                    >
                        <p>
                            <span className="h5">Filter By: </span>
                            <Link
                                to={archived ? notArchivedUrl : archivedUrl}
                                className="float-end"
                                onClick={() => { setFilterView(false); }}
                            ><Archive className="icon"/> View {archived? "Not": ""} Archive</Link>
                        </p>
                        <div className="d-flex flex-row flex-wrap justify-content-between gap-2">
                            {filterOptions}
                        </div>
                    </div>
                    <div style={{ zIndex: 100, position: 'relative' }}>
                        <SearchForm
                            search={search}
                            setSearch={setSearch}
                            className="w-100"
                            placeholder="Search e.g. my Air New Zealand CV..."
                        />
                    </div>

                </div>
            </div>
            <div className="d-flex flex-column gap-2 cv-table">
                <div key="header" className="row header">
                    <div className="col-10 col-md-3">
                        <input
                            className="me-3"
                            type="checkbox"
                            checked={visibleCVs.every(cv => checked.includes(cv.mcb_cvid))}
                            onChange={(e) => {
                                if (e.currentTarget.checked) {
                                    setChecked(visibleCVs.map(cv => cv.mcb_cvid));
                                } else {
                                    setChecked([]);
                                }
                            }}
                        />
                        <span onClick={sortColumnClickFactory("mcb_cvname")}>
                            <span className="me-2">CV Name</span>
                            {sortColumn === "mcb_cvname" && (sortDirection ? <UpArrow /> : <DownArrow />)}
                        </span>
                    </div>
                    <div className="col-12 col-md-2" onClick={sortColumnClickFactory("created_date")}>
                        <span className="me-2">Created Date</span>
                        {sortColumn === "created_date" && (sortDirection ? <UpArrow /> : <DownArrow />)}
                    </div>
                    <div className="col-12 col-md-2" onClick={sortColumnClickFactory("company_name")}>
                        <span className="me-2">Company</span>
                        {sortColumn === "company_name" && (sortDirection ? <UpArrow /> : <DownArrow />)}
                    </div>
                    <div className="col-12 col-md-2" onClick={sortColumnClickFactory("mcb_title")}>
                        <span className="me-2">Role</span>
                        {sortColumn === "mcb_title" && (sortDirection ? <UpArrow /> : <DownArrow />)}
                    </div>
                    <div className="col-12 col-md-3" onClick={sortColumnClickFactory("application_status_label")}>
                        <span className="me-2">Application Status</span>
                        {sortColumn === "application_status_label" && (sortDirection ? <UpArrow /> : <DownArrow />)}
                    </div>
                </div>
                
                {visibleCVs.length? visibleCVs.map((cv, index) => (
                    <div className="row" key={cv?.mcb_cvid || index}>
                        <div className="col-10 col-md-3">
                            <input
                                className="me-2"
                                type="checkbox"
                                checked={checked.includes(cv?.mcb_cvid)}
                                onChange={(e) => {
                                    if (e.currentTarget.checked) {
                                        setChecked(c => [...c, cv.mcb_cvid]);
                                    } else {
                                        setChecked(c => c.filter(id => id !== cv.mcb_cvid));
                                    }
                                }}
                            />
                            <button className="d-inline btn btn-transparent" onClick={() => {
                                navigate(`/cv-setup/${cv.mcb_cvid}?returnUrl=%2Fcvs`);
                            }}>{cv.mcb_cvname}</button>
                        </div>
                        <div className="col-12 col-md-2">
                            <span>{localeDate(cv.created_date)}</span>
                        </div>
                        <div className="col-12 col-md-2">
                            <span>{cv.company_name}</span>
                        </div>
                        <div className="col-12 col-md-2">
                            <span>{cv.mcb_title}</span>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="d-flex flex-nowrap flex-row gap-2">
                                <div className="flex-grow-1">
                                    {cv.application_status_display}
                                </div>
                                <div className="flex-shrink-0">
                                    <AdditionalOptions options={[
                                        { label: <><EmailIcon className="icon" /> Email</>, className: "btn-link-color", onClick: () => {
                                            emailCVs(cv.mcb_cvid);
                                        } },
                                        {
                                            label: <><Archive className="icon" /> {archived ? "Una" : "A"}rchive</>, className: "btn-danger", onClick: () => {
                                                if (archived) unArchiveCV(cv.mcb_cvid);
                                                else archiveCV(cv.mcb_cvid);
                                            }
                                        },
                                        {
                                            label: <><Duplicate className="icon"/> Duplicate</>,
                                            className: "btn-link-color",
                                            onClick: async () => {
                                                const data = await duplicateCV(cv.mcb_cvid);
                                                if (data?.mcb_cvid) navigate(`/cv-setup/${data?.mcb_cvid}?returnUrl=%2Fcvs`);
                                            }
                                        },
                                        <a
                                            href={`${portalURL}File/download.aspx?Entity=mcb_cv&Attribute=mcb_cvdocument&Id=${cv?.mcb_cvid}`}
                                            target='_blank'
                                            rel='noreferrer'
                                            className="unstyled dropdown-item"
                                        ><DownloadIcon className="icon" /> Download</a>
                                    ]} />
                                </div>

                            </div>
                        </div>
                    </div>
                )) : (
                    <div key="null" className="row">
                        <div className="col-12 py-2">
                            <p className="text-center">
                                <span>No {archived && "Archived"} CVs Found</span><br className='mb-3'/>
                                <Link to={archived ? notArchivedUrl : archivedUrl} className="text-center"><Archive className="icon"/>View {archived? "Not": ""} Archive</Link>
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <div className="d-flex flex-wrap flex-row-reverse gap-2">
                <div className="flex-shrink-0 justify-self-end">
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <button
                            type="button"
                            className="btn btn-link-color pe-3"
                            onClick={() => {
                                emailCVs(...checked);
                            }}
                        ><EmailIcon className="icon" /> Email</button>
                        <button
                            type="button"
                            className={addClassNames("btn pe-3", archived? "btn-warning": "btn-danger")}
                            onClick={() => {
                                if (archived) unArchiveCV(...checked);
                                else archiveCV(...checked);
                            }}
                        ><Archive className="icon" /> {archived ? "Una" : "A"}rchive</button>
                        <button type="button" className="btn btn-primary pe-3" onClick={() => {
                            checked.forEach(cvid => {
                                if (!cvid) return;
                                const cvUrl = `${portalURL}File/download.aspx?Entity=mcb_cv&Attribute=mcb_cvdocument&Id=${cvid}`;
                                window.open(cvUrl);
                            });

                        }}><DownloadIcon className="icon" /> Download</button>
                    </div>
                </div>
                <div className="flex-grow-1">
                    <Pagination center={false} pageCount={pageCount} pageNumber={pageNumber} searchParams={searchParams} />
                </div>
            </div>
        </div >
    );
}
