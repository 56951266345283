import React from 'react';
import Banner from './Generic/BrandedBanner';
import LinkToJourney from './Generic/LinkToJourney';
import { ReactComponent as IdealRoleIcon } from 'bootstrap-icons/icons/briefcase.svg';

export default function IdealRoleBanner(props) {    
    return (
        <Banner
            Icon={IdealRoleIcon}
            heading="Work toward your Ideal Job!"
            cta={<LinkToJourney className="btn btn-light" to="/ideal-job">Add Ideal Job</LinkToJourney>}
            {...props}
        >
            <p>Start the journey to your Ideal Job with easy to use setup.</p>
        </Banner>
    )
}