import React from 'react';
import logo from '../../Assets/logo.png';
import logoWheel from '../../Assets/logo-wheel.png';
import './Logo.scss';
import { addClassNames } from '../../Functions/Helper/Component';

export default function Logo({ mini, className, ...props }) {
    return (
        <div {...props} className={addClassNames("logo-container", className)}>
            {mini? (
                <div className="logo">
                    <img src={logoWheel} className="mcb-logo" alt="MCB" />
                </div>
            ) : ( 
                <div className="logo me-0">
                    <img src={logo} className="mcb-logo" alt="MyCareerBrand" />
                </div>
            )}
        </div>
    );
}