import React from 'react';
import Input from './Generic/Input';
import { components } from 'react-select';
import { addClassNames } from '../Functions/Helper/Component';
import { ReactComponent as AddIcon } from 'bootstrap-icons/icons/plus-circle.svg';
import useRole from '../Provider/TableContext/RoleContext';
import RoleStatus from '../Constants/RoleStatus.json';
import useActionDialog from '../Provider/ ActionDialogContext';

export default function RoleSelection({className, setFirstValue, value, onChange, ...props}) {
    const { roles: _roles, deleteRole, loading } = useRole();
    const roles = React.useMemo(() => _roles.filter(role => [RoleStatus.Previous, RoleStatus.Current].includes(role.mcb_rolestatus))
        .sort((a, b) =>  Date.parse(b.mcb_starteddate) - Date.parse(a.mcb_starteddate))
    , [_roles]);
    React.useEffect(() => {
        if (
            setFirstValue
            && !value
            && roles[0]?.mcb_roleid 
            && typeof onChange === 'function'
        ) {
            onChange(roles[0]?.mcb_roleid);
        }
    }, [setFirstValue, value, roles, onChange]);
    if (props.onCreateOption) {
        const onCreateOption = props.onCreateOption;
        props.components = Object.assign(props.components || {}, {
            MenuList: ({children, ...props}) => (
                <components.MenuList {...props}>
                    <button
                        onClick={() => onCreateOption("")}
                        className="btn btn-light w-100 "
                    ><AddIcon className="icon"/> Add new Role</button>
                    {children}
                </components.MenuList>
            )
        
        })
    }
    const { confirm } = useActionDialog();
    
    return (
        <div>
            <Input
                {...props}
                type='select'
                isLoading={loading}
                className={addClassNames('primary-select', className)}
                options={roles.map(role => ({
                    label: role.mcb_roletitle,
                    value: role.mcb_roleid
                }))}

                value={value || ""} onChange={onChange}
                onDeleteOption={async (roleId)=> {
                    if(await confirm("This action cannot be undone, are you sure?")) {
                        deleteRole(roleId);
                    }
                }}
            />
        </div>
    );
}