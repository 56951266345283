import React from 'react';
import useProfile from '../../Provider/TableContext/ProfileContext';
import useRole from '../../Provider/TableContext/RoleContext';
import useABC from '../../Provider/TableContext/ABCContext';
import useResponsibility from '../../Provider/TableContext/ResponsibilityContext';
import useQualification from '../../Provider/TableContext/QualificationContext';
import RoleStatus from '../../Constants/RoleStatus.json';
import { formDataToProfession, professionToFormData } from '../../Functions/Helper/Model';

const ProfileJourneyContext = React.createContext();

export function ProfileJourneyProvider(props) {
    const { 
        profileData, updateProfile,
        loading: personalLoading,
        ...profileActions
    } = useProfile();
    
    const { roles, processRoleChanges, loading: roleLoading } = useRole();
    const { responsibilities, processResponsibilityChanges, loading: responsibilityLoading } = useResponsibility();
    const { abcs, processABCChanges, loading: abcLoading } = useABC();

    const { qualifications, processQualificationChanges, loading: qualificationLoading } = useQualification();
    const [formData, setFormData] = React.useState({});
    const [professionalLoading, setProfessionalLoading] = React.useState(roleLoading || responsibilityLoading || abcLoading);
        
    React.useEffect(() => {
        if (!personalLoading && profileData) {
            setFormData((oldFormData) => ({
                ...oldFormData,
                personal: {
                    ...profileData,
                    ...(oldFormData?.personal || {}),
                    mcb_clifton34pdf: profileData?.mcb_clifton34pdf || null,
                    mcb_strengthinsightpdf: profileData?.mcb_strengthinsightpdf || null,
                    mcb_signaturethemepdf: profileData?.mcb_signaturethemepdf || null,
                    mcb_themesequencepdf: profileData?.mcb_themesequencepdf || null
                }
            }));
        }
    }, [personalLoading, profileData]);
    
    React.useEffect(() => {
        if (
            !(roleLoading || responsibilityLoading || abcLoading)
        ) {
            setFormData((oldFormData) => {
                let professional = roles.filter(role => (
                    [RoleStatus.Current, RoleStatus.Previous].includes(
                        (role || {}).mcb_rolestatus
                    )
                )).map((role) => professionToFormData(role, responsibilities, abcs)).sort((a, b) => {
                    if (a.currentJob && !b.currentJob) {
                        return -1;
                    } else if (!a.currentJob && b.currentJob) {
                        return 1;
                    } else if (a.currentJob && b.currentJob) {
                        const aStartedDate = new Date(a?.mcb_starteddate || "");
                        const bStartedDate = new Date(b?.mcb_starteddate || "");
                        if (aStartedDate > bStartedDate) {
                            return -1;
                        } else if (aStartedDate < bStartedDate) {
                            return 1;
                        }
                    } else {
                        const aEndedDate = new Date(a?.mcb_endeddate || "");
                        const bEndedDate = new Date(b?.mcb_endeddate || "");
                        if (aEndedDate > bEndedDate) {
                            return -1;
                        } else if (aEndedDate < bEndedDate) {
                            return 1;
                        }
                    }
                    return 0;

                });
                if (!professional?.length) professional = [{}];
                return ({ ...oldFormData, professional})
            });
            setProfessionalLoading(false);
        }

    }, [roleLoading, responsibilityLoading, abcLoading, roles, responsibilities, abcs]);
    
    React.useEffect(() => {
        if (!qualificationLoading && qualifications) {
            setFormData((oldFormData) => ({
                qualifications: [...(qualifications || [])],
                ...oldFormData
            }));
        }
    }, [qualificationLoading, qualifications]);
    return (
        <ProfileJourneyContext.Provider value={{
            formData, setFormData,
            updatePersonal: () => {
                updateProfile(formData?.personal)
            },
            updateProfessional: async (preProcessData)  => {
                setProfessionalLoading(true);
                const _formData = typeof preProcessData === "function"?  preProcessData(formData || {}) : (formData || {});
                const professional = [...(_formData?.professional || [])];
                const roleChanges = [];
                const responsibilityChanges = [];
                const abcChanges = [];

                roleChanges.push(...professional?.map(profession => formDataToProfession(profession, responsibilityChanges, abcChanges)));

                roles.forEach(({ mcb_roleid, mcb_rolestatus }) => {
                    if ((
                            mcb_rolestatus === RoleStatus.Current
                            ||  mcb_rolestatus === RoleStatus.Previous
                        ) && professional.every((prof) => prof?.mcb_roleid !== mcb_roleid)) {
                        roleChanges.push({ deleted: true, mcb_roleid});
                    }
                });

                responsibilities.forEach(({ _mcb_role_value, mcb_responsibilityid }) => {
                    if (!professional.some((prof) => {
                        if (prof?.mcb_roleid === _mcb_role_value) {
                            return prof?.responsibilities.some((resp) =>
                                resp?.mcb_responsibilityid === mcb_responsibilityid
                            );
                        }
                        return false;
                    })) {
                        responsibilityChanges.push({ deleted: true, mcb_responsibilityid});
                    }
                });

                abcs.forEach(({ _mcb_role_value, mcb_abcid }) => {
                    if (!professional.some((prof) => {
                        if (prof.mcb_roleid === _mcb_role_value) {
                            return prof.achievements.some((abc) =>
                                abc.mcb_abcid === mcb_abcid
                            );
                        } else {
                            return false;
                        }
                    })) {
                        abcChanges.push({ deleted: true, mcb_abcid});
                    }
                });
                
                try {
                    return await Promise.all([
                        processRoleChanges(roleChanges),
                        processResponsibilityChanges(responsibilityChanges),
                        processABCChanges(abcChanges)
                    ]);
                } finally {
                    setProfessionalLoading(false);
                }
            },
            
            updateQualification: () => {
                const formQualifications = formData?.qualifications || [];
                const qualificationChanges = [...formQualifications];
                qualifications.forEach(({ mcb_qualificationid }) => {
                    if (formQualifications.every((formQualification) => formQualification?.mcb_qualificationid !== mcb_qualificationid)) {
                        qualificationChanges.push({ deleted: true, mcb_qualificationid });
                    }
                });

                return processQualificationChanges(qualificationChanges);
            },
            profileActions, personalLoading,
            professionalLoading, qualificationLoading
        }}>
            {props.children}
        </ProfileJourneyContext.Provider>
    );
}

export default function useProfileJourneyContext(formName, defaultValue = {}) {
    const { setFormData: baseSetFormData, ...profileContext} = React.useContext(ProfileJourneyContext);
    let formData = profileContext?.formData;

    const setFormData = React.useCallback((value) => {
        if (formName) {
            const formDataSetter = (oldData) => {
                const newData = typeof value === "function"? value(oldData[formName]) : value;
                return {...oldData, [formName]: newData };
            };
            baseSetFormData(formDataSetter);
            return formDataSetter;
        } else {
            baseSetFormData(value);
        }
    }, [baseSetFormData, formName]);
    
    if (formName) {
        formData = formData[formName] || defaultValue;
    }

    return {...profileContext, formData, setFormData};
}