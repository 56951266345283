import React from 'react';
import ArrayInput from './Generic/ArrayInput';
import { bindOnChange } from '../Functions/Helper/OnChange';
import { ReactComponent as FloppyIcon } from 'bootstrap-icons/icons/floppy-fill.svg';
import { ReactComponent as ChevronLeft } from 'bootstrap-icons/icons/chevron-left.svg';
import { ReactComponent as RemoveIcon } from 'bootstrap-icons/icons/dash-circle.svg';
import Form from './Generic/Form';
import Input from './Generic/Input';
import Droppable from './Generic/Droppable';
import Orderable from './Generic/Orderable';
import { AchievementDisplay } from './AchievementsInput';

function RequirementAchievementDisplay({value, index, onChange}) {
    return (
        <>
            <AchievementDisplay value={value} />
            <button
                type="button"
                className="icon-button text-light"
                onClick={(e) => {
                    e.stopPropagation();
                    onChange(values => {
                        values = (values || []).slice();
                        values.splice(index, 1);
                        return values;
                    });
                }}
            >
                <RemoveIcon className="icon fs-5"/>
            </button>
        </>
    );
}


function DisplayComponent({value, onChange }) {
    const onAchievementChange = React.useMemo(() => bindOnChange(onChange, "achievements"), [onChange]);
    return (
        <>
            <div className="fw-bolder">{value?.mcb_responsibility}</div>
            <div className='d-flex flex-column justify-content-right flex-grow-1'>
                <ul className="mb-0 no-marker d-flex flex-column gap-2 px-0 mb-2">
                    <Orderable          
                        onChange={onAchievementChange}
                        ItemComponent={RequirementAchievementDisplay}
                        values={value?.achievements || []}
                        keyFunction={(val) => val.mcb_abcid}
                        draggableClassName="card bg-tertiary text-light flex-row"
                    />
                </ul>
                <Droppable className="border border-1 p-4" type="mcb-achievement" onDrop={({data}) => {
                    onChange((ov) => {
                        const achievements = [...(ov?.achievements || [])];
                        if (achievements.every(abc => abc.mcb_abcid !== data.mcb_abcid)) {
                            achievements.push(data);
                            return {
                                ...ov,
                                achievements
                            };
                        } else {
                            return ov;
                        }
                    });

                }}>
                    Please Drop Achievements to Add Here
                </Droppable>
            
            </div>
            
        </>
    );
}

export default function RequirementsInput({ onChange, values, invalid, ...props}) {
    const _onChange = React.useMemo(() => bindOnChange(onChange, "requirements"), [onChange]);
    const _values = React.useMemo(() => values?.requirements || [], [values]);
    const InputComponent = React.useCallback(({value, onSubmit, onChange, isNew, onCancel}) => {
        return (
            <Form onSubmit={onSubmit}>
                <Input
                    value={value?.mcb_responsibility || ""}
                    required
                    className={invalid? "is-invalid" : ""}
                    placeholder="Type Requirements here..."
                    onChange={(mcb_responsibility) => onChange((ov) => ({...ov, mcb_responsibility}))}
                    addOn={{
                        children: (
                            <div className="btn-group" title={!value?.mcb_responsibility? 'please type a responsibility' : 'add responsibility'}>
                                {!isNew && (
                                    <button 
                                        onClick={onCancel}
                                        className="btn btn-transparent pe-3"
                                        type="button"
                                    >
                                        <ChevronLeft className="me-2"/> Cancel
                                    </button>
                                )}
                                <button 
                                    onClick={onSubmit}
                                    disabled={!value?.mcb_responsibility}
                                    className="btn btn-primary rounded-pill"
                                    type="button"
                                >
                                    <FloppyIcon className="me-2"/> {isNew? "Add" : "Save"}
                                </button>
                            </div>
                        )
                    }}
                />
            </Form>
        )
    }, [invalid]);

    return (
        <>
        <ArrayInput
            InputComponent={InputComponent}
            DisplayComponent={DisplayComponent}
            newValue=""
            noActionComponents
            displayWrapperClassName={"card text-bg-light d-flex flex-row justify-content-between align-items-center flex-wrap gap-4 px-3 py-2"}
            onChange={_onChange}
            values={_values}
            keyFunction={(v) => v?.mcb_responsibilityid}
            {...props}
        />
        </>
    );
}