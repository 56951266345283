import React from 'react';
import useDragAndDrop from '../Provider/DragAndDropContext';

export default function useDrop(type, onDrop, options) {
    const dropRef = React.useRef(null);
    const {setCurrentlyOver} = useDragAndDrop();
    const [isOver, setOver] = React.useState(null);
    
    const getDropData = React.useCallback(() => ({
        type: type || "default",
        element: dropRef.current,
        onDrop,
        ...options
    }), [type, onDrop, options]);

    const onEnter = React.useCallback((e) => {
        setOver(setCurrentlyOver(getDropData()));
    }, [setCurrentlyOver, getDropData]);

    const onExit = React.useCallback((e) => {
        setOver(false);
        setCurrentlyOver(null);
    }, [setCurrentlyOver]);

    React.useEffect(() => {
        const element = dropRef.current;
        if (element) {

            const assignedEvents = [
                ['dragenter', onEnter],
                ['dragleave', onExit],
                ['touchover', (e) => {
                    e.stopPropagation();
                    if (
                        dropRef.current && e.detail?.overTarget
                        && dropRef.current.contains( e.detail?.overTarget)
                    ) {
                        onEnter(e);
                    } else {
                        onExit(e);
                    }
                }],
                ['mouseleave', onExit],
                ['touchend', onExit]
                
            ];
            assignedEvents.forEach(([event, handler]) => {
                element.addEventListener(event, handler);
            });
            return () => {
                assignedEvents.forEach(([event, handler]) => {
                    element.removeEventListener(event, handler);
                });
            };
        }
    });

    return [dropRef, {isOver}];
}