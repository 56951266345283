import React from 'react';
import { ReactComponent as DownloadIcon } from 'bootstrap-icons/icons/cloud-download.svg';
import { processRichTextXML, runOnChild } from '../Functions/Helper/Docx';
import processTemplate from 'docx-templates';
import useTemplateDocument from '../Provider/TableContext/TemplateContext';
import { addClassNames } from '../Functions/Helper/Component';

export default function HTMLDocxLink({ HTML, name, children, rounded = false, rotate = false }) {
    const { getGenericTemplateDocx } = useTemplateDocument();
    const [genericTemplateDocx, setGenericTemplateDocx] = React.useState(null);
    const [HTMLDocxUrl, setHTMLDocxUrl] = React.useState("");
    React.useEffect(() => {
        if (getGenericTemplateDocx) {
            let mounted = true;
            getGenericTemplateDocx().then((template) => {
                if (mounted) setGenericTemplateDocx(template)
            }).catch(console.error);
            return () => { mounted = false;}
        }
    }, [getGenericTemplateDocx]);

    React.useEffect(() => {
        if (genericTemplateDocx) {
            const ctx = {numberingDetails: [], links: []};
            processTemplate({
                template: new Uint8Array(genericTemplateDocx),
                data: { HTML: HTML },
                failFast: false,
                preBuildXML: (xml, documentComponent, reports) => {
                    if (rotate && documentComponent === "main.xml") {
                        runOnChild(xml, (a) => a._tag === "w:pgSz", (pgSz) => {
                            let w = pgSz._attrs['w:w'];
                            pgSz._attrs['w:w'] = pgSz._attrs['w:h'];
                            pgSz._attrs['w:h'] = w;
                        });
                    }
                    return processRichTextXML(ctx, xml, documentComponent, reports);
                }
            }).then((HTMLDocx) => {
                const blob = new Blob([HTMLDocx.buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                setHTMLDocxUrl(window.URL.createObjectURL(blob));
            }).catch(console.error);
        } else setHTMLDocxUrl("");
    }, [genericTemplateDocx, rotate, HTML]);

    if (HTMLDocxUrl) return (
        <a
            className={addClassNames('btn btn-link-color', rounded && 'rounded-pill')}
            download={name}
            href={HTMLDocxUrl}
        ><DownloadIcon className="icon"/> {children}</a>
    )
}