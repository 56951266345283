import React from 'react';
import './LoadingSpinner.scss';
import { addClassNames } from '../../Functions/Helper/Component';

const LoadingSpinner = ({children, fullScreen, style}) => {
    return (
        <div style={style} className={addClassNames("spinner-container", fullScreen && "full-screen")}>
            <div className="loading-spinner">
                <div className="svg-container">
                    <svg className="spinner" width="8rem" height="8rem">
                        <circle
                            className="spinner"
                            r="3rem"
                            cx="50%"
                            cy="50%"
                            fill="none"
                            strokeWidth="1rem"
                            stroke='#9ACA3C'
                        />
                    </svg>
                </div>
            </div>
            <div className="text-highlight">{children}</div>
        </div>
        
    );
}  

export default LoadingSpinner;
