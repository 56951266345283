import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

export default function LinkToJourney({ to, children, ...props}) {
    const { pathname, hash, search } = useLocation();
    const toUrl = React.useMemo(() => {
        const returnUrl = encodeURIComponent(`${pathname || "/"}${hash || ""}${search || ""}`);
        return `${to}?returnUrl=${returnUrl}`;
    }, [pathname, hash, search, to]);
    return <Link to={toUrl} {...props}>{children}</Link>
}