import React, { createContext, useState } from 'react';
import { ReactComponent as CloseIcon } from "bootstrap-icons/icons/x.svg";

const ActionDialogContext = createContext();

const ActionDialogProvider = ({ children }) => {
    const [modalContent, setModalContent] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const showModal = (content) => {
        setModalContent(content);
        setModalVisible(true);
    };

    const hideModal = React.useCallback(() => {
        setModalVisible(false);
        setModalContent("");
    }, []);

    const confirm = React.useCallback((message) => {
        return new Promise((resolve) => {
            showModal(
                <>
                    <div className="modal-header">
                        <h5 className="modal-title">Confirm</h5>
                        <button
                            type="button"
                            className="btn-close icon-button"
                            aria-label="Close"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                resolve(null);
                                hideModal();
                            }}
                        ><CloseIcon width="1em" height="1em"/></button>
                    </div>
                    <div className="modal-body">
                        {message}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() => {
                                resolve(false);
                                hideModal();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                resolve(true);
                                hideModal();
                            }}
                        >
                            Confirm
                        </button>
                    </div>
                </>
            );
        });
    }, [hideModal]);

    const prompt = React.useCallback((message, defaultValue = '') => {
        return new Promise((resolve) => {
            showModal(
                <>
                    <div className="modal-header">
                        <h5 className="modal-title">Prompt</h5>
                        
                        <button
                            type="button"
                            className="btn-close icon-button"
                            aria-label="Close"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                resolve(null);
                                hideModal();
                            }}
                        ><CloseIcon width="1em" height="1em"/></button>
                    </div>
                    <div className="modal-body">
                        <p>{message}</p>
                        <form onSubmit={(e) => { 
                            e.preventDefault();
                            const input = document.querySelector('.modal.action-dialog input');
                            resolve(input.value);
                            hideModal();
                        }}>
                        <input
                            type="text"
                            className="form-control"
                            defaultValue={defaultValue}
                            ref={(input) => input && input.focus()}
                        />
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() => {
                                resolve(null);
                                hideModal();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                const input = document.querySelector('.modal input');
                                resolve(input.value);
                                hideModal();
                            }}
                        >
                            OK
                        </button>
                    </div>
                </>
            );
        });
    }, [hideModal]);

    const alert = React.useCallback((message) => {
        return new Promise((resolve) => {
            showModal(
                <>
                    <div className="modal-header">
                        <h5 className="modal-title">Alert</h5>
                        <button
                            type="button"
                            className="btn-close icon-button"
                            aria-label="Close"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                resolve(null);
                                hideModal();
                            }}
                        ><CloseIcon width="1em" height="1em"/></button>
                    </div>
                    <div className="modal-body">
                        {message}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                resolve(true);
                                hideModal();
                            }}
                        >
                            OK
                        </button>
                    </div>
                </>
            );
        });
    }, [hideModal]);

    return (
        <ActionDialogContext.Provider value={{ confirm, prompt, alert }}>
            {children}
            {modalVisible && (
                <div className="modal action-dialog" tabIndex="-1" role="dialog" style={{ display: 'block', zIndex: 99999}}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">{modalContent}</div>
                    </div>
                </div>
            )}
        </ActionDialogContext.Provider>
    );
};

export default function useActionDialog() {
    return React.useContext(ActionDialogContext);
}

export { ActionDialogContext, ActionDialogProvider };