export function stripHtml(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}


export function convertStyleStringToReactStyle(styleString) {
    return styleString.split(';').reduce((styleObject, styleProperty) => {
      let [property, value] = styleProperty.split(':');
      property = property.trim().replace(/-./g, c => c.toUpperCase()[1]); // Convert to camelCase
      value = value.trim();
      styleObject[property] = value;
      return styleObject;
    }, {});
}