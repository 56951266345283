import React from 'react';
import SkillGraphCard from '../Components/SkillGraphCard';
import CliftonGraphCard from '../Components/CliftonGraphCard';
import AchievementsCard from '../Components/AchievementsCard';
import Pagination from '../Components/Pagination';
import BrandedJobApplicationBanner from '../Components/JobApplicationBanner';
import { useParams } from 'react-router';
import CVTable from '../Components/CVTable';

export default function CVDisplay() {
    const {id} = useParams();
    
    React.useEffect(() => {
        if (id) {
            document.getElementById(`${id}-cv`)?.scrollIntoView({ behavior: "smooth" });
        }
    }, [id]);
    return (
        <>
            <div>
                <CVTable limit={10} Pagination={Pagination} className="w-100 shadow"/>
            </div>
            <BrandedJobApplicationBanner className="w-100 shadow" />
            <div className="d-flex flex-row flex-wrap flex-lg-nowrap align-items-stretch gap-4">
                <div className="d-flex flex-column gap-4 flex-grow-1">
                    <SkillGraphCard className="flex-grow-1 w-100 shadow"/>
                    <CliftonGraphCard className="flex-grow-1 w-100 shadow"/>
                </div>
                <div className="flex-grow-1">
                    <AchievementsCard className="flex-grow-1 w-100 h-100 shadow"/>
                </div>
            </div>
        </>
    );
}