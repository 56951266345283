import React from 'react';
import './RoundedIcon.scss';
import { addClassNames } from '../../Functions/Helper/Component';

export default function RoundedIcon({ Icon, backgroundColor, color, className, ...props }) {
    return (
        <div 
            className={addClassNames("rounded-icon", className)}
            style={{backgroundColor}}
            {...props}
        ><Icon style={{ fill: color }}/></div>
    );
}