import { formatData } from "./Format.Helper";
import { formatDate, spanHTML, strongHTML, styleHTML, tableHTML } from "./Generator.Helper";
import { generateRACTableRowsHTML } from "./RACTable.Genrator";

function generateFormHTML(data) {
    const roleCompanyName =data.companyName,
        roleTitle = data.roleTitle,
        createdOn = formatDate(data.createdon),
        tertiaryQualifications = (data.tertiaryQualifications || []).join(", ");
    return [
        [[ 
            [strongHTML("Company: ") + spanHTML(roleCompanyName), { 
                style: "border-right: 0px solid transparent;" 
            }], [strongHTML("Role/Title: ") + spanHTML(roleTitle), { 
                style: "border-left: 0px solid transparent;" 
                + "border-right: 0px solid transparent;" 
            }], [strongHTML("Date: ") + spanHTML(createdOn), { 
                style: "border-left: 0px solid transparent;" 
            }]
        ], { 
            style: "background-color: #e7e6e6;"
                + "border-bottom: 0px solid transparent;"
        }], [[ 
            [strongHTML("Tertiary Qualifications: ") + spanHTML(tertiaryQualifications), { 
                options: "colspan=\"3\"",
                style: "background-color: #e7e6e6;"
                    + "border-top: 0px solid transparent;"
                    + "border-bottom: 0px solid transparent;" 
            }]
        ]], [[ 
            [strongHTML("Position Key Challenges and Performance Areas of the Role")
                + spanHTML("(Check your notes, Job ad and the position description)"
                + "Responsibilities?"), { style: "border-right: 0px solid transparent;"}
            ], [strongHTML("Career Experience and Outcomes Previous Achievements listed here"),{
                style: "border-left: 0px solid transparent;"
                    + "border-right: 0px solid transparent;"
            }], [strongHTML("Competencies Applied")
                + spanHTML(" (Check your notes, Job ad and the position description)"
                + "Responsibilities?"), {
                    style: "border-left: 0px solid transparent;"
            }]
        ], {
            options: "valign=\"top\"",
            style: "background-color: #e7e6e6;border-top:0px solid transparent;" }]
    ];
}


export function generateRACHTML(role, responsibilities, qualifications, abcs, skills) {
    const data = formatData(role, responsibilities, qualifications, abcs, skills);
    
    return '<div data-wrapper="true" style="font-family:Calibri;font-size:11pt">'
       + tableHTML(
           { options: "cellspacing=\"0\" cellpadding=\"3\" border=\"1\" docx-table-width=\"13958\"" },  
           ...generateFormHTML(data),
           ...generateRACTableRowsHTML(data)
       ) + '</div>' + styleHTML;
}
