import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { addClassNames } from '../Functions/Helper/Component';
import useRole from '../Provider/TableContext/RoleContext';
import RoleStatus from '../Constants/RoleStatus.json';
import { ReactComponent as PersonIcon } from 'bootstrap-icons/icons/person.svg';
import { ReactComponent as AchievementsIcon } from 'bootstrap-icons/icons/rocket-takeoff.svg';
import { ReactComponent as JobApplicationIcon } from 'bootstrap-icons/icons/clipboard-check.svg';
import { ReactComponent as IdealJobIcon } from 'bootstrap-icons/icons/clipboard-heart.svg';
import { ReactComponent as DashboardIcon } from 'bootstrap-icons/icons/graph-up.svg';
import { ReactComponent as DownloadIcon } from 'bootstrap-icons/icons/cloud-download.svg';
import { ReactComponent as CVsIcon } from 'bootstrap-icons/icons/file-earmark-text.svg';
import useConstant from '../Provider/ConstantContext';

export function SideNav({ className }) {
    const { pathname: _pn } = useLocation();
    const { portalURL } = useConstant();
    const pathname = _pn.toLowerCase();
    const [isIdealJobVisible, setIdealJobVisible] = React.useState(false);
    const { roles, loading: roleLoading } = useRole();

    React.useEffect(() => {
        const idealJob = document.getElementById('ideal-job');
        if (idealJob) {
            const observer = new IntersectionObserver(([entry]) => {
                setIdealJobVisible(entry.isIntersecting);
            }, { threshold: 0.5 });

            observer.observe(idealJob);

            return () => observer.unobserve(idealJob);
        }
    });

    const genericCvId = React.useMemo(() => {
        if (!roleLoading) {
            const idealRole = roles.find(role => role.mcb_rolestatus === RoleStatus.Ideal);
            if (idealRole && idealRole?._mcb_cv_value) {
                return idealRole._mcb_cv_value;
            }
        }
        return false;
    }, [roles, roleLoading]);

    const scrollToTop = React.useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <ul className={addClassNames("nav nav-pills", className)}>
                <li className="nav-item">
                    <Link
                        to="/profile"
                        onClick={scrollToTop}
                        className={addClassNames('nav-link unstyled', pathname.startsWith("/profile") && !isIdealJobVisible ? 'active' : '')}
                    >
                        <PersonIcon className="icon fs-4" /> Profile
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        to="/"
                        onClick={scrollToTop}
                        className={addClassNames('nav-link unstyled', pathname === "/" && !isIdealJobVisible ? 'active' : '')}
                    >
                        <DashboardIcon className="icon fs-4" /> Dashboard
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        to="/achievements"
                        onClick={scrollToTop}
                        className={addClassNames('nav-link unstyled', pathname.startsWith("/achievements") && !isIdealJobVisible ? 'active' : '')}
                    ><AchievementsIcon className="icon fs-4" /> Achievements</Link>
                </li>
                <li className="nav-item">
                    <Link
                        to="/#ideal-job"
                        onClick={(e) => {
                            const idealJob = document.getElementById('ideal-job');
                            if (idealJob) {
                                idealJob.scrollIntoView({ behavior: 'smooth' });
                                e.preventDefault();
                            }
                        }}
                        className={addClassNames('nav-link unstyled', isIdealJobVisible ? 'active' : '')}
                    ><IdealJobIcon className="icon fs-4" /> Ideal Job</Link>
                </li>
                <li className="nav-item">
                    <Link
                        to="/job-applications"
                        onClick={scrollToTop}
                        className={addClassNames('nav-link unstyled', pathname.startsWith("/job-applications") && !isIdealJobVisible ? 'active' : '')}
                    ><JobApplicationIcon className="icon fs-4" /> Job Applications</Link>
                </li>
                <li className="nav-item">
                    <Link
                        to="/cvs"
                        onClick={scrollToTop}
                        className={addClassNames('nav-link unstyled', pathname.startsWith("/cvs") && !isIdealJobVisible ? 'active' : '')}
                    ><CVsIcon className="icon fs-4" /> CVs</Link>
                </li>
            </ul>


            {genericCvId && (
                <div className='d-flex position-sticky flex-row justify-content-end gap-3 b-4'>
                    <a
                        target='_blank'
                        rel='noreferrer'
                        href={`${portalURL}File/download.aspx?Entity=mcb_cv&Attribute=mcb_cvdocument&Id=${genericCvId}`}
                        className='btn btn-primary w-max-content unstyled align-self-end'
                    ><DownloadIcon className="icon" /> Download CV</a>
                </div>
            )}
        </>
    );
}
