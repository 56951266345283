import React from 'react';
import SkillGraphCard from '../Components/SkillGraphCard';
import CliftonGraphCard from '../Components/CliftonGraphCard';
import IdealRolePanel from '../Components/IdealRolePanel';
import AchievementsDetails from '../Components/AchievementsDetails';

export default function Achievements() {
    return (
        <>
            <div>
                <AchievementsDetails className="flex-grow-1 w-100 h-100 shadow"/>
            </div>
            <div className="d-flex flex-row flex-wrap flex-lg-nowrap gap-4 flex-grow-1">
                <SkillGraphCard className="flex-grow-1 w-100 shadow"/>
                <CliftonGraphCard className="flex-grow-1 w-100 shadow"/>
            </div>
            <div>
                <IdealRolePanel className="w-100 shadow"/>
            </div>
        </>
    );
}