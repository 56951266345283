import React from 'react';
import Input from './Generic/Input';
import { ReactComponent as SearchIcon } from 'bootstrap-icons/icons/search.svg';
import './SearchForm.scss';

export default function SearchForm({ search, setSearch, onSubmit, ...props}) {
    return (
        <form onSubmit={onSubmit} className="form-inline search-form my-2 my-lg-0 flex-grow-1">
            <Input
                type="search"
                placeholder="Search e.g. my Air New Zealand CV..."
                aria-label="Search"
                inputGroupClassName='rounded-pill'
                inputClassName='py-2'
                value={search}
                onChange={setSearch}
                preAddOn={{
                    className: "text-secondary",
                    children: <SearchIcon/>
                }}
                {...props}
            />
        </form>
    );
}