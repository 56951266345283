import React from 'react';
import { ReactComponent as DownloadIcon } from 'bootstrap-icons/icons/cloud-download.svg';
import useCoverLetterTemplate from '../Provider/TableContext/CoverLetterTemplateContext';
import { processRichTextXML } from '../Functions/Helper/Docx';
import processTemplate from 'docx-templates';

export default function CoverLetterLink({ coverLetter, name }) {
    const { coverLetterTemplate } = useCoverLetterTemplate();
    const [coverLetterUrl, setCoverLetterUrl] = React.useState("");
    React.useEffect(() => {
        if (coverLetterTemplate && coverLetter) {
            const ctx = {numberingDetails: [], links: []};
            processTemplate({
                template: new Uint8Array(coverLetterTemplate),
                data: { coverLetter },
                failFast: false,
                preBuildXML: (xml, documentComponent, reports) => {
                    return processRichTextXML(ctx, xml, documentComponent, reports);
                }
            }).then((coverLetterDocx) => {
                const blob = new Blob([coverLetterDocx.buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                setCoverLetterUrl(window.URL.createObjectURL(blob));
            }).catch(console.error);

        } else setCoverLetterUrl("");
    }, [coverLetterTemplate, coverLetter]);

    if (coverLetterUrl) return (
        <a
            className='btn btn-link-color rounded-pill'
            download={name}
            href={coverLetterUrl}
        ><DownloadIcon className="icon"/> Download Cover Letter</a>
    )
}