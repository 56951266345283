import React from 'react';
import  { RoleProvider } from './RoleContext';
import { ResponsibilityProvider } from './ResponsibilityContext';
import { QualificationProvider } from './QualificationContext';
import { ABCProvider } from './ABCContext';
import { SkillProvider } from './SkillContext';
import { ProfileProvider } from './ProfileContext';
import { CVProvider } from './CVContext';
import { CVTemplateProvider } from './CVTemplateContext';
import { CVSkillAchievementProvider } from './CVSkillAchievementContext';
import { CoverLetterTemplateProvider } from './CoverLetterTemplateContext';

export default function TableContextProvider({children}) {
    return (
        <ProfileProvider>
        <RoleProvider>
        <ResponsibilityProvider>
        <QualificationProvider>
        <ABCProvider>
        <SkillProvider>
        <CVProvider>
        <CVTemplateProvider>
        <CVSkillAchievementProvider>
        <CoverLetterTemplateProvider>
            {children}
        </CoverLetterTemplateProvider>
        </CVSkillAchievementProvider>
        </CVTemplateProvider>
        </CVProvider>
        </SkillProvider>
        </ABCProvider>
        </QualificationProvider>
        </ResponsibilityProvider>
        </RoleProvider>
        </ProfileProvider>
    );
}