import React from 'react';
import Card from './Generic/Card';
import useABC from '../Provider/TableContext/ABCContext';
import useSkill from '../Provider/TableContext/SkillContext';
import useRole from '../Provider/TableContext/RoleContext';
import LoadingSpinner from './Generic/LoadingSpinner';
import GraphComponent from './Generic/Graph';
import SkillAssociationModal from './SkillModal';
import RoleStatus from '../Constants/RoleStatus.json';
import useConstant from '../Provider/ConstantContext';

export default function SkillGraphCard(props) {
    const {abcs, loading: abcLoading} = useABC();
    const {roles, loading:roleLoading} = useRole();
    const {skills, loading: skillsLoading} = useSkill();
    const data = React.useMemo(() => {
        const skillCountMap = {};
        const experienceRoleIds = [];
        roles?.forEach(role => {
            if ([RoleStatus.Current, RoleStatus.Previous].includes(role.mcb_rolestatus)) {
                experienceRoleIds.push(role.mcb_roleid);
            }
        });
        abcs?.forEach(achievement => {
            if (experienceRoleIds.includes(achievement?._mcb_role_value)) {
                const countedId = [];
                achievement?.mcb_ABCSkill?.forEach(({mcb_skillid}) => { 
                    if (countedId.includes(mcb_skillid)) return;
                    countedId.push(mcb_skillid);
                    
                    if (!isNaN(skillCountMap[mcb_skillid])) {
                        skillCountMap[mcb_skillid] += 1;
                    } else {
                        skillCountMap[mcb_skillid] = 1;
                    }
                });
            }
        });
        
    
        const data = Object.entries(skillCountMap).map(([id, value]) => ({
            label: skills?.find(skill => skill.mcb_skillid === id)?.mcb_skill,
            value 
        })).sort((a, b) => b.value - a.value);
        const otherDatas = data.splice(10);
        if (otherDatas.length > 0 ) {
            data.push({
                label: "Others",
                isEmpty: true,
                value: otherDatas.reduce((acc, cur) => acc + cur.value, 0)
            });
        }
        return data;
    }, [abcs, skills, roles]);

    const { Descriptions } = useConstant();
    
    return (
        <Card
            title='Your Skill Distribution'
            footer={<SkillAssociationModal/>}
            description={Descriptions['skill']}
            {...props}
        >
            {(abcLoading || skillsLoading || roleLoading)? <LoadingSpinner/> : data.length? (
                <div className="px-4"><GraphComponent data={data}/></div>
            ) : (
                <div className="px-4 py-3 fs-5">You haven't got any skills yet.</div>
            )}
            
        </Card>
    );
}