import React from 'react';
import './LoadingSpinner.scss';
import { addClassNames } from '../../Functions/Helper/Component';
import LogoWheel from '../LogoWheelSVG';

export function LoadingSpinner({className, loading, ...props}) {
    return (
        <LogoWheel className={addClassNames("spinner", className, !loading && "stop")} {...props}/>

    );
}

export default function LoadingScreen({children, fullScreen, className, style}) {
    return (
        <div style={style} className={addClassNames("spinner-container", fullScreen && "full-screen", className)}>
            <div className="loading-spinner">
                <div className="svg-container">
                    <LogoWheel className="spinner"/>
                </div>
                {children && <div className="text-highlight">{children}</div>}
            </div>
        </div>
        
    );
}  

