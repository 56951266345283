import React from 'react';
import Banner from './Generic/BrandedBanner';
import { ReactComponent as AchievementIcon } from 'bootstrap-icons/icons/trophy-fill.svg';
import useConstant from '../Provider/ConstantContext';

export default function BrandedAchievementBanner(props) {
    const { Descriptions } = useConstant();

    return (
        <Banner
            Icon={AchievementIcon}
            heading={Descriptions["banner"]["title"]}
            cta={<a
                target='_blank'
                rel="noopener noreferrer"
                className="btn btn-light"
                href="https://mycareerbrand.net/products/create-your-coaching-solution"
            >View Coaching Solutions</a>}
            {...props}
        >
            {Descriptions["banner"]["description"]}
        </Banner>
    )
}