import React from 'react';
import LinkToJourney from './LinkToJourney';
import { ReactComponent as EditIcon } from 'bootstrap-icons/icons/pencil-fill.svg';
import RoundedIcon from './RoundedIcon';

export default function EditIconLink({ to }) {
    return (
        <LinkToJourney to={to}>
            <RoundedIcon
                backgroundColor="var(--mo-link-color)"
                color="var(--mo-light)"
                className="fs-5"
                Icon={EditIcon}
            />
        </LinkToJourney>
    );
}