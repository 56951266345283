import React from 'react';
import useApplyingRoleJourneyContext from './ApplyingRoleContext';
import Requirement, { getBindedSidePanel } from '../../Components/RequirementStep';

export default function ApplyingRequirement(props) {
    const journeyContext = useApplyingRoleJourneyContext();
    return <Requirement {...props} {...journeyContext}/>;
}

ApplyingRequirement.SideComponent = getBindedSidePanel(useApplyingRoleJourneyContext);

ApplyingRequirement.stepName = "Requirements";