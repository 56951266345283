import { useCallback } from "react";

export default function useCorrectHTML(apiCall) {
    const emailCVs = useCallback(async(HTML, AdditionalPrompt = "") => {
        const result = await apiCall({
            method: "POST",
            url: "/_api/cloudflow/v1.0/trigger/8d3296ec-504e-ef11-a316-000d3ae0e86d",
            body: JSON.stringify({ 
                eventData: JSON.stringify({ HTML, AdditionalPrompt })
            })
        });
        if (result.json) return result.json;
        else if (result.text) {
            try {
                const data = JSON.parse(result.text);
                return data?.html;
            } catch (e) {}
            return result.text;
        }
        return result;
    }, [apiCall])

    return emailCVs;
}