import React from 'react';
import useFormCallback from '../../Hooks/useFormCallback';

function Form({onSubmit, children, onValidityCheckAdd, isNew = true, onValidityCheckRemove, forceCallback, ...props}, potentialRef) {
    const localRef = React.useRef(null);
    React.useEffect(() => {
        if (typeof onValidityCheckAdd === "function" && typeof onValidityCheckRemove === "function") {
            const checkValidity = () => {
                return localRef.current.reportValidity();
            };
            onValidityCheckAdd(checkValidity)
            return () => onValidityCheckRemove(checkValidity);
            
        }
    }, [onValidityCheckAdd, onValidityCheckRemove]);
    
    useFormCallback({  formRef: localRef, onSubmit, isNew, forceCallback});

    return (
        <form
            ref={(_ref) => {
                localRef.current = _ref;

                if (potentialRef !== undefined && potentialRef !== null) {
                    potentialRef.current = _ref;
                }
            }}
            onSubmit={(event) => {
                event.preventDefault();
                
                if (localRef.current.reportValidity() && typeof onSubmit === "function") {
                    onSubmit(event);
                }
            }} 
            {...props}
        >
            {children}
        </form>
    );
}

export default React.forwardRef(Form);