import React from 'react';
import StringArrayInput from './Generic/StringArrayInput';
import { bindOnChange } from '../Functions/Helper/OnChange';
import { ExpandingTextarea } from './Generic/Input/FormControlInput';

export function ResponsibilitiesInput({ value, onChange }) {
    const toValue = React.useCallback((d) => (d?.mcb_description || ""), []);
    const toObject = React.useCallback((mcb_description, i) => {
        return ({ ...(i || {}), mcb_description });
    }, []);
    const toDisplay = React.useCallback((d) => <span className="fw-normal"><strong className="fw-bolder">{d.mcb_responsibility}</strong> {d.mcb_description}</span>, []);
    const toKey = React.useCallback((d) => d?.mcb_responsibilityid, []);
    const _onChange = React.useMemo(() => bindOnChange(onChange, "responsibilities"), [onChange]);

    return (
        <StringArrayInput
            label={<strong>Key Responsibilities</strong>}
            values={value?.responsibilities || []}
            onChange={_onChange}
            defaultValue={{}}
            toValue={toValue}
            toObject={toObject}
            toDisplay={toDisplay}
            toKey={toKey}
            inputProps={({ value, onChange }) => ({
                type: "textarea",
                placeholder: "Additional Details...",
                preAddOn: {
                    className: "pe-1",
                    children: (
                        <ExpandingTextarea
                            placeholder='Type Responsibility Here'
                            value={(value.mcb_responsibility) || ""}
                            className="fw-bolder mt-2 pb-1"
                            style={{
                                width: `100%`,
                                minWidth: "23ch",
                                outline: 0,
                                border: "0px none transparent",
                                borderBottom: "1px solid gray"
                            }}
                            rows={1}
                            onChange={(e) => {
                                onChange({ ...value, mcb_responsibility: e.target.value });
                            }}
                        />
                    )
                }
            })} />
    );
}
