import React from 'react';
import useABC from '../Provider/TableContext/ABCContext';
import useRole from '../Provider/TableContext/RoleContext';
import useSkill from '../Provider/TableContext/SkillContext';
import LoadingSpinner from './Generic/LoadingSpinner';
import Card from './Generic/Card';
import { generateABCHTML } from '../Functions/Generator/ABCGenerator';
import RichTextDisplay from './RichTextDisplay';
import HTMLDocxLink from './HTMLDocxLink';

const Description = (HTML) => () => <HTMLDocxLink HTML={HTML} rotate name="MyABCs"> Download MyABCs</HTMLDocxLink>;

export default function MyABCs() {
    const { abcs, loading: abcLoading } = useABC();
    const { roles, loading: rolesLoading } = useRole();
    const { skills, loading: skillsLoading } = useSkill();
    
    const loading = React.useMemo(() => abcLoading || rolesLoading || skillsLoading, [abcLoading, rolesLoading, skillsLoading]);

    const value = React.useMemo(() => {
        if (loading) {
            return "Loading...";
        }
        return generateABCHTML(roles, abcs, skills);
    }, [abcs, roles, skills, loading]);

    const children = loading? <LoadingSpinner/> : <RichTextDisplay value={value}/>;
    
    return (
        <Card
            title="MyABCs"
            className="w-100"
            description="Downloads this ABC as MS Word Document"
            DescriptionControl={Description(value)}
        >
            {children}
        </Card>
    );
}