import CreateTableContext from './TableContext';

const fields = [ "mcb_cvtemplateid", "mcb_name", "mcb_document" ];
const readOnly = [ "modifiedon"];
function processContextData({datas, downloadFileFromData}) {
    return { 
        cvTemplates: datas.sort((a, b) => a.mcb_name.localeCompare(b.mcb_name)),
        downloadCVTemplate: (template, ...args) =>   downloadFileFromData(template.mcb_cvtemplateid, "mcb_document", ...args)
    };
}

const [useCVTemplate, CVTemplateProvider] = CreateTableContext(
    "mcb_cvtemplate", {processContextData, fields, readOnly }
);

export default useCVTemplate;
export { useCVTemplate, CVTemplateProvider };
