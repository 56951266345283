import React from 'react';
import Card from './Generic/Card';
import { Link, useNavigate } from 'react-router-dom';
import {components as ReactSelectComponents} from 'react-select';
import { ReactComponent as CheckCircleIcon } from 'bootstrap-icons/icons/check-circle.svg';
import { ReactComponent as CrossCircleIcon } from 'bootstrap-icons/icons/x-circle.svg';
import { ReactComponent as AchievementIcon } from 'bootstrap-icons/icons/trophy.svg';
import { ReactComponent as  AddIcon } from 'bootstrap-icons/icons/plus-circle.svg';
import { ReactComponent as  BackIcon } from 'bootstrap-icons/icons/arrow-left.svg';
import { ReactComponent as DownloadIcon } from 'bootstrap-icons/icons/cloud-download.svg';
import { ReactComponent as TrashIcon } from 'bootstrap-icons/icons/trash-fill.svg';

import RichTextDisplay from './RichTextDisplay';
import Input from './Generic/Input';
import GraphComponent from './Generic/Graph';
import EditIconLink from './Generic/EditIconLink';
import LoadingSpinner from './Generic/LoadingSpinner';
import RemoveAdditionalOption from './Generic/RemoveAdditionalOption';
import { addClassNames } from '../Functions/Helper/Component';
import { getSectorNames } from '../Functions/Helper/Sector';
import useRoleData from '../Hooks/useRoleData';
import useRole from '../Provider/TableContext/RoleContext';
import useCV from '../Provider/TableContext/CVContext';
import CoverLetterLink from './CoverLetterLink';
import useConstant from '../Provider/ConstantContext';
import { ApplicationStatuses, getRoleApplicationStatus } from '../Functions/Helper/ApplicationStatus';
import { localeDate } from '../Functions/Helper/Month';
import CheckCliftonStrength from './CheckCliftonStrength';
import RichTextInput from './Generic/Input/RichTextInput';
import useActionDialog from '../Provider/ ActionDialogContext';

function UpdateNotesRemote(role, updateRole, notes, roleUpdateTimeout) {
    if(notes !== role.mcb_generalcomments) {
        updateRole({ ...role, mcb_generalcomments: notes }, false);
    }
    roleUpdateTimeout.current = false;
};

export default function ApplyingRoleEditPanel({ id, ...props}) {
    const { roles, updateRole, deleteRole } = useRole();
    const navigate = useNavigate();
    const { cvs } = useCV();
    const { portalURL, Descriptions } = useConstant();

    const role = React.useMemo(() => {
        if (Array.isArray(roles)) return roles.find(role => role.mcb_roleid === id) || {};
        else return {};
    }, [id, roles]);
    const cv = React.useMemo(() => {
        if (cvs && cvs.length) return cvs.find(cv => cv.mcb_cvid === role._mcb_cv_value) || {};
        return {};
    }, [cvs, role]);
    
    const [notes, setNotes] = React.useState(role.mcb_generalcomments);
    const roleUpdateTimeout = React.useRef(false);
    
    const onNoteChange = React.useCallback((value) => {
        if (value !== true) setNotes(value);

        if (roleUpdateTimeout.current) {
            clearTimeout(roleUpdateTimeout.current);
            roleUpdateTimeout.current = false;
        }

        if (value === true) UpdateNotesRemote(role, updateRole, notes, roleUpdateTimeout);
        else roleUpdateTimeout.current = setTimeout(UpdateNotesRemote, 2100, role, updateRole, notes, roleUpdateTimeout);
    }, [notes, role, updateRole]);
    React.useEffect(() => { setNotes(role.mcb_generalcomments); }, [role.mcb_generalcomments]);
    const { confirm } = useActionDialog();


    const {
        loading,
        skillData,
        cliftonStrengthData,
        cliftonStrengthColors,
        roleRequirements,
        removeRelationFromResponsibility,
        addRelationToResponsibility,
        achievementOptions
    } = useRoleData(role);

    const FilterComponent = React.useMemo(()=> function ({label, value}) {
        return (
            <li key={value}>
                <button 
                    type="button"
                    onClick={(e) => {
                        const dropdownContainer = e.target.parentNode.parentNode.parentNode;
                        const toggleButton = dropdownContainer.getElementsByClassName("dropdown-toggle")[0];
                        const spinner = dropdownContainer.getElementsByClassName("spinner-border")[0];
                        spinner?.classList.remove("d-none");
                        setTimeout(() => { toggleButton.disabled = true; }, 40);
                        if (!role.mcb_datesubmitted && value > 861400003) {
                            role.mcb_datesubmitted = new Date().toISOString();
                        }
                        updateRole({...role, mcb_applicationstatus: value}, false).then(() => {
                            spinner?.classList.add("d-none");
                            toggleButton.disabled = false;
                        });
                    }}
                    className="dropdown-item gap-2 d-flex flex-nowrap align-items-center justify-content-between" 
                >{label}</button>
                
            </li>
        );
    }, [updateRole, role]);

    const CurrentApplicationStatus = React.useMemo(() => (
        getRoleApplicationStatus(role?.mcb_applicationstatus)
    ), [role?.mcb_applicationstatus]);

    const DescriptionControl = React.useCallback(() => 
        <EditIconLink to={`/applying-job-setup/job-details/${id}`}/>, [id]
    );
    if (loading) return <LoadingSpinner style={{minHeight: "33rem"}}/>;
    
    return (
        <Card
            title={<><Link to="/job-applications"><BackIcon className="icon me-4" /></Link>Job Application</>}
            DescriptionControl={DescriptionControl}
            description={Descriptions['applying-job']}
            id={`${id}-applying-job`}
            {...props}
        >
            <div className="d-flex flex-row flex-wrap flex-lg-nowrap gap-2 px-3">
                <div className="d-flex flex-grow-1 flex-column gap-3 py-3 px-1">
                    <div>
                        <div className="d-flex flex-row flex-nowrap justify-content-between align-items-start my-3">
                            <div className="h4 mb-0">{role?.mcb_roletitle}</div>
                            <div className="dropdown-container d-flex flex-row flex-nowrap">
                                <button 
                                    type="button"
                                    className={addClassNames(
                                        "btn flex-grow-1 py-2 px-3 dropdown-toggle ws-normal d-flex",
                                    )}
                                    style={{backgroundColor: CurrentApplicationStatus?.color, color: 'white'}}
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                >
                                    <div><small>Job Status: </small>{CurrentApplicationStatus? CurrentApplicationStatus.label : "Choose Status"}</div>
                                    <div className="spinner-border ms-2 d-none" role="status"><span className="visually-hidden">Loading...</span></div>
                                </button>
                            
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    {ApplicationStatuses.map(FilterComponent)}
                                    <li key="divider" className="dropdown-divider"></li>
                                    <li key="delete"><button
                                        type="button"
                                        onClick={async () => {
                                            if (await confirm("This could not be undone. Are you sure?")) {
                                                await deleteRole(role.mcb_roleid);
                                                navigate("/job-applications");
                                            }
                                        }}
                                        className="dropdown-item text-danger gap-2 d-flex flex-nowrap align-items-center justify-content-between" 
                                    ><TrashIcon/> Delete</button></li>
                                </ul>
                            </div>
                        </div>
                        <p className="fs-5 mb-1"><b>Company</b>: {role?.mcb_rolecompanyname}</p>
                        <p className="fs-5 mb-1"><b>Sector</b>: {getSectorNames("" + role.mcb_sector)}</p>
                        <p className="fs-5 mb-1"><b> Reporting to</b>: {role?.mcb_reportingto}</p>
                        <p className="fs-large mb-1"><b>Base Remuneration</b>: ${Number(role?.mcb_baserenumeration).toLocaleString()} {
                            role?.mcb_renumerationtype === 861400001? "per year" : "per hour"}</p>
                        <p className="fs-large mb-1"><b>At Risk Remuneration</b>: ${Number(role?.mcb_atriskrenumeration).toLocaleString()}</p>
                        <p className="fs-large mb-1 min-w-min-content"><b>Bonuses</b>: ${Number(role?.mcb_atriskrenumeration).toLocaleString()}</p>
                        {role?.mcb_datesubmitted? 
                            <p className="fs-large mb-1 min-w-min-content"><b>Submitted Date</b>: {localeDate(role?.mcb_datesubmitted)}</p>
                            : null}
                        <h5 className="mt-3">Challenges</h5>
                        <RichTextDisplay
                            value={role?.mcb_challenge || ""}
                        />
                    </div>
                    <div>
                        <h3 className='h4'>Your Skills for the Job</h3>
                        <div className="px-4 py-2">
                            {skillData?.length? <GraphComponent data={skillData} />: "No Skills Added."}
                        </div>
                    </div>
                    <div>
                        <h3 className='h4'>Your CliftonStrengths for the Job</h3>
                        <CheckCliftonStrength>
                            <div className="px-4 py-2">
                                {cliftonStrengthData?.length?  <GraphComponent colors={cliftonStrengthColors} data={cliftonStrengthData} />
                                    : "No CliftonStrength Added."}
                            </div>
                        </CheckCliftonStrength>
                    </div>
                    <div>
                        <h5 className="mt-3">Notes Regarding Job Application</h5>
                        <RichTextInput
                            value={notes || ""}
                            className="fs-larger"
                            theme="snow"
                            onChange={onNoteChange}
                            onKeyUp={e => onNoteChange(e.target.value)}
                            onBlur={() => onNoteChange(true)}
                        />
                    </div>
                </div>
                <div className="flex-grow-1 d-flex flex-column gap-3">
                    {!!roleRequirements?.length && <>
                        <h3 className='h4'>Requirements for the Job</h3>
                        <div className="d-flex flex-column position-relative gap-4 flex-grow-1">
                            <div className='position-lg-absolute w-100 h-100 overflow-y-auto'>
                            {roleRequirements.map((req) => {
                                return (
                                    <div key={req.mcb_responsibilityid}>
                                        <h5 className="h5">{req?.achievements?.length? <CheckCircleIcon className="text-success me-3 icon"/> 
                                            : <CrossCircleIcon className="text-danger me-3 icon"/>}{req.mcb_responsibility}</h5>
                                        <ul className='d-flex flex-column gap-3'>
                                            {req?.achievements?.map((achievement) => {
                                                return (
                                                    <li key={achievement.mcb_abcid} className='d-flex flex-row flex-nowrap'>
                                                        <div className="fs-x-large">
                                                            <AchievementIcon className="icon"/>
                                                        </div>
                                                        <RichTextDisplay
                                                            value={achievement?.mcb_achievementstatement || ""}
                                                            readOnly={true}
                                                            className='fs-larger flex-grow-1 read-only'
                                                            theme="snow"
                                                        />
                                                        <div>
                                                            <RemoveAdditionalOption onRemove={() => {
                                                                removeRelationFromResponsibility(
                                                                    req.mcb_responsibilityid, 
                                                                    "mcb_ABCResponsibility",
                                                                    achievement.mcb_abcid
                                                                );
                                                            }}/>
                                                        </div>
                                                    </li>
                                                );
                                            })}

                                            {req?.achievements?.length < 3 && (
                                                <Input
                                                    type="select"
                                                    onChange={(value) => {
                                                        if (value) {
                                                            addRelationToResponsibility(
                                                                req.mcb_responsibilityid, 
                                                                "mcb_ABCResponsibility",
                                                                value
                                                            );
                                                        }
                                                    }}
                                                    value=""
                                                    classNames={{
                                                        control: (a) => addClassNames(a?.className, "p-1")
                                                    }}
                                                    components={{
                                                        Option: function DeletableProp({children, ...props}) {
                                                            return (
                                                                <div 
                                                                    className={'d-flex react-select__option_container' + (props?.isFocused? ' focused' : '')}
                                                                >
                                                                    <ReactSelectComponents.Option {...props}>
                                                                        <RichTextDisplay
                                                                            value={children || ""}
                                                                            readOnly={true}
                                                                            className='fs-larger flex-grow-1 read-only'
                                                                            theme="snow"
                                                                        />
                                                                    </ReactSelectComponents.Option>
                                                                </div>
                                                            )
                                                        }
                                                    }}
                                                    options={achievementOptions.filter(abc => req?.achievements.every(acheivement => acheivement.mcb_abcid !== abc.value))}
                                                    placeholder="Add Achievement"
                                                    preAddOn={{
                                                        children: <AddIcon className="icon me-0"/>
                                                    }}
                                                />
                                            )}
                                        </ul>
                                    </div>
                                );
                            })}
                            </div>
                            
                        </div>

                        {role?._mcb_cv_value && (
                            <div className='d-flex flex-row justify-content-end gap-3'>
                                <a
                                    target='_blank'
                                    rel='noreferrer'
                                    href={`${portalURL}File/download.aspx?Entity=mcb_cv&Attribute=mcb_cvdocument&Id=${role?._mcb_cv_value}`}
                                    className='btn btn-primary w-max-content unstyled align-self-end'
                                ><DownloadIcon className="icon"/> Download CV</a>
                                <CoverLetterLink coverLetter={cv.mcb_coverletter} name={`CoverLetter_${role.mcb_roletitle}.docx`}/>
                            </div>
                        )}
                    </>}
                </div>
            </div>
        </Card>
    );
}