import React from 'react';
import { Link } from 'react-router-dom';
import './AuthPages.scss';
import Logo from '../Components/Generic/Logo';

export default function AuthPages(PageFunctionalComponent) {
    return function (props) {
        return (
            <div className="auth-pages">
                <div>
                    <Link to="/sign-in"><Logo className="logo mb-4"/></Link>
                    <PageFunctionalComponent {...props} />
                </div>
            </div>
        );
    };
}