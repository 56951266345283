import React from 'react';
import './BrandedBanner.scss';
import RoundedIcon from './RoundedIcon';

export default function Banner({ Icon, className: _className, heading, children, sideFeature, cta}) {
    const className = `branded-banner ${_className || ''}`;

    return (
        <div className={className}>
            <div className="banner-icon">
                <RoundedIcon
                    Icon={Icon}
                    backgroundColor="white"
                    color="#80A733"
                    className="fs-2"
                />
            </div>
            <div className="banner-body">
                {heading && <h4 className="banner-heading mb-1 text-light">{heading}</h4>}
                {children && <div className="banner-body-content text-light">{children}</div>}
                {sideFeature && cta && <div className="banner-cta">{cta}</div>}
            </div>
            {sideFeature? <div className="side banner-feature">{sideFeature}</div>
                : cta? <div className="side banner-cta">{cta}</div>: null}
        </div>
    );
}