/**
 * Generates a random request ID.
 * @returns {string} The generated request ID.
 */
export function generateRequestId() {
  return Date.now() + Math.random().toString(36).substring(2, 5);
}

export async function awaitIframe(iframeRef, condition) {
  // Retry if the iframe hasn't been loaded yet
  while (!(
    iframeRef
    && iframeRef.contentWindow 
    && iframeRef.contentWindow.postMessage
    && (typeof condition !== 'function' || condition(iframeRef))
  )) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
}

export function ActionViaFrame(action) {

  return async function(iframeRef, options) {
    const requestId = generateRequestId();
    // Wait for the iframe to load
    await awaitIframe(iframeRef);

    await new Promise((resolve, reject) => {
        const iframeTimeout = setTimeout(function () {
            window.removeEventListener('message', handleMessage);
            console.log(action, requestId, options);
            reject(new Error("IFrame Timeout"));
        }, 500);

        const handleMessage = (event) => {
            try {
              const response = event.data;
              // console.log(event, requestId, response.requestId);
                if (response.requestId === requestId) {
                    clearTimeout(iframeTimeout);
                    if (response.action === `${action}_RESPONSE`) {
                      if (response.success) {
                          resolve(response.result);
                      } else {
                          reject(response.error);
                      }
                      window.removeEventListener('message', handleMessage);
                    }
                }
            } catch (err) {
                console.error(err);
            }
        };

        // Listen for messages from the iframe
        window.addEventListener('message', handleMessage);

        // Send login request to the iframe
        iframeRef.contentWindow.postMessage({
            action,
            requestId,
            ...options
        }, '*');
    });
  }
}
