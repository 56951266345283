import Months from '../../Constants/Months.json';
export function OrdinalSuffix({number}) {
    let ordinal = "th";
    if (!(number % 100 >= 11 && number % 100 <= 13)) {
        switch (number % 10) {
        case 1:
            ordinal = "st";
            break;
        case 2:
            ordinal = "nd";
            break;
        case 3:
            ordinal = "rd";
            break;
        default:
            ordinal = "th";
            break;
        }
    } 
    return <span>{number}<sup>{ordinal}</sup></span>;
}

export function monthDate(date) {
    const dateOBJ = new Date(date);
    return `${Months[dateOBJ.getMonth()]}, ${dateOBJ.getFullYear()}`;
}


export function localeDate(date) {
    const dateOBJ = new Date(date);
    return <span> <OrdinalSuffix number={dateOBJ.getDate()} /> {`${Months[dateOBJ.getMonth()]}, ${dateOBJ.getFullYear()}`}</span>;
}

export function localeCompare(a) {
    return this.getTime() - new Date(a).getTime();
}