import React from 'react';
import SimpleSelect, {components as ReactSelectComponents} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ReactComponent as CrossIcon} from 'bootstrap-icons/icons/dash-circle-fill.svg';
import { ReactComponent as DeleteIcon } from 'bootstrap-icons/icons/trash-fill.svg';
import { addClassNames } from '../../../Functions/Helper/Component';
import './MultiSelectThemes.scss';

function TransformOptions(options, sorted = false) {
    const transformedOptions =  options.map(option => {
        if (typeof option === "object") {
            if (Array.isArray(option?.options)) {
                return {
                    ...option,
                    options: TransformOptions(option.options)
                };
            } else return option;
        } else {
            return {label: option, value: option};
        }
    })
    if (sorted) return transformedOptions.sort((a, b) => a.label.localeCompare(b.label));
    else return transformedOptions;
}


function findOption(options, value) {
    if (Array.isArray(options)) {
        for(var option of options) {
            if (Array.isArray(option.options)) {
                const result = findOption(option.options, value);
                if (result) return result;
            } else if (typeof option === "object" && option.value === value) {
                return option;
            } else {
                if (option === value) return option;
            }
        }
    } else if (options === value || options?.value === value ) {
        return options;
    }
}

export default function MultiSelectInput({ 
    label,
    type,
    children,
    options,
    className,
    inputClassName,
    value,
    onChange,
    components ,
    refCallback,
    onDeleteOption,
    addOn,
    preAddOn,
    inputWrapper: Wrapper,
    classNames,
    labelClassName,
    sorted,
    ...props
}) {
    props.options = TransformOptions(options || [], sorted);
    const [focused, setFocused] = React.useState(false);

    if (type === "multiselect") {
        if (value) {
            props.value = value.map(v => findOption(props.options, v));
        } else {
            props.value = [];
        }
        props.isMulti = true;
        props.onChange = (selectedOptions) => {
            const selectedValues = (selectedOptions || []).map(option => option.value);
            if (onChange) {
                onChange(selectedValues);
            }
        };
    } else {
        if (value) props.value = findOption(props.options, value);

        props.isMulti = false;
        props.onChange = (selectedOptions) => {
            const selectedValue = (selectedOptions || {}).value;
            if (onChange) {
                onChange(selectedValue);
            }
        };
    }

    const Select = props?.onCreateOption? CreatableSelect : SimpleSelect;
    const finalComponents = { 
        MultiValueRemove: ({innerProps}) => <div {...innerProps}><CrossIcon/></div>
    };

    if (onDeleteOption) {
        finalComponents.Option = function DeletableProp(props) {
            return (
                <div 
                    className={'d-flex react-select__option_container' + (props?.isFocused? ' focused' : '')}
                >
                    <ReactSelectComponents.Option {...props} />
                    {!props.data?.__isNew__? (
                        <button
                            type="button"
                            className='btn btn-outline-danger rounded m-1 me-2 p-1 bg-light'
                            onClick={(e) => {
                                e.stopPropagation();
                                onDeleteOption(props.data?.value);
                            }}
                        ><DeleteIcon/></button>
                    ): <></>}
                </div>
            );
        };
    }
    const select = (
        <Select 
            closeMenuOnSelect={!props.isMulti}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            className={inputClassName}
            ref={refCallback}
            onFocus={()=>setFocused(true)}
            onBlur={()=>setFocused(false)}
            value={value}
            {...props}

            classNames={{
                ...classNames,
                container: (a) => {
                    let className = a.className;
                    if (classNames?.container) className = classNames?.container(a);
                    return addClassNames(className, "react-select-input flex-grow-1");
                }
            }}
            classNamePrefix={"react-select"}
            components={{...finalComponents, ...components}}
        />
    );
    let input = Wrapper? <Wrapper>{select}</Wrapper> : select;

    const { className: addOnClassName, children: addOnChildren,  ...addOnRest } = addOn || {};
    const { className: preAddOnClassName, children: preAddOnChildren,  ...preAddOnRest } = preAddOn || {};

    return (
        <div className={addClassNames("input", (className || ""))}>
            <label className={labelClassName || ""}>{children || label}</label>
            <div className={addClassNames("input-group", (focused? " focus" : ""))}>
                {preAddOn && (
                    <span
                        {...preAddOnRest}
                        className={addClassNames(
                            "input-group-text border-start-0",
                            addOnClassName
                        )}
                    >{preAddOnChildren}</span>
                )}
                {input}

                {addOn && (
                    <span
                        {...addOnRest}
                        className={addClassNames(
                            "input-group-text border-end-0", 
                            preAddOnClassName
                        )}
                    >{addOnChildren}</span>
                )}
            </div>
        </div>
    );
}

